import {
  faArrowRight,
  faCheckCircle,
  faCreditCard,
  faDragon,
  faMessage,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  StarIcon,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { PaywallBundlingCoachingPlan } from "./PaywallBundlingCoachingPlan"
import { PaywallBundlingFreeTrial } from "./PaywallBundlingFreeTrial"
import { usePaywallReferralModalStore } from "./PaywallReferralModal"
import { useCheckoutRedirect } from "./useCheckoutRedirect"
import { CloseButton } from "../../components/closeButton/CloseButton"
import { useAddPhoneNumberModalStore } from "../../components/modals/addPhoneNumberModal/useAddPhoneNumberModalStore"
import { useAddToHomescreenModal } from "../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../components/modals/IOSModal"
import ParentSignupReasons from "../../components/onboarding/ParentSignupReasons"
import useMediaQuery from "../../hooks/useMediaQuery"
import useMobile from "../../hooks/useMobile"
import useOnboardContext from "../../hooks/useOnboardContext"
import { useQuestionnairesQuery, useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { getUserReasons } from "../../util/util"
import { useCoachingStore } from "../coaching/useCoachingStore"
import { useEnterTherapistCodeModal } from "../family/therapists/EnterTherapistCodeModal"

const PaywallBundling = () => {
  const [userReasons, setUserReasons] = useState<any>(null)
  const { isBundleJoonity, setIsBundleJoonity } = useCoachingStore()
  const { isOpen: isOpenPaywallReferralModal } = usePaywallReferralModalStore()
  const { isOpen: isOpenEnterTherapistCodeModal } = useEnterTherapistCodeModal()
  const { isOpen: isOpenAddPhoneNumberModal } = useAddPhoneNumberModalStore()
  const isMobileMediaQuery = useMobile()
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { user } = useUserQuery()
  const { parentData } = useOnboardContext()
  const { data: questionsResponse } = useQuestionnairesQuery()
  const { onOpen: openPaywallReferralModal, hasValidCode } =
    usePaywallReferralModalStore()
  const { isLoadingCheckoutFn, redirectToFamilyCheckout } =
    useCheckoutRedirect()

  const isSmallScreen = useMediaQuery("(max-width: 450px)")

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const onCheckoutClick = () => {
    localStorage.removeItem(localStorageItems.therapistCode)
    if (isBundleJoonity) {
      localStorage.setItem(localStorageItems.planChoiceFromPaywall, "withCoach")
      router.push("/coaching")
    } else {
      localStorage.setItem(localStorageItems.planChoiceFromPaywall, "appOnly")
      redirectToFamilyCheckout()
      localStorage.setItem(localStorageItems.stripeFromPaywall, "true")
      trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_START_TRIAL)
    }
  }

  const onClose = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_SKIP)
    localStorage.removeItem(localStorageItems.therapistCode)
    if (!!localStorage.getItem(localStorageItems.planChoiceFromPaywall)) {
      localStorage.removeItem(localStorageItems.planChoiceFromPaywall)
      router.push("/me")
      if (isOnMobileChrome) openAddToHomescreenModal()
      else openIOSModal()
    } else {
      localStorage.setItem(
        localStorageItems.planChoiceFromPaywall,
        isBundleJoonity ? "withCoach" : "appOnly"
      )
      router.push("/coaching")
    }
  }

  const onReferralClick = () => {
    setIsBundleJoonity(false)
    openPaywallReferralModal()
  }

  useEffect(() => {
    if (!questionsResponse?.parentQuestions || userReasons !== null) return
    const reasons = getUserReasons(
      parentData,
      questionsResponse?.parentQuestions
    )
    setUserReasons(reasons)
  }, [questionsResponse]) // eslint-disable-line

  useEffect(() => {
    if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
      "withCoach"
    ) {
      setIsBundleJoonity(false)
    }
  }, []) // eslint-disable-line

  const planItems: { title: string; icon: React.ReactNode }[] = [
    {
      title: `Full access to Joon Apps ${isBundleJoonity ? "included!" : ""}`,
      icon: (
        <FontAwesomeIcon
          icon={faDragon}
          color={JoonUIColor.icon.accent}
          style={{ transform: "scaleX(0.8)" }}
        />
      ),
    },
    ...(isBundleJoonity
      ? [
          {
            title: "Anytime direct messaging with your coach",
            icon: (
              <FontAwesomeIcon
                icon={faMessage}
                color={JoonUIColor.icon.accent}
              />
            ),
          },
          {
            title: "Flexible plans up to 4 live coaching sessions/mo",
            icon: (
              <FontAwesomeIcon icon={faVideo} color={JoonUIColor.icon.accent} />
            ),
          },
          {
            title: "HSA/FSA eligible",
            icon: (
              <FontAwesomeIcon
                icon={faCreditCard}
                color={JoonUIColor.icon.accent}
              />
            ),
          },
        ]
      : []),
  ]

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space4}
      style={{
        filter:
          isOpenAddPhoneNumberModal ||
          isOpenEnterTherapistCodeModal ||
          isOpenPaywallReferralModal
            ? "blur(5px)"
            : "none",
        width: "min(95vw, 450px)",
        marginTop: "3dvh",
        marginRight: "auto",
        marginBottom: 0,
        marginLeft: "auto",
        height: "100vh",
        position: "relative",
        paddingLeft: SPACING.space4,
        paddingRight: SPACING.space4,
      }}
      wrap={false}
    >
      <img
        src={"/images/logos/logo_white.svg"}
        alt="Joon"
        style={{
          minHeight: isMobileMediaQuery ? "40px" : "60px",
          maxHeight: isMobileMediaQuery ? "40px" : "60px",
        }}
      />

      <CloseButton onClick={onClose} style={{ top: 0, right: 0 }} />

      {userReasons && (
        <>
          <Typography variant="h3">{user?.name}'s Custom Plan</Typography>
          <ParentSignupReasons />
          <img src="/images/squiggle_accent.svg" alt="~" className="my-12" />
        </>
      )}

      <Typography variant="h3">Choose your Joon Plan</Typography>

      <FlexBox
        direction="row"
        justify="center"
        align="center"
        gap={SPACING.space2}
        wrap={false}
      >
        <button
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_JOON_PLAN_SELECT, {
              plan: "withCoach",
            })
            setIsBundleJoonity(true)
          }}
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: SPACING.space2,
            width: "100%",
            border: isBundleJoonity
              ? `2px solid ${JoonUIColor.border.accent}`
              : `1px solid ${JoonUIColor.border.default}`,
            backgroundColor: isBundleJoonity
              ? JoonUIColor.background.primaryNeutral
              : JoonUIColor.background.xlightGray,
            borderRadius: SPACING.space2,
            padding: `${SPACING.space8} ${isMobileMediaQuery ? SPACING.space2 : SPACING.space4} ${SPACING.space4}`,
            minHeight: "108px",
            maxHeight: "108px",
            transform: isBundleJoonity ? "scale(1)" : "scale(0.96)",
            transition: "transform 0.2s ease",
          }}
        >
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            gap={SPACING.space1}
            style={{
              position: "absolute",
              padding: `${SPACING.space1} ${SPACING.space2}`,
              top: -1,
              left: -1,
              backgroundColor: isBundleJoonity
                ? JoonUIColor.background.accent
                : JoonUIColor.icon.neutral,
              borderTopLeftRadius: SPACING.space2,
              borderBottomRightRadius: SPACING.space2,
              width: "fit-content",
            }}
          >
            <div style={{ width: 16, height: 18 }}>
              <StarIcon size={16} color={JoonUIColor.icon.inverted} />{" "}
            </div>
            <Typography variant="bodySmall" color={JoonUIColor.text.inverted}>
              Best value!
            </Typography>
          </FlexBox>
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={JoonUIColor.icon.accent}
            size="xl"
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              backgroundColor: JoonUIColor.background.primaryNeutral,
              borderRadius: "50%",
              opacity: isBundleJoonity ? 1 : 0,
              transition: "opacity 0.1s ease-in-out",
            }}
          />
          <FlexBox
            direction="row"
            gap={SPACING.space1}
            wrap={false}
            align="center"
            justify="center"
          >
            <Typography variant={"h3"}>Joon</Typography>
            <Typography
              variant={"h3"}
              color={JoonUIColor.text.primaryAccent}
              style={{ fontFamily: "Caveat" }}
            >
              with
            </Typography>{" "}
            <Typography variant={"h3"}>Coach</Typography>
          </FlexBox>
          <Typography
            variant={isMobileMediaQuery ? "caption" : "bodyBold"}
            style={{ whiteSpace: "nowrap" }}
          >
            Plans start at $24/mo
          </Typography>
        </button>
        <button
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_JOON_PLAN_SELECT, {
              plan: "appOnly",
            })
            setIsBundleJoonity(false)
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            position: "relative",
            width: "100%",
            border: isBundleJoonity
              ? `1px solid ${JoonUIColor.border.default}`
              : `2px solid ${JoonUIColor.border.accent}`,
            borderRadius: SPACING.space2,
            padding: SPACING.space3,
            backgroundColor: isBundleJoonity
              ? JoonUIColor.background.xlightGray
              : JoonUIColor.background.primaryNeutral,
            minHeight: "108px",
            maxHeight: "108px",
            transform: isBundleJoonity ? "scale(0.96)" : "scale(1)",
            transition: "transform 0.2s ease",
          }}
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={JoonUIColor.icon.accent}
            size="xl"
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              backgroundColor: JoonUIColor.background.primaryNeutral,
              borderRadius: "50%",
              opacity: isBundleJoonity ? 0 : 1,
              transition: "opacity 0.1s ease-in-out",
            }}
          />
          <FlexBox
            direction="row"
            gap={SPACING.space1}
            wrap={false}
            align="center"
            justify="center"
          >
            <Typography variant={"h3"}>Joon</Typography>
            <Typography
              variant={"h3"}
              color={JoonUIColor.text.primaryAccent}
              style={{ fontFamily: "Caveat", whiteSpace: "nowrap" }}
            >
              App only
            </Typography>{" "}
          </FlexBox>
          <Typography variant={"caption"} style={{ whiteSpace: "nowrap" }}>
            $7.49/month
          </Typography>
          <Typography
            variant={"bodyXSmall"}
            style={{
              whiteSpace: "nowrap",
              fontWeight: 600,
            }}
          >
            7 days free trial
          </Typography>
        </button>
      </FlexBox>

      <FlexBox direction="column" gap={SPACING.space3}>
        <Typography variant="bodyBold" textAlign="left">
          What you'll get with this plan:
        </Typography>
        {planItems.map(({ title, icon }, index) => (
          <FlexBox
            key={index}
            direction="row"
            align="center"
            justify="flex-start"
            gap={SPACING.space2}
            wrap={false}
            fullWidth
          >
            <FlexBox
              align="center"
              justify="center"
              style={{ width: 16, height: 16 }}
            >
              {icon}
            </FlexBox>
            <Typography variant="caption">{title}</Typography>
          </FlexBox>
        ))}
      </FlexBox>

      {isBundleJoonity && <PaywallBundlingCoachingPlan />}
      {!isBundleJoonity && <PaywallBundlingFreeTrial />}

      <FlexBox
        direction="column"
        justify="flex-start"
        align="center"
        gap={SPACING.space1}
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: JoonUIColor.background.primaryNeutral,
          padding: SPACING.space4,
          paddingTop: isBundleJoonity ? SPACING.space2 : SPACING.space4,
          paddingBottom: isBundleJoonity ? SPACING.space2 : SPACING.space4,
          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
          zIndex: 10,
          width: "min(100vw, 450px)",
          marginTop: "auto",
          borderTopLeftRadius: isSmallScreen ? 0 : SPACING.space2,
          borderTopRightRadius: isSmallScreen ? 0 : SPACING.space2,
        }}
      >
        {!isBundleJoonity && (
          <>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
              textAlign="center"
            >
              Introductory one-time offer!
            </Typography>
            <Typography variant="caption" textAlign="center">
              Unlimited free access for 7 days, then
            </Typography>
            <FlexBox
              direction="row"
              align="center"
              justify="center"
              style={{ width: "fit-content", textAlign: "center" }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.secondary}
                style={{
                  textDecoration: "line-through",
                  marginRight: SPACING.space1,
                }}
              >
                $10.99
              </Typography>
              {hasValidCode && (
                <Typography variant="bodyBold">$7.49</Typography>
              )}
              <Typography variant="h3">
                {hasValidCode ? "$5.83" : "$7.49"}
              </Typography>
              <Typography variant="body">
                {hasValidCode ? "/month (save 47%)" : "/month (save 32%)"}
              </Typography>
            </FlexBox>
          </>
        )}
        <Button
          isLoading={isLoadingCheckoutFn}
          onClick={onCheckoutClick}
          style={{
            position: "relative",
            justifyContent: "flex-start",
            fontSize: "24px",
            width: "100%",
            marginTop: SPACING.space2,
            marginBottom: SPACING.space2,
            borderRadius: SPACING.space2,
          }}
          buttonType="primary"
          text={
            <FlexBox direction="row" align="center" wrap={false}>
              <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
                {isBundleJoonity
                  ? "Find your perfect coach"
                  : "Start FREE trial"}
              </Typography>
              <FontAwesomeIcon
                icon={faArrowRight}
                color={JoonUIColor.text.inverted}
                size="sm"
                style={{ position: "absolute", right: SPACING.space4 }}
              />
            </FlexBox>
          }
          fullWidth
        />
        <TextButton onClick={onReferralClick}>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            I was referred by a therapist or parent
          </Typography>
        </TextButton>
        {!isBundleJoonity && (
          <Typography variant="bodyXSmall" color={JoonUIColor.text.secondary}>
            Offer expires when you close this screen!
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default PaywallBundling
