import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { create } from "zustand"

import { useAddEditQuestModal } from "./useAddEditQuest"
import { CloseButton } from "../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"

interface ConfirmUngroupModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useConfirmUngroupModalStore =
  create<ConfirmUngroupModalStoreProps>()((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const ConfirmUngroupModal = () => {
  const { isOpen, onClose } = useConfirmUngroupModalStore()
  const { editQuestMutation } = useAddEditQuestModal()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <CloseButton onClick={onClose} />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: `${SPACING.space8} ${SPACING.space4} ${SPACING.space14} ${SPACING.space4}`,
        }}
        fullWidth
      >
        <Typography variant="bodyBold">
          Are you sure you want to ungroup these quests?
        </Typography>
        <Typography variant="bodySmall">
          Once Ungrouped & Saved, you will not be able to regroup these quests
          back together.
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          gap={SPACING.space2}
          fullWidth
          wrap={false}
        >
          <Button
            buttonType="secondary"
            onClick={onClose}
            text="Back"
            fullWidth
            style={{ borderRadius: SPACING.space2 }}
          />
          <Button
            buttonType="primary"
            onClick={async () => {
              await editQuestMutation.mutateAsync()
              onClose()
            }}
            text="Ungroup & Save"
            isLoading={editQuestMutation.isLoading}
            fullWidth
            style={{ borderRadius: SPACING.space2 }}
          />
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}
