import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { HeaderBottomButtons } from "./HeaderBottomButtons"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const AddEditQuestModalHeader = () => {
  const {
    questName,
    description,
    selectedQuest,
    setQuestName,
    setDescription,
  } = useAddEditQuestStore()
  const [isFocusedQuestTitle, setIsFocusedQuestTitle] = useState(false)
  const [isFocusedQuestDescription, setIsFocusedQuestDescription] =
    useState(false)

  const isEditing = !!selectedQuest
  const hasFrequencyTarget = !!selectedQuest?.series.current_frequency_target

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space6}
      style={{
        padding: SPACING.space4,
        paddingTop: SPACING.space6,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
      }}
      fullWidth
    >
      {hasFrequencyTarget && (
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          style={{
            borderRadius: SPACING.space2,
            border: `1px solid ${JoonUIColor.semantic.info}`,
            padding: SPACING.space2,
            background: JoonColorExpanded.blue100,
          }}
          gap={SPACING.space2}
          wrap={false}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            color={JoonUIColor.semantic.info}
          />
          <Typography variant="bodySmall" color={JoonUIColor.semantic.info}>
            Some fields are{" "}
            <Typography
              variant="caption"
              color={JoonUIColor.semantic.info}
              style={{ fontStyle: "italic" }}
            >
              not editable*
            </Typography>{" "}
            on therapist modified Quests.
          </Typography>
        </FlexBox>
      )}

      <FlexBox direction="column" gap={SPACING.space1}>
        {isEditing && hasFrequencyTarget ? (
          <Typography
            variant="bodyBold"
            color={JoonUIColor.text.secondary}
            style={{ fontStyle: "italic" }}
          >
            {questName}*
          </Typography>
        ) : (
          <FlexBox direction="column">
            <textarea
              name="Quest Title"
              value={questName}
              placeholder="Quest title.."
              onChange={(e: any) => setQuestName(e.target.value)}
              onFocus={() => setIsFocusedQuestTitle(true)}
              onBlur={() => setIsFocusedQuestTitle(false)}
              maxLength={100}
              rows={1}
              style={{
                height: isFocusedQuestTitle ? "56px" : "28px",
                width: "100%",
                border: "none",
                outline: "none",
                fontSize: "1.25rem",
                lineHeight: "1.5rem",
                fontWeight: 600,
                resize: "none",
                overflow: "auto",
                transition: "height 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)",
              }}
            />
            <FlexBox
              direction="row"
              align="center"
              justify="flex-end"
              style={{
                ...(isFocusedQuestTitle
                  ? { opacity: 1, pointerEvents: "auto" }
                  : { opacity: 0, pointerEvents: "none" }),
              }}
            >
              <Typography variant="bodyXSmall">
                {questName.length} / 100
              </Typography>
            </FlexBox>
          </FlexBox>
        )}

        <FlexBox direction="column">
          <textarea
            name="Quest Description"
            value={description || ""}
            placeholder={`Add quest description..`}
            onChange={(e: any) => setDescription(e.target.value)}
            onFocus={() => setIsFocusedQuestDescription(true)}
            onBlur={() => setIsFocusedQuestDescription(false)}
            maxLength={150}
            rows={3}
            style={{
              width: "100%",
              height: "64px",
              border: "none",
              outline: "none",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 500,
              resize: "none",
              overflow: "scroll",
              whiteSpace: "pre-wrap",
            }}
          />
          <FlexBox
            direction="row"
            align="center"
            justify="flex-end"
            style={{
              ...(isFocusedQuestDescription
                ? { opacity: 1, pointerEvents: "auto" }
                : { opacity: 0, pointerEvents: "none" }),
            }}
          >
            <Typography variant="bodyXSmall">
              {description?.length} / 150
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <HeaderBottomButtons />
    </FlexBox>
  )
}
