import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

import { TOSSigningModal } from "./TOSSigningModal"
import { useTOSSigningModalStore } from "./useTOSSigningModalStore"
import Button2 from "../../components/buttons/Button2"
import { useAgreementLinksQuery } from "../../networking/agreementLinks/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import useCareTeam from "../care/useCareTeam"

const TOSOverlay = () => {
  const { onOpen } = useTOSSigningModalStore()
  const { selectedCoachProfile } = useCareTeam()
  const { data: agreementLinks, isLoading } = useAgreementLinksQuery()

  const needsToSign = !isLoading && agreementLinks && agreementLinks.length > 0

  if (!needsToSign) return null

  const onViewAgreement = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_COACHING_TOS)
    onOpen()
  }

  return (
    <FlexBox
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 400,
        backdropFilter: "blur(2px)",
      }}
    >
      <FlexBox
        direction="column"
        justify="center"
        gap={SPACING.space2}
        style={{
          background: JoonUIColor.background.lightBlue,
          padding: "16px 24px",
          borderRadius: "12px",
          width: "min(400px, 95vw)",
          zIndex: 2,
        }}
      >
        <Typography variant="h1">🚀</Typography>
        <Typography variant="bodyBold">
          Welcome to the Joon Parent Coaching Program!
        </Typography>
        <Typography variant="body">
          To get started, please review our Coaching Agreement. This outlines
          what parent coaching is, how it works at Joon, and how you and{" "}
          {selectedCoachProfile?.user.name || "your coach"} will work together
          to help you reach your goals.
        </Typography>
        <Button2
          text={
            <FlexBox
              gap={SPACING.space1}
              align="center"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.inverted}
                style={{ fontWeight: 600 }}
              >
                View coaching agreement
              </Typography>
              <FontAwesomeIcon icon={faChevronRight} fontSize="14px" />
            </FlexBox>
          }
          buttonType="primary"
          onClick={onViewAgreement}
          style={{
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space3}`,
            marginTop: SPACING.space2,
          }}
          fullWidth
        />
      </FlexBox>
      <TOSSigningModal />
    </FlexBox>
  )
}

export default TOSOverlay
