import {
  faHandHoldingHeart,
  faMessage,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

export const PaywallBundlingCoachingPlan = () => {
  const coachingPlanItems = [
    {
      icon: (
        <FontAwesomeIcon
          icon={faHandHoldingHeart}
          size="lg"
          color={JoonUIColor.text.primaryAccent}
        />
      ),
      title: "Get matched with your perfect coach!",
      subtitle: "Answer a short survey to get paired with a coach",
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faMessage}
          size="lg"
          color={JoonUIColor.text.primaryAccent}
        />
      ),
      title: "Access immediate help via chat",
      subtitle: "You'll be able to message your coach right away",
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faVideo}
          size="lg"
          color={JoonUIColor.text.primaryAccent}
        />
      ),
      title: "Schedule live sessions",
      subtitle:
        "Easily see your coach's availability and find a time that works best for you!",
    },
  ]

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        paddingTop: SPACING.space3,
      }}
    >
      <Typography variant="bodyBold" textAlign="left">
        How your coaching plan works:
      </Typography>
      <FlexBox direction="column" gap={SPACING.space4}>
        {coachingPlanItems.map(({ icon, title, subtitle }, index) => (
          <FlexBox
            key={index}
            direction="row"
            align="center"
            justify="flex-start"
            gap={SPACING.space2}
            wrap={false}
          >
            <FlexBox
              align="center"
              justify="center"
              style={{ width: 20, height: 20 }}
            >
              {icon}
            </FlexBox>
            <FlexBox direction="column" align="flex-start" justify="center">
              <Typography
                variant="caption"
                textAlign="left"
                color={JoonUIColor.text.primaryAccent}
                style={{ lineHeight: "1.2" }}
              >
                {title}
              </Typography>
              <Typography
                variant="bodyXSmall"
                textAlign="left"
                style={{ fontWeight: "600" }}
              >
                {subtitle}
              </Typography>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space4}
        style={{
          marginTop: SPACING.space4,
          paddingTop: SPACING.space2,
          paddingBottom: SPACING.space4,
          backgroundColor: JoonColorExpanded.yellow100,
          borderRadius: SPACING.space2,
          overflow: "visible",
        }}
      >
        <Typography variant="bodyBold" textAlign="center">
          Why Joon Coaching?
        </Typography>
        <Typography
          variant="bodySmall"
          textAlign="center"
          style={{ paddingLeft: SPACING.space2, paddingRight: SPACING.space2 }}
        >
          Our parent coaches have decades of childhood experience, advanced
          education, and are experts in educational and behavioral science
        </Typography>
        <img
          src="/images/coaches_and_joe.png"
          alt="Joon Coaching"
          style={{
            width: "100%",
            userSelect: "none",
            pointerEvents: "none",
          }}
        />
      </FlexBox>
    </FlexBox>
  )
}
