import { create } from "zustand"

import { SubscriptionPackage } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"

interface PlanUpdateModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  numSessions: number
  setNumSessions: (num: number) => void

  hasChangedPlan: boolean
  setHasChangedPlan: (hasChanged: boolean) => void

  previousPackage: SubscriptionPackage | null
  setPreviousPackage: (plan: SubscriptionPackage) => void

  isOpenConfirmationModal: boolean
  onOpenConfirmationModal: () => void
  onCloseConfirmationModal: () => void
}

const initialData = {
  isOpen: false,
  numSessions: 2,
  hasChangedPlan: false,
  previousPackage: null,
  isOpenConfirmationModal: false,
}

const usePlanUpdateModalStore = create<PlanUpdateModalStore>()((set) => ({
  ...initialData,

  onClose: () => set(initialData),
  onOpen: () => {
    set({ isOpen: true })
    trackAnalyticEvent(ANALYTIC_EVENTS.INITIATE_UPDATE_COACH_PLAN)
  },
  setNumSessions: (num) => set({ numSessions: num }),
  setHasChangedPlan: (hasChanged) => set({ hasChangedPlan: hasChanged }),
  setPreviousPackage: (plan) => set({ previousPackage: plan }),
  onOpenConfirmationModal: () => set({ isOpenConfirmationModal: true }),
  onCloseConfirmationModal: () => {
    set({ isOpenConfirmationModal: false })
    trackAnalyticEvent(ANALYTIC_EVENTS.CANCEL_COACH_PLAN_UPDATE)
  },
}))

export default usePlanUpdateModalStore
