import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Slider,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const PlanSlider = ({
  sessionOptions,
  numSessions,
  setNumSessions,
  currentPlanSessions,
}: {
  sessionOptions: number[]
  numSessions: number
  setNumSessions: (num: number) => void
  currentPlanSessions: number | undefined
}) => {
  return (
    <FlexBox direction="column" gap={SPACING.space1}>
      <Slider
        options={sessionOptions}
        value={numSessions}
        setValue={setNumSessions}
        variant="secondary"
        barColor={JoonColorExpanded.indigo200}
      />
      <FlexBox
        direction="row"
        gap={SPACING.space2}
        justify="space-between"
        style={{ padding: `0 ${SPACING.space2}` }}
      >
        {sessionOptions.map((option) => (
          <button
            style={{ padding: SPACING.space2, paddingBottom: 0 }}
            onClick={() => setNumSessions(option)}
          >
            <Typography
              variant="h3"
              style={{ fontFamily: "Caveat", fontWeight: 600 }}
              color={
                option === currentPlanSessions
                  ? JoonUIColor.semantic.warning
                  : JoonUIColor.text.primary
              }
            >
              {option}x{option === currentPlanSessions ? "*" : ""}
            </Typography>
          </button>
        ))}
      </FlexBox>
      <Typography
        variant="h3"
        textAlign="center"
        style={{ fontFamily: "Caveat", fontWeight: 600 }}
      >
        per month
      </Typography>
    </FlexBox>
  )
}

export default PlanSlider
