import {
  JoonColorExpanded,
  JoonUIColor,
  QuestStatus,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const StatusBadge = ({ status }: { status: QuestStatus }) => {
  const badgeInfo = getBadgeInfo(status)

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: SPACING.space2,
        height: "fit-content",
        width: "fit-content",
        padding: `${SPACING.space1} ${SPACING.space2}`,
        background: badgeInfo.color,
      }}
    >
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.inverted}
        style={{ whiteSpace: "nowrap" }}
      >
        {badgeInfo.text}
      </Typography>
    </div>
  )
}

const getBadgeInfo = (
  status: QuestStatus
): {
  color: JoonColorExpanded
  text: string
} => {
  switch (status) {
    case QuestStatus.COMPLETED:
      return {
        color: JoonUIColor.semantic.info,
        text: "In-review",
      }
    case QuestStatus.VERIFIED:
    case QuestStatus.REDEEMED:
      return {
        color: JoonUIColor.semantic.success,
        text: "Completed",
      }
    case QuestStatus.OPEN:
    case QuestStatus.RETRY:
      return {
        color: JoonUIColor.background.disabled,
        text: "Incomplete",
      }
    case QuestStatus.SKIPPED:
      return {
        color: JoonUIColor.semantic.warning,
        text: "Skipped",
      }
    case QuestStatus.REJECTED:
    case QuestStatus.REJECTED_VIEWED:
      return {
        color: JoonUIColor.semantic.destructive,
        text: "Rejected",
      }
  }
}

export default StatusBadge
