import { localStorageItems } from "../../../../util/storage"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const useCompletionRequirement = () => {
  const {
    reqReview,
    reqPhoto,
    reqNotes,
    setReqReview,
    setReqPhoto,
    setReqNotes,
  } = useAddEditQuestStore()

  const toggleReview = () => {
    if (!reqReview) {
      localStorage.setItem(localStorageItems.prefersToReviewQuest, "true")
    } else {
      localStorage.setItem(localStorageItems.prefersToReviewQuest, "false")
      localStorage.setItem(
        localStorageItems.prefersToReviewWithPicture,
        "false"
      )
      localStorage.setItem(localStorageItems.prefersToReviewWithNotes, "false")
      setReqPhoto(false)
      setReqNotes(false)
    }
    setReqReview(!reqReview)
  }

  const togglePhoto = () => {
    localStorage.setItem(
      localStorageItems.prefersToReviewWithPicture,
      !reqPhoto ? "true" : "false"
    )
    setReqPhoto(!reqPhoto)
  }

  const toggleNotes = () => {
    localStorage.setItem(
      localStorageItems.prefersToReviewWithNotes,
      !reqNotes ? "true" : "false"
    )
    setReqNotes(!reqNotes)
  }

  return { toggleReview, togglePhoto, toggleNotes }
}
