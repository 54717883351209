import { DropdownOption, QuestRoutine } from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import { useSpecifyDateModalStore } from "./SpecifyDateModal"
import { StartDateOption } from "../../../../constants"
import { capitalizeFirstLetter } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const useDateTime = () => {
  const {
    startDate,
    isOngoing,
    routine,
    setStartDate,
    setRoutine,
    setReminderTimeMap,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      startDate: state.startDate,
      isOngoing: state.isOngoing,
      routine: state.routine,
      setStartDate: state.setStartDate,
      setRoutine: state.setRoutine,
      setReminderTimeMap: state.setReminderTimeMap,
    }))
  )
  const { onOpen: onOpenSpecifyDateModal } = useSpecifyDateModalStore()

  const selectedStartDate = useMemo(() => {
    if (startDate && dayjs(startDate).isSame(dayjs(), "day"))
      return StartDateOption.TODAY
    if (startDate && dayjs(startDate).isSame(dayjs().add(1, "day"), "day"))
      return StartDateOption.TOMORROW
    return StartDateOption.CUSTOM
  }, [startDate])

  const startDateOptions: DropdownOption[] = [
    { label: "Today", value: StartDateOption.TODAY },
    { label: "Tomorrow", value: StartDateOption.TOMORROW },
    {
      label:
        selectedStartDate === StartDateOption.CUSTOM
          ? dayjs(startDate).format("MMM DD, YYYY")
          : "Specify date...",
      value: StartDateOption.CUSTOM,
    },
  ]

  const getRoutineString = () => {
    if (isOngoing) return "Anytime"
    return capitalizeFirstLetter(routine)
  }

  const onRoutineClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    questRoutine: QuestRoutine
  ) => {
    e.stopPropagation()
    if (routine === questRoutine) return
    setRoutine(questRoutine)
    setReminderTimeMap({
      [questRoutine]: "",
    })
  }

  const setSelectedStartDate = (value: StartDateOption) => {
    switch (value) {
      case StartDateOption.TODAY:
        setStartDate(dayjs().toString())
        break
      case StartDateOption.TOMORROW:
        setStartDate(dayjs().add(1, "day").toString())
        break
      case StartDateOption.CUSTOM:
        onOpenSpecifyDateModal()
        break
    }
  }

  return {
    selectedStartDate,
    startDateOptions,
    getRoutineString,
    setSelectedStartDate,
    onRoutineClick,
  }
}
