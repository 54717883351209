import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { create } from "zustand"

import { useAddEditQuestStore } from "./addEditQuestModal/useAddEditQuestStore"
import { CloseButton } from "../../components/closeButton/CloseButton"
import TextArrowButton from "../../components/coaching/TextArrowButton"
import SlideUpModal from "../../components/slideUpModal/SlideUpModal"
import { localStorageItems } from "../../util/storage"

interface QuestGroupAnnouncementModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useQuestGroupAnnouncementModalStore =
  create<QuestGroupAnnouncementModalStoreProps>()((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const QuestGroupAnnouncementModal = () => {
  const { isOpen, onClose } = useQuestGroupAnnouncementModalStore()
  const { onOpen: onOpenAddEditQuestModal } = useAddEditQuestStore()

  const onCloseModal = () => {
    localStorage.setItem(
      localStorageItems.hasSeenQuestGroupAnnouncement,
      "true"
    )
    onClose()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onCloseModal}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <CloseButton onClick={onCloseModal} />
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: `${SPACING.space8} ${SPACING.space4} ${SPACING.space14} ${SPACING.space4}`,
        }}
        fullWidth
      >
        <Typography variant="h3">Introducing: Quest Groups!</Typography>
        <Typography variant="bodySmall">
          Now you can assign one quest to multiple children at once,{" "}
          <Typography variant="caption" style={{ lineHeight: "1.25rem" }}>
            and edit them as a group if you need to make changes.
          </Typography>{" "}
          Group quests are great when expectations are the same for all
          children, like when you'd like your triplets to all brush their teeth
          in the morning and at night!
        </Typography>
        <Typography variant="bodySmall">
          Turn it on by tapping on the toggle above the Create quest button at
          the bottom of Quest creation screen!
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="/images/previews/quest_group_preview.png"
            alt="Quest Group Announcement"
            style={{ width: "min(100%, 350px)" }}
          />
        </div>
        <TextArrowButton
          text="Check it out!"
          onClick={() => {
            onCloseModal()
            onOpenAddEditQuestModal()
          }}
          style={{
            width: "100%",
            borderRadius: SPACING.space2,
          }}
        />
      </FlexBox>
    </SlideUpModal>
  )
}
