import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"

import { RecommendedCoachCarousel } from "./recommendedCoaches/RecommendedCoachCarousel"
import RecommendedCoachModal from "./recommendedCoaches/RecommendedCoachModal"
import { useRecommendedCoachesQuery } from "./recommendedCoaches/useRecommendedCoachesQuery"
import { useRecommendedCoachModalStore } from "./recommendedCoaches/useRecommendedCoachModalStore"
import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { CoachingStep } from "../../../constants"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useCoachPostSelectionQuestionnaireQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { useCoachingStore } from "../useCoachingStore"

function RecommendedCoaches() {
  const router = useRouter()
  const { onOpen } = useRecommendedCoachModalStore()
  const { selectedCoach, setSelectedCoach, setSelectedCoachIndex, setStep } =
    useCoachingStore()
  const isOnMobileChrome = isChrome && isMobile && isAndroid
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const { isLoading: isLoadingQuestionnaire } =
    useCoachPostSelectionQuestionnaireQuery()
  const { data: recommendedCoaches, isLoading: isLoadingCoaches } =
    useRecommendedCoachesQuery()

  const isSmallMobile = useMediaQuery("(max-width: 350px)")

  const onSkipClick = () => {
    if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
      "appOnly"
    ) {
      localStorage.removeItem(localStorageItems.planChoiceFromPaywall)
      router.push("/me")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.RECOMMENDED_COACHES,
        is_tof: router.query.from !== "preview",
      })
      if (router.query.from === "preview") return
      if (isOnMobileChrome) openAddToHomescreenModal()
      else openIOSModal()
    } else {
      router.push("/paywall/trial")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.RECOMMENDED_COACHES,
        is_tof: router.query.from !== "preview",
      })
    }
  }

  const onSelectCoachClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_SELECT, {
      coach_id: selectedCoach?.user.id,
      is_tof: router.query.from !== "preview",
    })
    setStep(CoachingStep.PLAN_SELECT)
  }

  const onLearnMoreClick = () => {
    onOpen()
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_LEARN_MORE_ABOUT_COACH_CLICK, {
      coach_id: selectedCoach?.user.id,
      is_tof: router.query.from !== "preview",
    })
  }

  useEffect(() => {
    if (recommendedCoaches?.[0] && !selectedCoach) {
      setSelectedCoach(recommendedCoaches[0])
      setSelectedCoachIndex(0)
    }
  }, [
    recommendedCoaches,
    setSelectedCoach,
    setSelectedCoachIndex,
    selectedCoach,
  ])

  if (isLoadingQuestionnaire || isLoadingCoaches) return <PageLoader />

  if (recommendedCoaches) {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_RECOMMENDED_COACHES, {
      coach_ids: recommendedCoaches.map((coach) => coach.id),
    })
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space6}
      wrap={false}
      style={{ marginBottom: 0 }}
    >
      <Typography variant="h2" color={JoonUIColor.text.primary}>
        Your recommended coaches
      </Typography>
      <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
        Based on your responses, these are the best coaches for you. Select a
        coach to get started, and you can change it at anytime.
      </Typography>

      {recommendedCoaches && (
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          fullWidth
          gap={SPACING.space2}
          style={{ position: "relative" }}
        >
          <RecommendedCoachCarousel recommendedCoaches={recommendedCoaches} />

          <button
            onClick={onLearnMoreClick}
            style={{ marginTop: SPACING.space3 }}
          >
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              Learn more about {selectedCoach?.user.name || "this coach"}
            </Typography>
          </button>

          <div
            style={{
              width: "min(120%, 430px)",
              borderRadius: SPACING.space4,
              height: "180px",
              zIndex: 0,
              position: "absolute",
              top: isSmallMobile ? "372px" : "400px",
              background: JoonUIColor.background.lightGray,
            }}
          />
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.primary}
            style={{
              marginTop: SPACING.space8,
              marginBottom: SPACING.space2,
              zIndex: 1,
            }}
          >
            You can change your coach at any time.
          </Typography>

          <button
            onClick={onSelectCoachClick}
            style={{
              width: "min(100%, 400px)",
              position: "sticky",
              bottom: SPACING.space2,
              zIndex: 100,
            }}
          >
            <FlexBox
              fullWidth
              justify="space-between"
              style={{
                background: JoonColorExpanded.indigo400,
                boxShadow: `${SPACING.space1} ${SPACING.space2} ${SPACING.space2} rgba(0, 0, 0, 0.08)`,
                borderRadius: SPACING.space3,
                padding: SPACING.space4,
              }}
            >
              <Typography variant="bodyBold" color={JoonUIColor.text.inverted}>
                Continue with {selectedCoach?.user.name || "this coach"}
              </Typography>
              <ChevronRightIcon size={24} color={JoonUIColor.text.inverted} />
            </FlexBox>
          </button>
          <TextButton onClick={onSkipClick} style={{ zIndex: 1 }}>
            <Typography
              variant="caption"
              color={JoonUIColor.text.primaryAccent}
            >
              No thanks, I don't want a coach
            </Typography>
          </TextButton>

          <RecommendedCoachModal />
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default RecommendedCoaches
