import {
  faArrowRotateLeft,
  faCalendarDay,
  faCameraRetro,
  faClockRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo } from "react"

import { QuestDetailsCard } from "./QuestDetailsCard"
import { useViewQuestModalStore } from "./useViewQuestModalStore"
import {
  capitalizeFirstLetter,
  getRepetitionArrayFromRrule,
  getRepetitionText,
  getRepetitionIntervalFromRrule,
} from "../../../util/util"

export const QuestDetails = () => {
  const { questInstance } = useViewQuestModalStore()

  const questSeriesData = questInstance?.series

  const mandatory = questSeriesData?.mandatory
  const skill = questSeriesData?.skill
  const byWeekday = getRepetitionArrayFromRrule(
    questSeriesData?.recurrence || ""
  )
  const hasRepetition = !byWeekday.every((day) => !day)
  const interval = getRepetitionIntervalFromRrule(
    questSeriesData?.recurrence || ""
  )
  const startDate = questSeriesData?.start_date
  const endDate = questSeriesData?.end_date
  const recurrence = getRepetitionText(byWeekday, interval)
  const isOngoing = !hasRepetition && !endDate
  const routine = questSeriesData?.routine
  const timerLength = questSeriesData?.timer_length
  const reqReview = questSeriesData?.requires_review
  const reqPhoto = questSeriesData?.requires_completion_photo
  const reqNotes = questSeriesData?.requires_completion_notes
  const requiresReviewText = reqReview
    ? `Review${reqPhoto ? " with photo" : ""}${reqNotes ? (reqPhoto ? " and notes" : " with notes") : ""} required`
    : "No completion requirement"
  const dateTimeText = `${capitalizeFirstLetter(routine || "")}${
    hasRepetition
      ? `, Start${dayjs(startDate || undefined).isBefore(dayjs(), "day") ? "ed" : "s"} `
      : endDate
        ? `, Due `
        : ", Ongoing"
  } ${isOngoing ? "" : dayjs(startDate || undefined).isSame(dayjs(), "day") ? "Today" : dayjs(startDate).format("MMM D, YYYY")}`

  const labels = useMemo(() => {
    if (!questSeriesData) return []
    return [
      // Mandatory
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <img
          src={`/images/icons/splotch-solid-exclamation-${
            mandatory ? "" : "disabled-"
          }icon.svg`}
          alt={"Mandatory"}
          style={{ width: "15px" }}
        />
        <Typography variant="caption">
          {mandatory ? "Mandatory quest" : "Optional quest"}
        </Typography>
      </FlexBox>,

      // Skill
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        {skill ? (
          <>
            <img
              src={`/images/icons/${skill}-icon.png`}
              alt={skill || ""}
              style={{ width: "15px" }}
            />
            <Typography variant="caption">
              {capitalizeFirstLetter(skill)}
            </Typography>
          </>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faXmark}
              color={JoonUIColor.icon.neutral}
              style={{ width: "15px" }}
            />
            <Typography variant="caption">No skill set</Typography>
          </>
        )}
      </FlexBox>,

      // Recurrence
      ...(hasRepetition
        ? [
            <FlexBox
              direction="row"
              align="center"
              gap={SPACING.space2}
              wrap={false}
            >
              <FontAwesomeIcon
                icon={faArrowRotateLeft}
                color={JoonUIColor.icon.accent}
                style={{ width: "15px" }}
              />
              <Typography variant="caption">{recurrence}</Typography>
            </FlexBox>,
          ]
        : []),

      // Start date
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <FontAwesomeIcon
          icon={faCalendarDay}
          color={JoonUIColor.icon.accent}
          style={{ width: "15px" }}
        />
        <Typography variant="caption">{dateTimeText}</Typography>
      </FlexBox>,

      // Timer length
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <FontAwesomeIcon
          icon={faClockRotateLeft}
          color={
            timerLength ? JoonUIColor.icon.accent : JoonUIColor.icon.neutral
          }
          style={{ width: "15px" }}
        />
        <Typography variant="caption">
          {timerLength
            ? `${timerLength.slice(3, 5)}m ${timerLength.slice(6, 8)}s timer`
            : "No timer"}
        </Typography>
      </FlexBox>,

      // Requires review
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        <FontAwesomeIcon
          icon={faCameraRetro}
          color={reqReview ? JoonUIColor.icon.accent : JoonUIColor.icon.neutral}
          style={{ width: "15px" }}
        />
        <Typography variant="caption">{requiresReviewText}</Typography>
      </FlexBox>,
    ]
  }, [
    questSeriesData,
    mandatory,
    skill,
    hasRepetition,
    recurrence,
    timerLength,
    reqReview,
    dateTimeText,
    requiresReviewText,
  ])

  if (!questInstance) return <></>

  return (
    <FlexBox
      direction="column"
      style={{ borderRadius: SPACING.space2, overflow: "hidden" }}
      wrap={false}
      fullWidth
    >
      {labels.map((label, index) => (
        <QuestDetailsCard
          key={index}
          label={label}
          borderBottom={index !== labels.length - 1}
        />
      ))}
    </FlexBox>
  )
}
