import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  JoonUIColor,
  Typography,
  FlexBox,
  SPACING,
  Slider,
} from "@joonapp/web-shared"
import { useEffect, useMemo, useState } from "react"

import { BooleanCheckbox } from "../../../../components/booleanCheckbox/BooleanCheckbox"
import { useFamilyQuery } from "../../../../networking/queries"
import { FamilyQuerySelect } from "../../../../types"
import { useQuestGroupInstances } from "../../useQuestGroups"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

type ChildAssignDifficultyCardProps = {
  childId: number
  childName: string
  onClick: () => void
  isChecked: boolean
}

const ChildAssignDifficultyCard = ({
  childId,
  childName,
  onClick,
  isChecked,
}: ChildAssignDifficultyCardProps) => {
  const [isHovering, setIsHovering] = useState(false)
  const { assignedChildren, coinRewardsMap, setCoinRewardsMap, selectedQuest } =
    useAddEditQuestStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { questGroupInstances } = useQuestGroupInstances(selectedQuest)

  const initialValue = 10
  const isEditing = !!selectedQuest

  const onChangeDifficultyCoins = (value: string) => {
    const valueNum = parseInt(value, 10)
    if (value === "") return setCoinRewardsMap(childId, 0)
    if (isNaN(valueNum)) return
    if (valueNum > 30) return setCoinRewardsMap(childId, 30)
    if (valueNum < 1) return setCoinRewardsMap(childId, 1)
    else setCoinRewardsMap(childId, valueNum)
  }

  const sliderOptions = [1, 2, 3, 4, 5, 6, 8, 10, 12, 14, 17, 20, 23, 26, 30]

  const doNotAllowReassign =
    isEditing && assignedChildren.length >= (children?.length || 0)

  const sliderValue = useMemo(() => {
    const findClosestValue = (num: number, options: number[]) => {
      return options.reduce((prev, curr) =>
        Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev
      )
    }

    return findClosestValue(
      coinRewardsMap[childId] ?? initialValue,
      sliderOptions
    )
  }, [coinRewardsMap, childId, initialValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCoinRewardsMap(
      childId,
      questGroupInstances.find((instance) => instance.user_id === childId)
        ?.redeemable_reward ?? initialValue
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexBox
      id={childId.toString()}
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      style={{
        width: "100%",
        border: `1px solid ${JoonUIColor.border.default}`,
        borderRadius: SPACING.space2,
      }}
    >
      <button
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: isEditing ? "space-between" : "flex-start",
          alignItems: "center",
          borderRight: `1px solid ${JoonUIColor.border.default}`,
          padding: SPACING.space2,
          paddingLeft: isEditing ? SPACING.space3 : SPACING.space2,
          gap: SPACING.space2,
          width: "32%",
          cursor: doNotAllowReassign ? "default" : "pointer",
          pointerEvents: doNotAllowReassign ? "none" : "auto",
          borderTopLeftRadius: SPACING.space2,
          borderBottomLeftRadius: SPACING.space2,
          backgroundColor:
            !doNotAllowReassign && isHovering
              ? JoonUIColor.background.xlightGray
              : "transparent",
          transition: "background-color 0.2s ease-in-out",
        }}
      >
        {!isEditing && <BooleanCheckbox isChecked={isChecked} />}

        <FlexBox style={{ overflowX: "hidden", width: "fit-content" }}>
          <Typography
            variant="caption"
            textAlign="left"
            style={{
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {childName}
          </Typography>
        </FlexBox>

        {isEditing && !doNotAllowReassign && (
          <FontAwesomeIcon
            icon={faChevronDown}
            color={JoonUIColor.icon.neutral}
          />
        )}
      </button>

      <FlexBox
        direction="row"
        align="center"
        justify="space-between"
        style={{
          padding: SPACING.space2,
          width: "68%",
          height: "42px",
          paddingRight: SPACING.space2,
          borderTopRightRadius: SPACING.space2,
          borderBottomRightRadius: SPACING.space2,
          background: JoonUIColor.background.xlightGray,
          opacity: isChecked ? 1 : 0.2,
          pointerEvents: isChecked ? "auto" : "none",
          zIndex: 1000,
        }}
        wrap={false}
      >
        <Slider
          value={sliderValue}
          options={sliderOptions}
          setValue={(value) => setCoinRewardsMap(childId, value)}
          variant="secondary"
        />
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          gap={SPACING.space1}
          wrap={false}
          style={{ width: "42px", userSelect: "none" }}
        >
          <input
            style={{
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 500,
              fontFamily: "Urbanist",
              textAlign: "right",
              width: "100%",
              border: "none",
              outline: "none",
              background: "transparent",
            }}
            value={coinRewardsMap[childId] ?? ""}
            onChange={(e) => onChangeDifficultyCoins(e.target.value)}
            onBlur={() => {
              if (!coinRewardsMap[childId]) {
                setCoinRewardsMap(childId, 1)
              }
            }}
          />
          <img
            style={{ height: SPACING.space3 }}
            src="/images/icons/coin-icon.png"
            alt="coins"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default ChildAssignDifficultyCard
