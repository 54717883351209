import { faWarning } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"

import { useGetCoachSubscriptionQuery } from "../../networking/queries"
import { getCoachingStripeBillingUrl } from "../../networking/subscriptions"
import { SubscriptionStatus } from "../../types"

const FailedPaymentOverlay = () => {
  const { data: coachSubscriptionData, isLoading } =
    useGetCoachSubscriptionQuery()
  const [isLoadingBilling, setIsLoadingBilling] = useState(false)
  const hasExpiredSubscription =
    coachSubscriptionData?.status === SubscriptionStatus.EXPIRED

  if (isLoading || !hasExpiredSubscription) return null

  const redirectToBilling = async () => {
    if (isLoadingBilling) return
    setIsLoadingBilling(true)
    const billingUrl = await getCoachingStripeBillingUrl()
    window.open(billingUrl, "_self")
    setIsLoadingBilling(false)
  }

  return (
    <FlexBox
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 400,
        backdropFilter: "blur(2px)",
      }}
    >
      <FlexBox
        direction="column"
        justify="center"
        gap={SPACING.space2}
        style={{
          background: JoonColorExpanded.red100,
          padding: "16px 24px",
          borderRadius: "12px",
          width: "min(400px, 95vw)",
          zIndex: 2,
        }}
      >
        <FlexBox direction="row" gap={SPACING.space2} align="center">
          <FontAwesomeIcon
            icon={faWarning}
            fontSize="14px"
            color={JoonUIColor.semantic.destructive}
          />
          <Typography
            variant="caption"
            color={JoonUIColor.semantic.destructive}
          >
            We ran into an issue processing your payment!
          </Typography>
        </FlexBox>
        <Typography
          variant="bodySmall"
          color={JoonUIColor.semantic.destructive}
        >
          To ensure you can retain access to your coach before your subscription
          expires, please update your payment details.
        </Typography>
        <Button
          text="Update payment"
          buttonType="primary"
          onClick={redirectToBilling}
          style={{
            borderRadius: SPACING.space2,
            padding: `${SPACING.space2} ${SPACING.space3}`,
            marginTop: SPACING.space2,
          }}
          fullWidth
          isLoading={isLoadingBilling}
        />
      </FlexBox>
    </FlexBox>
  )
}

export default FailedPaymentOverlay
