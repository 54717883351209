import { JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"

export const SlideUpModalOption = ({
  onClick,
  title,
  align = "center",
  style,
}: {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
  align?: "center" | "left"
  style?: React.CSSProperties
}) => {
  return onClick ? (
    <button
      onClick={onClick}
      style={{
        width: "100%",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space4,
        ...style,
      }}
    >
      <Typography
        variant="body"
        textAlign={align}
        color={JoonUIColor.text.primaryAccent}
      >
        {title}
      </Typography>
    </button>
  ) : (
    <Typography
      variant="body"
      textAlign={align}
      color={JoonUIColor.text.primaryAccent}
      style={{
        cursor: "pointer",
        width: "100%",
        borderTop: `1px solid ${JoonUIColor.border.default}`,
        padding: SPACING.space4,
        ...style,
      }}
    >
      {title}
    </Typography>
  )
}
