import { QuestStatus, QuestType } from "@joonapp/web-shared"
import dayjs from "dayjs"
import toast from "react-hot-toast"

import { useQBDatePickerStore } from "./QuestBoardDatePicker"
import useQuestActions from "./useQuestActions"
import useQuestBoardFilters from "./useQuestBoardFilters"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import { useSubscription } from "../../hooks/useSubscription"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import { CoachUpsellModalType } from "../care/CoachUpsellModal"
import { useCoachUpsellModalStore } from "../care/CoachUpsellModal"
import useCareTeam from "../care/useCareTeam"

const useQuestBoardMultiSelect = () => {
  const { onOpen: openCoachUpsellModal } = useCoachUpsellModalStore()
  const { selectedQuestIds, clearSelectedQuestIds, setMultiSelectMode } =
    useQuestBoardMultiSelectStore()
  const { filteredQuests } = useQuestBoardFilters()
  const {
    useCompleteQuestsMutation,
    useRejectQuestsMutaton,
    useSkipQuestsMutation,
  } = useQuestActions()
  const { questBoardDate } = useQBDatePickerStore()
  const { hasCoach } = useCareTeam()

  const { checkRemainingFreeQuests } = useSubscription()

  const onClickCompleteQuests = () => {
    if (selectedQuestIds.length === 0) return
    checkRemainingFreeQuests(selectedQuestIds.length)
    useCompleteQuestsMutation.mutateAsync({
      questInstanceIds: selectedQuestIds,
      status: QuestStatus.VERIFIED,
      questDate: questBoardDate.format("YYYY-MM-DD"),
    })
    clearSelectedQuestIds()
    trackAnalyticEvent(ANALYTIC_EVENTS.COMPLETE_MULTIPLE_QUESTS, {
      count: selectedQuestIds.length,
      source: "quest_board",
    })
  }

  const onClickRejectQuests = () => {
    if (selectedQuestIds.length === 0) return
    useRejectQuestsMutaton.mutate({
      questInstanceIds: selectedQuestIds,
      status: QuestStatus.REJECTED,
      questDate: questBoardDate.format("YYYY-MM-DD"),
    })
    clearSelectedQuestIds()
    trackAnalyticEvent(ANALYTIC_EVENTS.REJECT_MULTIPLE_QUESTS, {
      count: selectedQuestIds.length,
      source: "quest_board",
    })
    if (hasCoach) return
    const lastSeenUpsellModalDate = localStorage.getItem(
      localStorageItems.rejectCoachUpsellModalSeenDate
    )
    const needsToSeeRejectCoachUpsellModal =
      !dayjs().isSame(dayjs(lastSeenUpsellModalDate), "week") ||
      !lastSeenUpsellModalDate
    if (needsToSeeRejectCoachUpsellModal) {
      setMultiSelectMode(false)
      openCoachUpsellModal(CoachUpsellModalType.reject)
    }
  }

  const onClickSkipQuests = () => {
    if (selectedQuestIds.length === 0) return

    // If one of the quests is a non-repeating quest,
    // Then we can not skip this
    const hasNonRepeatingQuest = filteredQuests.find(
      (quest) =>
        selectedQuestIds.includes(quest.id) &&
        quest.series.type !== QuestType.REPEATING
    )
    if (hasNonRepeatingQuest) {
      toast(
        "You can not skip a one-off Quest. To remove this Quest, mark it as Rejected or delete it!"
      )
      return
    }

    useSkipQuestsMutation.mutate({
      questInstanceIds: selectedQuestIds,
      status: QuestStatus.SKIPPED,
      questDate: questBoardDate.format("YYYY-MM-DD"),
    })
    clearSelectedQuestIds()
    trackAnalyticEvent(ANALYTIC_EVENTS.SKIP_MULTIPLE_QUESTS, {
      count: selectedQuestIds.length,
      source: "quest_board",
    })
  }

  return { onClickCompleteQuests, onClickRejectQuests, onClickSkipQuests }
}

export default useQuestBoardMultiSelect
