import {
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { SlideUpModalOption } from "../../../../components/modals/SlideUpModalOption"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface QuestPhotoUploadModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  isShowingPhoto: boolean
  setIsShowingPhoto: (isShowingPhoto: boolean) => void
}

export const useQuestPhotoUploadModalStore =
  create<QuestPhotoUploadModalStoreProps>()((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    isShowingPhoto: false,
    setIsShowingPhoto: (isShowingPhoto) => set({ isShowingPhoto }),
  }))

export const QuestPhotoUploadModal = () => {
  const { isOpen, onClose, isShowingPhoto, setIsShowingPhoto } =
    useQuestPhotoUploadModalStore()
  const { photoFile, setPhotoFile, photoUrl, setPhotoUrl } =
    useAddEditQuestStore()

  const onViewPhoto = () => {
    if (!photoUrl && photoFile && photoFile instanceof File) {
      setPhotoUrl(URL.createObjectURL(photoFile))
    }
    setIsShowingPhoto(true)
  }

  const onRemovePhoto = () => {
    setPhotoFile(null)
    setPhotoUrl(null)
    onClose()
  }

  if (isShowingPhoto && photoUrl) {
    return (
      <Modal
        isOpen={isShowingPhoto}
        onClose={() => {
          setIsShowingPhoto(false)
          onClose()
        }}
        style={{ zIndex: 1000, width: "min(100%, 600px)" }}
        displayCloseIcon
      >
        <img src={photoUrl} alt="quest" />
      </Modal>
    )
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox direction="column" align="center">
        <Typography
          variant="bodyXSmall"
          color={JoonUIColor.text.secondary}
          style={{ padding: SPACING.space2 }}
        >
          Edit photo
        </Typography>
        <SlideUpModalOption onClick={onViewPhoto} title="View Photo" />
        <label
          htmlFor="file-upload"
          className="icon-only-upload"
          aria-label="Upload Image"
          style={{ width: "100%" }}
        >
          <SlideUpModalOption title="Add new photo" />
        </label>
        <SlideUpModalOption onClick={onRemovePhoto} title="Remove photo" />
      </FlexBox>
    </SlideUpModal>
  )
}
