import {
  faCreditCard,
  faMessage,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  CloseCircleIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import PlanSlider from "./PlanSlider"
import PlanUpdateConfirmationModal from "./PlanUpdateConfirmationModal"
import PlanUpdateSuccess from "./PlanUpdateSuccess"
import usePlanUpdate from "./usePlanUpdate"
import usePlanUpdateModalStore from "./usePlanUpdateModalStore"
import ChatBubble from "../../../components/chatBubble/ChatBubble"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { useCoachSubscriptionPackagesQuery } from "../../../networking/queries"
import { SubscriptionPackage } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useLocation } from "../../../util/router"
import useCareTeam from "../../care/useCareTeam"

const PlanUpdateModal = () => {
  const {
    isOpen,
    onClose,
    numSessions,
    setNumSessions,
    hasChangedPlan,
    onOpenConfirmationModal,
    previousPackage,
    setPreviousPackage,
  } = usePlanUpdateModalStore()
  const { firstCoach } = useCareTeam()
  const {
    planFinePrint,
    sessionOptions,
    selectedPlanUnitAmount,
    isCurrentPlanSelected,
    activePlan,
    isLoadingSubPackages,
    isDowngrading,
    selectedPackage,
  } = usePlanUpdate()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const packages: SubscriptionPackage[] = Object.values(
    subscriptionPackages || {}
  )
  const location = useLocation()

  const handleViewNewPlan = (value: number) => {
    const source = location.pathname.includes("sessions")
      ? "sessions_page"
      : "manage_subscription"
    setNumSessions(value)
    const newPlan = packages.find((plan) => plan.num_sessions === value)
    trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_NEW_COACH_PLAN, {
      source,
      lookup_key: newPlan?.lookup_key,
    })
  }

  // Save the "previous" plan for later in analytics
  // after the plan is already updated
  useEffect(() => {
    if (activePlan && !previousPackage) {
      const previousSessions = activePlan.num_sessions
      const currentPackage = packages.find(
        (plan) => plan.num_sessions === previousSessions
      ) as SubscriptionPackage
      setPreviousPackage(currentPackage)

      // and then set initial value to be +1 (default is 2)
      if (currentPackage.num_sessions === 2) {
        setNumSessions(3)
      } else if (currentPackage.num_sessions === 3) {
        setNumSessions(4)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlan])

  if (isLoadingSubPackages || !firstCoach) return null

  const infoCards = [
    {
      icon: faMessage,
      text: "Anytime direct messaging with your coach",
    },
    ...(numSessions > 0
      ? [
          {
            icon: faVideo,
            text: `${numSessions}x 45-minute video calls each month`,
          },
        ]
      : []),
    {
      icon: faCreditCard,
      text: "HSA/FSA eligible",
    },
  ]

  const handleSelectPlan = async () => {
    onOpenConfirmationModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.CONFIRM_UPDATE_COACH_PLAN, {
      source: location.pathname.includes("sessions")
        ? "sessions_page"
        : "manage_subscription",
      old_plan_lookup_key: previousPackage?.lookup_key,
      new_plan_lookup_key: selectedPackage?.lookup_key,
      is_upgrade: !isDowngrading,
    })
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
    >
      {hasChangedPlan ? (
        <PlanUpdateSuccess />
      ) : (
        <FlexBox direction="column" gap={SPACING.space2}>
          <TextButton
            onClick={onClose}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: SPACING.space2,
              borderRadius: "50%",
            }}
          >
            <CloseCircleIcon size={16} color={JoonUIColor.icon.neutral} />
          </TextButton>
          <FlexBox
            direction="column"
            wrap={false}
            gap={SPACING.space4}
            style={{
              padding: SPACING.space6,
              paddingTop: SPACING.space4,
              minHeight: "min(100dvh, 680px)",
            }}
          >
            <Typography variant="h3">Update coaching plan</Typography>
            <FlexBox direction="column">
              <div style={{ padding: SPACING.space4, margin: "0 auto" }}>
                <ChatBubble
                  text="How frequently would you like to meet with me?"
                  imageUrl={firstCoach.profile_image_url || ""}
                />
              </div>
              <PlanSlider
                sessionOptions={sessionOptions}
                numSessions={numSessions}
                setNumSessions={handleViewNewPlan}
                currentPlanSessions={activePlan?.num_sessions}
              />
            </FlexBox>
            <div style={{ width: "100%", minHeight: "320px" }}>
              <FlexBox
                direction="column"
                style={{
                  border: `1px solid ${JoonUIColor.border.accent}`,
                  borderRadius: SPACING.space2,
                  overflow: "hidden",
                }}
              >
                <FlexBox
                  direction="column"
                  gap={SPACING.space1}
                  style={{
                    background: JoonUIColor.background.xlightGray,
                    padding: SPACING.space4,
                    borderBottom: `1px solid ${JoonUIColor.border.default}`,
                  }}
                >
                  <Typography variant="h3" textAlign="center">
                    ${selectedPlanUnitAmount.toFixed(2)} per month
                  </Typography>
                  <Typography
                    variant="caption"
                    textAlign="center"
                    color={JoonUIColor.text.secondary}
                  >
                    Change plan anytime with no penalty!
                  </Typography>
                </FlexBox>
                <FlexBox
                  direction="column"
                  gap={SPACING.space2}
                  style={{
                    padding: SPACING.space2,
                    borderBottom: `1px solid ${JoonUIColor.border.default}`,
                  }}
                >
                  {infoCards.map(({ icon, text }) => (
                    <FlexBox
                      direction="row"
                      gap={SPACING.space2}
                      align="center"
                    >
                      <FontAwesomeIcon
                        icon={icon}
                        color={JoonUIColor.icon.accent}
                      />
                      <Typography
                        variant="bodySmall"
                        style={{ fontWeight: 600 }}
                      >
                        {text}
                      </Typography>
                    </FlexBox>
                  ))}
                </FlexBox>
                <FlexBox
                  direction="column"
                  gap={SPACING.space2}
                  style={{ padding: SPACING.space4 }}
                >
                  {isCurrentPlanSelected ? (
                    <FlexBox
                      style={{
                        width: "fit-content",
                        margin: "0 auto",
                        padding: `${SPACING.space2} ${SPACING.space4}`,
                        borderRadius: SPACING.space2,
                        background: JoonColorExpanded.yellow200,
                      }}
                    >
                      <Typography
                        variant="caption"
                        color={JoonUIColor.semantic.warning}
                      >
                        *This is your current plan!
                      </Typography>
                    </FlexBox>
                  ) : (
                    <FlexBox direction="column" gap={SPACING.space2}>
                      <Typography
                        variant="bodyXSmall"
                        style={{ fontWeight: 500 }}
                        color={JoonUIColor.text.secondary}
                      >
                        {planFinePrint}
                      </Typography>
                      <Button
                        style={{ borderRadius: SPACING.space2 }}
                        buttonType="primary"
                        fullWidth
                        text="Continue with this plan"
                        onClick={handleSelectPlan}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </div>
            <TextButton
              onClick={onClose}
              style={{ margin: "0 auto", marginTop: "auto" }}
            >
              <Typography
                variant="caption"
                textAlign="center"
                color={JoonUIColor.text.primaryAccent}
              >
                Nevermind
              </Typography>
            </TextButton>
          </FlexBox>
        </FlexBox>
      )}
      <PlanUpdateConfirmationModal />
    </SlideUpModal>
  )
}

export default PlanUpdateModal
