import {
  Button,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  Modal,
  QuestRoutine,
  SPACING,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"

import QuestDndList from "./QuestDndList.tsx"
import { useQuestDndListStore } from "./useQuestDndListStore.ts"
import { useReorderQuestsMutation } from "./useReorderQuestsMutation"
import { Toasts } from "../../../../components/toaster/Toaster.tsx"
import { QUERY_KEYS } from "../../../../constants.tsx"
import useMobile from "../../../../hooks/useMobile.ts"
import { useReorderQuestsQuery } from "../../../../networking/queries.ts"
import {
  ANALYTIC_EVENTS,
  trackAnalyticEvent,
} from "../../../../util/analytics.ts"
import { queryClient } from "../../../../util/queryClient.ts"
import QuestBoardChildToggleHeader from "../../QuestBoardChildToggleHeader.tsx"
import useQuestBoardStore from "../../useQuestBoardStore.ts"

const ReorderQuestsModal = () => {
  const { data: quests } = useReorderQuestsQuery()
  const { isOpen, onClose, userHasMadeChanges } = useQuestDndListStore()
  const isMobile = useMobile()
  const { selectedChildId } = useQuestBoardStore()
  const { sections } = useQuestDndListStore()
  const [isLoadingQuestBoard, setIsLoadingQuestBoard] = useState(false)

  const reorderQuestsMutation = useReorderQuestsMutation()

  useEffect(() => {
    document.getElementById("quests-dnd-list")?.scrollTo(0, 0)
  }, [selectedChildId])

  const onSave = async () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.REORDER_QUESTS_SAVE)
    const newOrders = [
      ...sections[0][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.MORNING,
        role: "parent",
      })),
      ...sections[1][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.AFTERNOON,
        role: "parent",
      })),
      ...sections[2][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.NIGHT,
        role: "parent",
      })),
      ...sections[3][1].map((quest) => ({
        id: quest.id,
        order: quest.order,
        routine: QuestRoutine.ANYTIME,
        role: "parent",
      })),
    ]

    const newOrdersWithGroupedQuests: typeof newOrders = []

    // we need to include the remaining grouped quests (all will have same order)
    newOrders.forEach((orderedQuest) => {
      const questSeries = quests?.find((quest) => quest.id === orderedQuest.id)
      const questGroupId = questSeries?.quest_group_id || null

      const questGroupSeries =
        questGroupId === null || !quests
          ? []
          : quests.filter((quest) => quest.quest_group_id === questGroupId)

      if (questGroupSeries.length > 1) {
        newOrdersWithGroupedQuests.push(
          ...questGroupSeries.map((series) => ({
            id: series.id,
            order: orderedQuest.order,
            routine: orderedQuest.routine,
            role: "parent",
          }))
        )
      } else newOrdersWithGroupedQuests.push(orderedQuest)
    })

    await reorderQuestsMutation.mutateAsync(newOrdersWithGroupedQuests)
    setIsLoadingQuestBoard(true)
    await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
    setIsLoadingQuestBoard(false)
    onClose()
    toast.success(Toasts.successReorderingQuests, { duration: 1500 })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      mobileFull
      style={{ height: "100vh", width: "min(600px, 100%)" }}
      animate
    >
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        fullWidth
        wrap={false}
        style={{
          padding: SPACING.space4,
          paddingTop: 0,
        }}
      >
        <button
          style={{
            position: "absolute",
            right: SPACING.space4,
            top: SPACING.space4,
            padding: SPACING.space2,
          }}
          onClick={onClose}
        >
          <CloseIcon color={JoonUIColor.icon.neutral} />
        </button>
        <QuestBoardChildToggleHeader isOnDnd={true} />
        <div
          id="quests-dnd-list"
          style={{
            height: `${userHasMadeChanges ? (isMobile ? 82 : 84) : isMobile ? 88 : 90}dvh`,
            transition: "height 0.2s ease",
            overflowY: "scroll",
            overflowX: "hidden",
            width: "min(500px, 96%)",
            padding: `0 ${SPACING.space1}`,
          }}
        >
          <QuestDndList />
        </div>

        {userHasMadeChanges && (
          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            wrap={false}
            gap={SPACING.space2}
            style={{
              position: "fixed",
              bottom: isMobile ? "10px" : SPACING.space2,
              left: "50%",
              transform: "translateX(-50%)",
              width: `min(500px, 100%)`,
              height: "fit-content",
              padding: `${SPACING.space2} ${SPACING.space4}`,
              background: JoonUIColor.background.primaryNeutral,
              border: "none",
              zIndex: 100,
            }}
          >
            <Button
              buttonType="primary"
              text="Save Order"
              onClick={onSave}
              fullWidth
              isLoading={reorderQuestsMutation.isLoading || isLoadingQuestBoard}
            />
          </FlexBox>
        )}
      </FlexBox>
    </Modal>
  )
}

export default ReorderQuestsModal
