import {
  FlexBox,
  JoonUIColor,
  SkillKey,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { useSkills } from "./useSkills"
import { SlideUpModalOption } from "../../../../components/modals/SlideUpModalOption"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface AddSkillModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useAddSkillModalStore = create<AddSkillModalStoreProps>()(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export const AddSkillModal = () => {
  const { isOpen, onClose } = useAddSkillModalStore()
  const { category, setCategory } = useAddEditQuestStore()
  const { skillOptions } = useSkills()

  const onAddSkill = (skill: SkillKey | null) => {
    setCategory(skill)
    onClose()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox direction="column" align="center">
        <Typography
          variant="bodyXSmall"
          color={JoonUIColor.text.secondary}
          style={{ padding: SPACING.space2 }}
        >
          Select skill
        </Typography>
        {skillOptions.map((skill, index) => (
          <SlideUpModalOption
            key={index}
            onClick={() => onAddSkill(skill.value as SkillKey | null)}
            title={skill.label as string}
            align="left"
            style={{
              backgroundColor:
                skill.value === category
                  ? JoonUIColor.background.lightAccent
                  : "transparent",
            }}
          />
        ))}
      </FlexBox>
    </SlideUpModal>
  )
}
