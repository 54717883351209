import {
  Family,
  FamilyChildProfile,
  FamilyParentProfile,
  User,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../constants"
import { editParent } from "../../networking/user"
import { queryClient } from "../../util/queryClient"

const useEditParentMutation = () => {
  return useMutation({
    mutationFn: editParent,
    onMutate: async ({ parentInfo, userId }) => {
      await queryClient.cancelQueries([QUERY_KEYS.FAMILY])
      await queryClient.cancelQueries([QUERY_KEYS.USER])
      const previousFamily = queryClient.getQueryData([
        QUERY_KEYS.FAMILY,
      ]) as Family
      const previousUser = queryClient.getQueryData<User>([QUERY_KEYS.USER])

      const newUser = {
        ...previousUser,
        ...(parentInfo.name && { name: parentInfo.name }),
        ...(parentInfo.last_name && { last_name: parentInfo.last_name }),
        ...(parentInfo.avatar && { avatar: parentInfo.avatar }),
      }

      const newFamily = {
        ...previousFamily,
        profiles: previousFamily?.profiles?.map(
          (profile: FamilyParentProfile | FamilyChildProfile) => {
            if (profile?.user?.id !== userId) return profile
            profile = profile as FamilyParentProfile
            return {
              ...profile,
              ...(parentInfo.nickname && { nickname: parentInfo.nickname }),
              user: newUser,
            }
          }
        ),
      }

      queryClient.setQueryData([QUERY_KEYS.FAMILY], newFamily)
      queryClient.setQueryData([QUERY_KEYS.USER], newUser)
      return { previousFamily, previousUser }
    },
    onError: (_err, _family, context) => {
      queryClient.setQueryData([QUERY_KEYS.FAMILY], context?.previousFamily)
      queryClient.setQueryData([QUERY_KEYS.USER], context?.previousUser)
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      queryClient.invalidateQueries([QUERY_KEYS.USER])
    },
  })
}

export default useEditParentMutation
