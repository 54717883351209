import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { ReactNode } from "react"

export const QuestDetailsCard = ({
  label,
  borderBottom = true,
}: {
  label: string | ReactNode
  borderBottom?: boolean
}) => {
  return (
    <FlexBox
      direction="column"
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        borderBottom: borderBottom
          ? `1px solid ${JoonUIColor.border.default}`
          : "none",
        background: JoonUIColor.background.primaryNeutral,
      }}
      fullWidth
    >
      <FlexBox direction="row" align="center" gap={SPACING.space2} wrap={false}>
        {typeof label === "string" ? (
          <Typography variant="caption">{label}</Typography>
        ) : (
          label
        )}
      </FlexBox>
    </FlexBox>
  )
}
