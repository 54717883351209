import {
  Button,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import Calendar from "react-calendar"
import { create } from "zustand"

import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export type CalendarDatePiece = Date | null
export type CalendarDate =
  | CalendarDatePiece
  | [CalendarDatePiece, CalendarDatePiece]

interface SpecifyDateModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useSpecifyDateModalStore = create<SpecifyDateModalStoreProps>()(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
)

export default function SpecifyDateModal() {
  const { startDate, setStartDate } = useAddEditQuestStore()
  const { isOpen, onClose } = useSpecifyDateModalStore()

  const relativeDate = dayjs(startDate).isSame(dayjs(), "day")
    ? "Today"
    : dayjs(startDate).isSame(dayjs().add(1, "day"), "day")
      ? "Tomorrow"
      : dayjs(startDate).format("MMM DD, YYYY")

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1001}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        fullWidth
        gap={SPACING.space4}
        style={{
          position: "relative",
          backgroundColor: JoonUIColor.background.xlightGray,
          paddingTop: SPACING.space4,
          paddingBottom: SPACING.space3,
        }}
      >
        <CloseButton onClick={onClose} />
        <Typography
          variant="bodyBold"
          color={JoonUIColor.text.primary}
          textAlign="left"
          style={{ paddingLeft: SPACING.space4 }}
        >
          Specify Date
        </Typography>

        <FlexBox
          direction="column"
          align="flex-start"
          wrap={false}
          style={{
            padding: `${SPACING.space4} ${SPACING.space1}`,
            backgroundColor: JoonUIColor.background.primaryNeutral,
            width: "fit-content",
          }}
        >
          <FlexBox style={{ paddingLeft: SPACING.space6 }} fullWidth>
            {startDate && (
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                {relativeDate}
              </Typography>
            )}
          </FlexBox>
          <Calendar
            onChange={(date) => setStartDate(dayjs(date as Date).toString())}
            value={startDate ? dayjs(startDate).toDate() : null}
            minDate={dayjs().toDate()}
            maxDate={undefined}
            calendarType="US"
            showNeighboringMonth={false}
            next2Label={null}
            prev2Label={null}
            defaultView="month"
            selectRange={false}
            showDoubleView={false}
          />
        </FlexBox>

        <div
          style={{
            width: "100%",
            paddingLeft: SPACING.space4,
            paddingRight: SPACING.space4,
          }}
        >
          <Button
            buttonType="primary"
            text="Done"
            fullWidth
            onClick={onClose}
            style={{ borderRadius: SPACING.space2 }}
          />
        </div>
      </FlexBox>
    </SlideUpModal>
  )
}
