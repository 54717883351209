import {
  FlexBox,
  JoonUIColor,
  JoonColorExpanded,
  SPACING,
  Typography,
  WarningTriangleIcon,
  CalendarSolidIcon,
  VideoIcon,
  ChevronRightIcon,
  BanIcon,
  SessionBooking,
  SessionBillingPeriod,
  SessionBookingLinkType,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useSessionBookingModalStore } from "./hooks/useSessionBookingModalStore"
import Button from "../../../components/buttons/Button2"
import { trackAnalyticEvent } from "../../../util/analytics"
import { ANALYTIC_EVENTS } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { formatDate } from "../../../util/util"

const SessionSlot = ({
  session,
  billingPeriod,
}: {
  session: SessionBooking | undefined
  billingPeriod?: SessionBillingPeriod
}) => {
  const isBooked = !!session

  const sessionDate = isBooked ? dayjs(session?.start_datetime) : undefined
  const sessionIsTomorrow = sessionDate?.isSame(dayjs().add(1, "day"), "day")
  const sessionIsWithin24Hours =
    dayjs(session?.start_datetime).isBefore(dayjs().add(24, "hours")) &&
    dayjs(session?.start_datetime).isAfter(dayjs())
  const { onOpen: openSessionBookingModal } = useSessionBookingModalStore()
  const router = useRouter()
  const careteamUserId = router.query.careTeamUserId

  const handleSchedule = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (!billingPeriod?.booking_link) return
    trackAnalyticEvent(ANALYTIC_EVENTS.SCHEDULE_COACHING_SESSION)
    localStorage.setItem(localStorageItems.needsToRefreshBookings, "true")
    openSessionBookingModal(
      billingPeriod.booking_link,
      SessionBookingLinkType.SCHEDULE
    )
  }

  const handleJoinSession = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (!session?.meeting_link) return
    trackAnalyticEvent(ANALYTIC_EVENTS.JOIN_COACHING_SESSION)
    window.open(session.meeting_link, "_blank")
  }

  const handleClick = () => {
    if (!isBooked) return
    router.push(`/coach/${careteamUserId}/sessions/${session?.id}`)
  }

  return (
    <button style={{ width: "100%" }} onClick={handleClick}>
      <FlexBox
        direction="row"
        align="center"
        justify="space-between"
        wrap={false}
        style={{
          background: sessionIsWithin24Hours
            ? JoonColorExpanded.yellow300
            : sessionIsTomorrow
              ? JoonColorExpanded.yellow100
              : JoonUIColor.background.xlightGray,
          borderRadius: SPACING.space2,
          padding: `${SPACING.space3} ${SPACING.space4}`,
        }}
      >
        {isBooked ? (
          <FlexBox
            direction="row"
            align="center"
            wrap={false}
            gap={SPACING.space2}
          >
            <Typography variant="caption" color={JoonUIColor.text.secondary}>
              {formatDate(new Date(session?.start_datetime), "MMM D")}
              {", "}
              {formatDate(new Date(session?.start_datetime), "h:mm A", false)}
            </Typography>
            {session?.marked_no_show && (
              <FlexBox
                direction="row"
                gap={SPACING.space1}
                align="center"
                style={{
                  background: JoonColorExpanded.red100,
                  borderRadius: "100px",
                  width: "fit-content",
                  padding: `${SPACING.space05} ${SPACING.space2}`,
                }}
              >
                <BanIcon />
                <Typography variant="caption">No-show</Typography>
              </FlexBox>
            )}
          </FlexBox>
        ) : (
          <FlexBox
            direction="row"
            wrap={false}
            gap={SPACING.space2}
            align="center"
          >
            <WarningTriangleIcon
              color={JoonUIColor.semantic.warning}
              size={16}
            />
            <Typography variant="caption" color={JoonUIColor.semantic.warning}>
              Not scheduled
            </Typography>
          </FlexBox>
        )}
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          style={{ width: "fit-content" }}
        >
          {isBooked ? (
            <>
              {sessionIsWithin24Hours && !session?.marked_no_show && (
                <Button
                  text="Join session"
                  buttonType="secondary"
                  icon={VideoIcon}
                  onClick={handleJoinSession}
                  size="small"
                />
              )}
              <div
                style={{
                  width: SPACING.space8,
                  height: SPACING.space8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: JoonUIColor.background.primaryNeutral,
                  borderRadius: "50%",
                }}
              >
                <ChevronRightIcon size={24} color={JoonUIColor.text.primary} />
              </div>
            </>
          ) : (
            <Button
              text="Schedule"
              buttonType="primary"
              size="small"
              icon={CalendarSolidIcon}
              onClick={handleSchedule}
            />
          )}
        </FlexBox>
      </FlexBox>
    </button>
  )
}

export default SessionSlot
