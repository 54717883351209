import {
  faCalendarDay,
  faChevronRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  DropdownInput,
  FlexBox,
  JoonUIColor,
  QuestRoutine,
  SPACING,
  Switch,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useDateTimeModalStore } from "./DateTimeModal"
import { useSetTimeModalStore } from "./SetTimeModal"
import { useDateTime } from "./useDateTime"
import { BooleanCheckbox } from "../../../../components/booleanCheckbox/BooleanCheckbox"
import { ExpandCard } from "../../../../components/card/ExpandCard"
import { REPETITIONS, StartDateOption } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { capitalizeFirstLetter } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const DateTimeSection = () => {
  const {
    repetition,
    routine,
    startDate,
    isOngoing,
    reminderTimeMap,
    setStartDate,
    setRoutine,
    setIsOngoing,
    setReminderTimeMap,
    setRepetition,
    setRepeatsFrequency,
    selectedQuest,
  } = useAddEditQuestStore()
  const { onOpen: onOpenDateTimeModal } = useDateTimeModalStore()
  const { onOpen: onOpenSetTimeModal } = useSetTimeModalStore()
  const {
    selectedStartDate,
    startDateOptions,
    getRoutineString,
    setSelectedStartDate,
    onRoutineClick,
  } = useDateTime()
  const isMobile = useMobile()

  const hasRepetition = repetition.some((day) => day)
  const isEditing = !!selectedQuest

  const startingText = isOngoing
    ? "Ongoing"
    : !hasRepetition
      ? "Due"
      : dayjs(startDate).isBefore(dayjs(), "day")
        ? "Started"
        : "Starts"

  const relativeDate = isOngoing
    ? ""
    : dayjs(startDate || undefined).isSame(dayjs(), "day")
      ? "Today"
      : dayjs(startDate).isSame(dayjs().add(1, "day"), "day")
        ? "Tomorrow"
        : dayjs(startDate).format("MMM DD, YYYY")

  const onToggleAnytime = () => {
    if (routine === QuestRoutine.ANYTIME) setRoutine(QuestRoutine.MORNING)
    else setRoutine(QuestRoutine.ANYTIME)
    setReminderTimeMap({})
  }

  return (
    <ExpandCard
      icon={faCalendarDay}
      text={
        <Typography variant="caption" color={JoonUIColor.text.primary}>
          {getRoutineString()}, {startingText} {relativeDate}
        </Typography>
      }
      additionalContent={
        <FlexBox direction="column" gap={SPACING.space2}>
          {!hasRepetition && (
            <button
              onClick={(e) => e.stopPropagation()}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: SPACING.space2,
                padding: SPACING.space3,
                borderRadius: SPACING.space2,
                background: JoonUIColor.background.primaryNeutral,
                cursor: "default",
              }}
            >
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                justify="space-between"
              >
                <Typography variant="caption">
                  Keep this quest until complete
                </Typography>
                <Switch
                  checked={isOngoing}
                  onChange={() => setIsOngoing(!isOngoing)}
                  name="ongoing-quest"
                />
              </FlexBox>
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.secondary}
                textAlign="left"
              >
                This quest will continue to show up until it's done and will be
                scheduled as part of an Anytime quest. Best for one of those
                once in a while bonus tasks!
              </Typography>
            </button>
          )}

          {(!isOngoing || hasRepetition) && (
            <>
              <Typography variant="caption" color={JoonUIColor.text.secondary}>
                {hasRepetition ? "Starts" : "Due"}
              </Typography>

              <button
                onClick={(e) => e.stopPropagation()}
                style={{
                  width: "100%",
                  cursor: "default",
                }}
              >
                <DropdownInput
                  options={startDateOptions}
                  label={
                    startDateOptions.find(
                      (option) => option.value === selectedStartDate
                    )?.label
                  }
                  onSelect={(option) =>
                    setSelectedStartDate(option.value as StartDateOption)
                  }
                  style={{ background: JoonUIColor.background.primaryNeutral }}
                  menuStyle={{ minWidth: "300px" }}
                  size="large"
                  fullWidth
                />
              </button>

              <Typography variant="caption" color={JoonUIColor.text.secondary}>
                Specify time of day
              </Typography>

              <button
                onClick={(e) => {
                  e.stopPropagation()
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: SPACING.space2,
                  padding: SPACING.space3,
                  border: `1px solid ${JoonUIColor.border.default}`,
                  borderRadius: SPACING.space2,
                  background: JoonUIColor.background.primaryNeutral,
                  width: "100%",
                  cursor: "default",
                }}
              >
                <FlexBox
                  direction="row"
                  gap={SPACING.space2}
                  align="center"
                  justify="space-between"
                  fullWidth
                >
                  <Typography variant="caption">Anytime quest</Typography>
                  <Switch
                    name="anytime-quest"
                    checked={routine === QuestRoutine.ANYTIME}
                    onChange={onToggleAnytime}
                  />
                </FlexBox>
                {routine === QuestRoutine.ANYTIME && (
                  <Typography
                    variant="bodyXSmall"
                    color={JoonUIColor.text.secondary}
                    textAlign="left"
                  >
                    Your child can do this at their leisure. We show Anytime
                    quests underneath Night quests.
                  </Typography>
                )}
              </button>

              <FlexBox
                direction="row"
                align="center"
                justify="space-between"
                fullWidth
                wrap={false}
              >
                {routine !== QuestRoutine.ANYTIME &&
                  Object.values(QuestRoutine)
                    .slice(0, 3)
                    .map((questRoutine, index) => (
                      <button
                        key={index}
                        style={{
                          width: "32%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: SPACING.space1,
                          justifyContent: "space-between",
                          border: `1px solid ${JoonUIColor.border.default}`,
                          borderRadius: SPACING.space2,
                          padding: SPACING.space2,
                          background: JoonUIColor.background.primaryNeutral,
                        }}
                        onClick={(e) => onRoutineClick(e, questRoutine)}
                      >
                        <FlexBox
                          direction="row"
                          align="center"
                          gap={SPACING.space2}
                          wrap={false}
                          fullWidth
                        >
                          <BooleanCheckbox
                            isChecked={routine === questRoutine}
                            type="radio"
                          />
                          <Typography variant="caption">
                            {capitalizeFirstLetter(questRoutine)}
                          </Typography>
                        </FlexBox>
                        <TextButton
                          onClick={(
                            e?: React.MouseEvent<HTMLButtonElement>
                          ) => {
                            e?.stopPropagation()
                            onOpenSetTimeModal(questRoutine)
                          }}
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: SPACING.space1,
                          }}
                        >
                          <Typography
                            variant="bodyXSmall"
                            color={
                              reminderTimeMap?.[questRoutine]?.length > 0
                                ? JoonUIColor.text.primary
                                : routine === questRoutine
                                  ? JoonUIColor.text.secondary
                                  : JoonUIColor.background.disabled
                            }
                            style={{ fontWeight: 600, whiteSpace: "nowrap" }}
                          >
                            {routine === questRoutine &&
                            reminderTimeMap?.[questRoutine]?.length > 0
                              ? reminderTimeMap?.[questRoutine]
                              : "Set due time"}
                          </Typography>
                          {routine === questRoutine &&
                          reminderTimeMap?.[questRoutine]?.length > 0 ? (
                            <TextButton
                              onClick={(
                                e?: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                e?.stopPropagation()
                                setReminderTimeMap({
                                  ...reminderTimeMap,
                                  [questRoutine]: "",
                                })
                              }}
                              style={{ padding: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faXmark}
                                color={JoonUIColor.icon.neutral}
                                size="lg"
                              />
                            </TextButton>
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronRight}
                              color={JoonUIColor.icon.neutral}
                            />
                          )}
                        </TextButton>
                      </button>
                    ))}
              </FlexBox>
            </>
          )}
        </FlexBox>
      }
      reset={!isOngoing}
      onReset={() => {
        setStartDate(dayjs().toString())
        setRoutine(QuestRoutine.ANYTIME)
        setReminderTimeMap({})
        const isNotRepeating = !isOngoing // store isOngoing
        setIsOngoing(true)
        // we don't want to allow users to change recurrence if editing
        if (isEditing && isNotRepeating) return
        setRepetition(REPETITIONS.noDays)
        setRepeatsFrequency(null)
      }}
      onClick={() => {
        if (isMobile) return onOpenDateTimeModal()
      }}
      selected
    />
  )
}
