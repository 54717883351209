import { faCameraRetro } from "@fortawesome/free-solid-svg-icons"
import {
  FlexBox,
  JoonUIColor,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useCompletionRequirementModalStore } from "./CompletionRequirementModal"
import { useCompletionRequirement } from "./useCompletionRequirement"
import { BooleanCheckbox } from "../../../../components/booleanCheckbox/BooleanCheckbox"
import { ExpandCard } from "../../../../components/card/ExpandCard"
import useMobile from "../../../../hooks/useMobile"
import { localStorageItems } from "../../../../util/storage"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const CompletionRequirementSection = () => {
  const {
    reqReview,
    reqPhoto,
    reqNotes,
    setReqReview,
    setReqPhoto,
    setReqNotes,
  } = useAddEditQuestStore()
  const { onOpen: onOpenCompletionRequirementModal } =
    useCompletionRequirementModalStore()
  const { toggleReview, togglePhoto, toggleNotes } = useCompletionRequirement()
  const { selectedQuest } = useAddEditQuestStore()

  const isMobile = useMobile()

  const isEditing = !!selectedQuest

  const onReset = () => {
    localStorage.setItem(localStorageItems.prefersToReviewQuest, "false")
    localStorage.setItem(localStorageItems.prefersToReviewWithPicture, "false")
    localStorage.setItem(localStorageItems.prefersToReviewWithNotes, "false")
    setReqReview(false)
    setReqPhoto(false)
    setReqNotes(false)
  }

  useEffect(() => {
    if (isEditing) return
    setReqReview(
      localStorage.getItem(localStorageItems.prefersToReviewQuest) === "true"
    )
    setReqPhoto(
      localStorage.getItem(localStorageItems.prefersToReviewWithPicture) ===
        "true"
    )
    setReqNotes(
      localStorage.getItem(localStorageItems.prefersToReviewWithNotes) ===
        "true"
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExpandCard
      icon={faCameraRetro}
      text={
        <Typography
          variant="caption"
          color={
            reqReview ? JoonUIColor.text.primary : JoonUIColor.text.secondary
          }
        >
          {reqReview
            ? `Review${reqPhoto ? " with photo" : ""}${reqNotes ? (reqPhoto ? " and notes" : " with notes") : ""} required`
            : "Add completion requirement"}
        </Typography>
      }
      additionalContent={
        <FlexBox direction="column" gap={SPACING.space2}>
          <button
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: SPACING.space2,
              padding: SPACING.space3,
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
              background: JoonUIColor.background.primaryNeutral,
              cursor: "default",
            }}
          >
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              align="center"
              justify="space-between"
            >
              <Typography variant="caption">
                I would like to review this quest
              </Typography>
              <Switch
                checked={reqReview}
                onChange={toggleReview}
                name="requires-review"
              />
            </FlexBox>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.secondary}
              textAlign="left"
            >
              You will be required to review the completion of this quest before
              your child can redeem their rewards.
            </Typography>
          </button>

          {reqReview && (
            <FlexBox
              direction="row"
              align="center"
              justify="space-between"
              wrap={false}
              fullWidth
            >
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  togglePhoto()
                }}
                style={{
                  width: "49%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: SPACING.space2,
                  border: `1px solid ${JoonUIColor.border.default}`,
                  background: JoonUIColor.background.primaryNeutral,
                  padding: SPACING.space3,
                }}
              >
                <Typography
                  variant="caption"
                  textAlign="left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Require completion photo
                </Typography>
                <BooleanCheckbox isChecked={reqPhoto} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  toggleNotes()
                }}
                style={{
                  width: "49%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: SPACING.space2,
                  border: `1px solid ${JoonUIColor.border.default}`,
                  background: JoonUIColor.background.primaryNeutral,
                  padding: SPACING.space3,
                }}
              >
                <Typography
                  variant="caption"
                  textAlign="left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Require completion notes
                </Typography>
                <BooleanCheckbox isChecked={reqNotes} />
              </button>
            </FlexBox>
          )}
        </FlexBox>
      }
      reset={reqReview}
      onReset={onReset}
      onClick={() => {
        if (isMobile) onOpenCompletionRequirementModal()
      }}
    />
  )
}
