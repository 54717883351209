import {
  FlexBox,
  SPACING,
  Typography,
  capitalizeFirstLetter,
  QuestInstance,
} from "@joonapp/web-shared"

import QuestCard, { QuestSectionType } from "./QuestCard"
import { QuestGroupCard } from "./QuestGroupCard"
import { sortQuestSection } from "../../util/util"
import { QuestCardStatusOverlay } from "../me/CatchUpCompleteContent"

interface Props {
  title: string
  questInstances: QuestInstance[][]
  icon: string
}

const QuestRoutineSection = ({ title, questInstances, icon }: Props) => {
  const sortedQuests = sortQuestSection(questInstances)

  return (
    <FlexBox className="routine-section" gap={SPACING.space2}>
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        gap={SPACING.space1}
        justify="center"
      >
        <img style={{ height: "16px" }} src={icon} alt="icon" />
        <Typography variant="bodySmall" style={{ fontWeight: "600" }}>
          {capitalizeFirstLetter(title)}
        </Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space1} align="unset">
        {sortedQuests.map((questGroup, i: number) => (
          <div style={{ position: "relative", width: "100%" }} key={i}>
            {/* @ts-ignore */}
            {questGroup.length === 1 && questGroup[0].temp_status && (
              <QuestCardStatusOverlay
                // @ts-ignore
                questStatus={questGroup[0].temp_status}
              />
            )}
            {questGroup.length === 1 ? (
              <QuestCard
                questInstance={questGroup[0]}
                type={QuestSectionType.ROUTINE}
                index={i}
              />
            ) : (
              <QuestGroupCard
                questGroup={questGroup}
                type={QuestSectionType.ROUTINE}
                index={i}
              />
            )}
          </div>
        ))}
      </FlexBox>
    </FlexBox>
  )
}
export default QuestRoutineSection
