import {
  QuestDifficulty,
  QuestRoutine,
  SkillKey,
  QuestInstance,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { create } from "zustand"

import { REPETITIONS } from "../../../constants"

type AddEditModalErrors = {
  questName?: string
  repetition?: string
}

interface AddEditQuestStore {
  resetQuestDetails: () => void

  questName: string
  description: string | null
  photoFile: File | null
  photoUrl: string | null
  questDifficulty: string
  coinRewardsMap: { [childId: number]: number }
  category: SkillKey | undefined | null
  routine: QuestRoutine
  repetition: boolean[]
  repeatsFrequency: number | null
  assignedChildren: number[]
  mandatory: boolean
  startDate: string | undefined | null
  reminderTimeMap: { [routine: string]: string }
  isOngoing: boolean
  minutes: any
  seconds: any
  timerLength: string | undefined | null
  reqReview: boolean
  reqNotes: boolean
  reqPhoto: boolean
  saveTemplate: boolean
  templateId: number | undefined
  customTemplateId: number | undefined
  isGrouped: boolean

  setQuestName: (name: string) => void
  setDescription: (description: string) => void
  setQuestDifficulty: (difficulty: string) => void
  setPhotoFile: (photo: File | null) => void
  setPhotoUrl: (photo: string | null) => void
  setCoinRewardsMap: (childId: number, reward: number) => void
  setCategory: (category: SkillKey | undefined | null) => void
  setRoutine: (routine: QuestRoutine) => void
  setRepetition: (repetition: boolean[]) => void
  setRepeatsFrequency: (frequency: number | null) => void
  setStartDate: (string: any) => void
  setReminderTimeMap: (reminderTimeMap: { [routine: string]: string }) => void
  setIsOngoing: (bool: boolean) => void
  setMinutes: (minutes: any) => void
  setSeconds: (seconds: any) => void
  setTimerLength: (length: any) => void
  toggleChild: (childId: number) => void
  replaceChild: (newChildId: number, targetChildId: number) => void
  setMandatory: (bool?: boolean) => void
  setReqReview: (bool: boolean) => void
  setReqNotes: (bool?: boolean) => void
  setReqPhoto: (bool?: boolean) => void
  setSaveTemplate: (bool?: boolean) => void
  setTemplateId: (id: number) => void
  setCustomTemplateId: (id: number) => void
  setIsGrouped: (bool: boolean) => void
  // Modal view
  isOpen: boolean
  onOpen: (selectedQuest?: QuestInstance) => void
  onClose: () => void

  errors: AddEditModalErrors
  setErrors: (errors: AddEditModalErrors) => void

  selectedQuest: QuestInstance | null
  setSelectedQuest: (quest: QuestInstance) => void
}

// for resetting data when modal is closed
const initQuestDataState = {
  questName: "",
  description: "",
  photoFile: null,
  photoUrl: null,
  questDifficulty: QuestDifficulty.EASY,
  coinRewardsMap: {},
  category: undefined,
  routine: QuestRoutine.ANYTIME,
  repetition: REPETITIONS.noDays,
  repeatsFrequency: null,
  assignedChildren: [],
  mandatory: true,
  minutes: null,
  seconds: null,
  startDate: dayjs().format("YYYY-MM-DD"),
  reminderTimeMap: {},
  isOngoing: true,
  timerLength: undefined,
  reqReview: true,
  reqNotes: false,
  reqPhoto: false,
  saveTemplate: false,
  templateId: undefined,
  customTemplateId: undefined,
  isGrouped: false,
}

export const useAddEditQuestStore = create<AddEditQuestStore>((set, get) => ({
  ...initQuestDataState,

  setQuestName: (name) => set({ questName: name }),
  setDescription: (description) => set({ description }),
  setPhotoFile: (photo) => set({ photoFile: photo }),
  setPhotoUrl: (photo) => set({ photoUrl: photo }),
  setQuestDifficulty: (difficulty) => set({ questDifficulty: difficulty }),
  setCoinRewardsMap: (childId, reward) =>
    set({ coinRewardsMap: { ...get().coinRewardsMap, [childId]: reward } }),
  setCategory: (category) => set({ category }),
  setRoutine: (routine) => set({ routine }),
  setRepetition: (repetition) => set({ repetition }),
  setRepeatsFrequency: (frequency) => set({ repeatsFrequency: frequency }),
  toggleChild: (childId) => {
    const newChildren = [...get().assignedChildren]
    if (newChildren.includes(childId))
      newChildren.splice(newChildren.indexOf(childId), 1)
    else newChildren.push(childId)
    if (newChildren.length === 0) set({})
    else set({ assignedChildren: newChildren })
  },
  replaceChild: (oldChildId, newChildId) => {
    const newChildren = get().assignedChildren.map((childId) =>
      childId === oldChildId ? newChildId : childId
    )
    set({ assignedChildren: newChildren })
  },
  setStartDate: (startDate) => set({ startDate }),
  setReminderTimeMap: (reminderTimeMap) => set({ reminderTimeMap }),
  setIsOngoing: (bool) => set({ isOngoing: bool }),
  setMinutes: (minutes) => set({ minutes }),
  setSeconds: (seconds) => set({ seconds }),
  setTimerLength: (length) => set({ timerLength: length }),
  setMandatory: (bool) => set({ mandatory: bool }),
  setReqReview: (bool) => set({ reqReview: bool }),
  setReqNotes: (bool) => set({ reqNotes: bool }),
  setReqPhoto: (bool) => set({ reqPhoto: bool }),
  setSaveTemplate: (bool) => set({ saveTemplate: bool }),
  setTemplateId: (id) => set({ templateId: id }),
  setCustomTemplateId: (id) => set({ customTemplateId: id }),
  resetQuestDetails: () =>
    Object.entries(initQuestDataState).forEach(([key, val]) =>
      set({ [key]: val })
    ),
  setIsGrouped: (bool) => set({ isGrouped: bool }),
  // Modal view
  isOpen: false,
  onOpen: (selectedQuest) => set({ isOpen: true, errors: {}, selectedQuest }),
  onClose: () => {
    set({ isOpen: false, selectedQuest: null })
    get().resetQuestDetails()
  },

  errors: {},
  setErrors: (errors: AddEditModalErrors) => set({ errors }),

  selectedQuest: null,
  setSelectedQuest: (selectedQuest) => set({ selectedQuest }),
}))
