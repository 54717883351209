import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  ModalContent,
  SPACING,
  TherapistIcon,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { ChildQuestStatusCard } from "./ChildQuestStatusCard"
import { QuestActionsModal } from "./QuestActionsModal"
import { DeleteQuestButton, EditQuestButton } from "./QuestButtonComponents"
import { QuestDetails } from "./QuestDetails"
import QuestLog from "./QuestLog"
import { useViewQuestModalStore } from "./useViewQuestModalStore"
import { CloseButton } from "../../../components/closeButton/CloseButton"
import { useQuestGroupInstances } from "../useQuestGroups"

const ViewQuestModal = () => {
  const { isOpen, onClose, questInstance } = useViewQuestModalStore()
  const { questGroupInstances } = useQuestGroupInstances(questInstance)

  if (!questInstance) return <></>

  const questSeriesData = questInstance.series
  const frequencyTarget = questSeriesData.current_frequency_target

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalContent
        style={{
          width: "min(500px, 100vw)",
          background: JoonUIColor.background.lightAccent,
        }}
      >
        <CloseButton onClick={onClose} style={{ zIndex: 1000 }} />
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            color={JoonColorExpanded.indigo700}
          >
            Quest {questSeriesData.quest_group_id && "Group"} detail
          </Typography>
          {!!frequencyTarget && (
            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              wrap={false}
              gap={SPACING.space2}
              style={{
                border: `1px solid ${JoonUIColor.border.accent}`,
                borderRadius: SPACING.space2,
                padding: SPACING.space3,
                background: JoonUIColor.background.primaryNeutral,
              }}
            >
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography variant="caption" textAlign="left">
                  Frequency target: {frequencyTarget.frequency}x per week
                </Typography>
                <Typography variant="bodySmall" textAlign="left">
                  Goal set by{" "}
                  {frequencyTarget.creator_profile?.nickname || "Therapist"} on{" "}
                  {dayjs(frequencyTarget.date_created).format("MMMM D, YYYY")}
                </Typography>
              </FlexBox>

              <div style={{ minWidth: "32px" }}>
                <TherapistIcon
                  size={32}
                  color={JoonUIColor.background.accent}
                />
              </div>
            </FlexBox>
          )}
          {questSeriesData.photo_url && (
            <img
              src={questSeriesData.photo_url || ""}
              alt="Quest"
              style={{
                width: "100px",
                height: "auto",
                borderRadius: SPACING.space2,
              }}
            />
          )}
          <Typography variant="h3">{questSeriesData.title}</Typography>
          {questInstance.series.description && (
            <Typography variant="body">
              {questInstance.series.description}
            </Typography>
          )}
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Current Status</Typography>
            {questGroupInstances.map((quest) => (
              <ChildQuestStatusCard key={quest.id} questInstance={quest} />
            ))}
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Quest details</Typography>
            <QuestDetails />
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Log</Typography>
            <QuestLog />
          </FlexBox>
        </FlexBox>
      </ModalContent>
      <div
        style={{
          position: "sticky",
          bottom: "0",
          width: "100%",
          background: "white",
          borderTop: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <FlexBox
          direction="row"
          wrap={false}
          align="center"
          justify="center"
          style={{ padding: `${SPACING.space3} ${SPACING.space4}` }}
        >
          <DeleteQuestButton />
          <EditQuestButton />
        </FlexBox>
      </div>
      <QuestActionsModal />
    </Modal>
  )
}

export default ViewQuestModal
