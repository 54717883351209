import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  FlexBox,
  JoonUIColor,
  QuestInstance,
  QuestStatus,
  SPACING,
} from "@joonapp/web-shared"

import { useQuestActionsModalStore } from "./QuestActionsModal"
import ChildBadge from "../../../components/badges/childBadge/ChildBadge"
import StatusBadge from "../../../components/badges/statusBadge/StatusBadge"
import Card from "../../../components/card/Card"
import { useReviewQuestModal } from "../ReviewQuestModal"

export const ChildQuestStatusCard = ({
  questInstance,
}: {
  questInstance: QuestInstance
}) => {
  const { onOpen: onOpenQuestActionsModal } = useQuestActionsModalStore()
  const { onOpen: openReviewQuestModal, setQuest: setQuestToReview } =
    useReviewQuestModal()

  const onClick = () => {
    if (questInstance.status === QuestStatus.COMPLETED) {
      setQuestToReview(questInstance)
      openReviewQuestModal()
    } else {
      onOpenQuestActionsModal(questInstance)
    }
  }

  return (
    <Card
      title={<ChildBadge childId={questInstance.user_id} />}
      onClick={onClick}
      coinAmount={questInstance.redeemable_reward || undefined}
      buttonComponent={
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space2}
          wrap={false}
          style={{ width: "fit-content" }}
        >
          <StatusBadge status={questInstance.status} />
          <FontAwesomeIcon icon={faEllipsis} color={JoonUIColor.icon.neutral} />
        </FlexBox>
      }
      style={{
        height: SPACING.space12,
        padding: SPACING.space3,
      }}
    />
  )
}
