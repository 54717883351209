import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

const ChatBubble = ({
  imageUrl,
  text,
  textWidth,
}: {
  imageUrl: string
  text: string
  textWidth?: string
}) => {
  return (
    <FlexBox
      direction="column"
      align="flex-start"
      wrap={false}
      gap={SPACING.space1}
      style={{ position: "relative", userSelect: "none" }}
    >
      <div
        style={{
          minWidth: SPACING.space9,
          maxWidth: SPACING.space9,
          minHeight: SPACING.space9,
          maxHeight: SPACING.space9,
          borderRadius: "50%",
          background: JoonColorExpanded.yellow400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={imageUrl}
          style={{
            width: SPACING.space9,
            height: SPACING.space9,
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt="Coach Profile"
        />
      </div>
      <FlexBox
        direction="column"
        align="flex-start"
        wrap={false}
        style={{
          padding: SPACING.space2,
          background: JoonColorExpanded.purple600,
          borderRadius: SPACING.space2,
          width: textWidth,
        }}
      >
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.inverted}
          style={{ textAlign: "left" }}
        >
          {text}
        </Typography>
      </FlexBox>

      <div
        style={{
          position: "absolute",
          left: SPACING.space10,
          top: SPACING.space7,
        }}
      >
        <img
          src="/images/guidePhotos/chat_bubble_tail_dark.svg"
          alt="chat bubble tail dark"
        />
      </div>
    </FlexBox>
  )
}

export default ChatBubble
