import { QuestTemplateCategory } from "../../types/quests"

export const displayStringForTemplateCategory = (
  category: QuestTemplateCategory
): string => {
  switch (category) {
    case QuestTemplateCategory.TOP:
      return "Most Used By Parents"
    case QuestTemplateCategory.HOUSEWORK:
      return "Chores & Housework"
    case QuestTemplateCategory.MENTAL_HEALTH:
      return "Mental Health"
    case QuestTemplateCategory.EDUCATION:
      return "Learning & Education"
    case QuestTemplateCategory.FITNESS:
      return "Health & Fitness"
    case QuestTemplateCategory.FOCUS:
      return "Focus & Self Control"
    case QuestTemplateCategory.RELATIONSHIPS:
      return "Relationship Building"
    case QuestTemplateCategory.CUSTOM:
      return "My Templates"
    case QuestTemplateCategory.RECOMMENDED:
      return "Smart Recommendations"
  }
}
