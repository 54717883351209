import {
  FlexBox,
  JoonUIColor,
  SPACING,
  Switch,
  Typography,
} from "@joonapp/web-shared"
import { create } from "zustand"

import { useCompletionRequirement } from "./useCompletionRequirement"
import { BooleanCheckbox } from "../../../../components/booleanCheckbox/BooleanCheckbox"
import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export interface CompletionRequirementModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useCompletionRequirementModalStore =
  create<CompletionRequirementModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const CompletionRequirementModal = () => {
  const { isOpen, onClose } = useCompletionRequirementModalStore()
  const { reqReview, reqPhoto, reqNotes } = useAddEditQuestStore()
  const { toggleReview, togglePhoto, toggleNotes } = useCompletionRequirement()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        fullWidth
        gap={SPACING.space4}
        style={{
          position: "relative",
          background: JoonUIColor.background.xlightGray,
          paddingTop: SPACING.space4,
          paddingBottom: SPACING.space3,
          padding: SPACING.space4,
        }}
      >
        <CloseButton onClick={onClose} />
        <Typography variant="bodyBold" textAlign="left">
          Add completion requirement
        </Typography>
        <FlexBox direction="column" gap={SPACING.space2}>
          <button
            onClick={(e) => e.stopPropagation()}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: SPACING.space2,
              padding: SPACING.space3,
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
              background: JoonUIColor.background.primaryNeutral,
              cursor: "default",
            }}
          >
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              align="center"
              justify="space-between"
            >
              <Typography variant="caption">
                I would like to review this quest
              </Typography>
              <Switch
                checked={reqReview}
                onChange={toggleReview}
                name="requires-review"
              />
            </FlexBox>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.secondary}
              textAlign="left"
            >
              You will be required to review the completion of this quest before
              your child can redeem their rewards.
            </Typography>
          </button>

          {reqReview && (
            <FlexBox
              direction="column"
              align="space-between"
              wrap={false}
              fullWidth
              style={{
                borderRadius: SPACING.space2,
                border: `1px solid ${JoonUIColor.border.default}`,
              }}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  togglePhoto()
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: `1px solid ${JoonUIColor.border.default}`,
                  borderTopLeftRadius: SPACING.space2,
                  borderTopRightRadius: SPACING.space2,
                  background: JoonUIColor.background.primaryNeutral,
                  padding: SPACING.space3,
                }}
              >
                <Typography
                  variant="caption"
                  textAlign="left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Require completion photo
                </Typography>
                <BooleanCheckbox isChecked={reqPhoto} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  toggleNotes()
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottomLeftRadius: SPACING.space2,
                  borderBottomRightRadius: SPACING.space2,
                  background: JoonUIColor.background.primaryNeutral,
                  padding: SPACING.space3,
                }}
              >
                <Typography
                  variant="caption"
                  textAlign="left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Require completion notes
                </Typography>
                <BooleanCheckbox isChecked={reqNotes} />
              </button>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}
