import {
  FlexBox,
  SPACING,
  Typography,
  QuestSeries,
  QuestRoutine,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import QuestDndCard from "./QuestDndCard"
import useQuestBoardDndFilters from "./useQuestBoardDndFilters"
import { useQuestDndListStore } from "./useQuestDndListStore"
import { MultipleSortableLists } from "../../../../components/dnd/MultipleSortableLists"
import { SortableList } from "../../../../components/dnd/SortableList"
import { QuestBoardSkeleton } from "../../../../components/skeleton/Skeleton"
import useMobile from "../../../../hooks/useMobile"
import { sortQuestSeriesSection } from "../../../../util/util"
import useQuestBoardStore from "../../useQuestBoardStore"

const QuestDndList = () => {
  const { filteredQuests, isLoading: isLoadingReorderQuests } =
    useQuestBoardDndFilters()
  const { selectedChildId } = useQuestBoardStore()
  const isMobile = useMobile()
  const { sections, setSections, setUserHasMadeChanges } =
    useQuestDndListStore()

  const { routineSections } = createQuestRoutineSections(filteredQuests)

  useEffect(() => {
    setSections(routineSections)
  }, [selectedChildId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUserHasMadeChanges(!isEqual(sections, routineSections))
  }, [sections]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingReorderQuests) {
    return <QuestBoardSkeleton />
  }

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space4}
      style={{ paddingTop: isMobile ? 0 : SPACING.space4 }}
    >
      <MultipleSortableLists
        sections={sections}
        onChange={setSections}
        renderItem={(questSeries) => <RenderedItem questSeries={questSeries} />}
        renderSection={(section: [string, QuestSeries[]]) => (
          <FlexBox
            className="routine-section"
            gap={SPACING.space2}
            key={section[0]}
          >
            <SectionHeader title={section[0]} />
            <SortableList
              title={section[0]}
              items={section[1]}
              renderItem={(questSeries) => (
                <RenderedItem questSeries={questSeries} />
              )}
            />
          </FlexBox>
        )}
      />
    </FlexBox>
  )
}

const RenderedItem = ({ questSeries }: { questSeries: QuestSeries }) => {
  const isMobile = useMobile()
  return (
    <SortableList.Item id={questSeries.id}>
      <FlexBox
        direction="row"
        wrap={false}
        align="center"
        gap={isMobile ? SPACING.space1 : SPACING.space2}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <QuestDndCard questSeries={questSeries} />
        </div>
      </FlexBox>
    </SortableList.Item>
  )
}

const SectionHeader = ({ title }: { title: string }) => (
  <FlexBox
    direction="row"
    wrap={false}
    align="center"
    gap={SPACING.space1}
    justify="left"
  >
    <img
      style={{ height: "16px" }}
      src={`/images/icons/${title}-routine-icon.png`}
      alt={`${title} icon`}
    />
    <Typography
      variant="bodySmall"
      style={{ fontWeight: "600", textTransform: "capitalize" }}
    >
      {title}
    </Typography>
  </FlexBox>
)

const createQuestRoutineSections = (questList: QuestSeries[]) => {
  const routineKeys = [
    QuestRoutine.MORNING,
    QuestRoutine.AFTERNOON,
    QuestRoutine.NIGHT,
    QuestRoutine.ANYTIME,
  ]

  const routineSections: Record<string, QuestSeries[]> = Object.fromEntries(
    routineKeys.map((key) => [key, []])
  )

  questList?.forEach((quest) => {
    const routineKey = quest.routine
    if (routineKey in routineSections) {
      routineSections[routineKey].push(quest)
    }
  })

  const sortedRoutineSections = sortQuestSections(routineSections).map(
    ([key, quests]) =>
      [key, sortQuestSeriesSection(quests)] as [string, QuestSeries[]]
  )

  const routineSectionsWithOneSeriesPerGroup = sortedRoutineSections.map(
    ([key, quests]) => {
      const seenQuestGroupIds = new Set()
      const filteredQuests = quests.filter((quest) => {
        if (quest.quest_group_id === null) {
          return true
        }
        if (!seenQuestGroupIds.has(quest.quest_group_id)) {
          seenQuestGroupIds.add(quest.quest_group_id)
          return true
        }
        return false
      })
      return [key, filteredQuests] as [string, QuestSeries[]]
    }
  )

  return {
    routineSections: routineSectionsWithOneSeriesPerGroup,
  }
}

const sortQuestSections = (questSections: Record<string, QuestSeries[]>) => {
  const order: Record<string, number> = {
    morning: 0,
    afternoon: 1,
    night: 2,
    anytime: 3,
  }
  return Object.entries(questSections).sort(
    ([keyA], [keyB]) => (order[keyA] ?? Infinity) - (order[keyB] ?? Infinity)
  )
}

const isEqual = (
  a: [string, QuestSeries[]][],
  b: [string, QuestSeries[]][]
) => {
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; i++) {
    const [keyA, questsA] = a[i]
    const [keyB, questsB] = b[i]
    if (keyA !== keyB || questsA.length !== questsB.length) return false
    for (let j = 0; j < questsA.length; j++) {
      if (questsA[j].id !== questsB[j].id) return false
    }
  }
  return true
}

export default QuestDndList
