import {
  Dropdown,
  JoonUIColor,
  SPACING,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import parsePhoneNumber from "libphonenumber-js"
import { useEffect, useState } from "react"

import { countryCodeList, priorityCountries } from "../../constants"

const CountryCode = ({
  countryCode,
  setCountryCode,
}: {
  countryCode: string
  setCountryCode: (value: string) => void
}) => {
  return (
    <Dropdown
      trigger={
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: countryCode.length === 2 ? SPACING.space3 : SPACING.space2,
            width: "40px",
          }}
        >
          <Typography variant="body">{countryCode}</Typography>
          <div
            style={{
              borderLeft: `1px solid ${JoonUIColor.border.default}`,
              height: SPACING.space10,
            }}
          />
        </button>
      }
      items={[
        ...priorityCountries.map((country) => ({
          code: country.code,
          flag: country.flag,
          country: country.country,
        })),
        ...countryCodeList
          .filter(
            (c) => !priorityCountries.some((p) => p.country === c.country)
          )
          .sort((a, b) => a.country.localeCompare(b.country)),
      ].map((country) => ({
        label: `${country.flag} ${country.country} +${country.code}`,
        value: `+${country.code}`,
        onClick: () => setCountryCode(`+${country.code}`),
      }))}
      menuStyle={{ minHeight: "30vh" }}
    />
  )
}

interface PhoneNumberInputProps {
  initialPhoneNumber?: string
  setPhoneNumber: (value: string) => void
  style?: React.CSSProperties
}

export const PhoneNumberInput = ({
  initialPhoneNumber = "",
  setPhoneNumber,
  style,
}: PhoneNumberInputProps) => {
  const [countryCode, setCountryCode] = useState("+1")
  const [phoneNumberInput, setPhoneNumberInput] = useState("")
  const [hasFilledInitialPhoneNumber, setHasFilledInitialPhoneNumber] =
    useState(false)

  const onChangePhoneNumber = (value: string) => {
    const cleanedValue =
      value[0] === "+"
        ? value.replace(/\D/g, "").slice(countryCode.length - 1)
        : value.replace(/\D/g, "")
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,6})$/)
    if (!match) return
    let formattedValue = match[1] ? `${match[1]}` : ""
    if (match[2]) formattedValue += `-${match[2]}`
    if (match[3]) formattedValue += `-${match[3]}`
    setPhoneNumber(`${countryCode}${cleanedValue}`)
    setPhoneNumberInput(formattedValue)
  }

  useEffect(() => {
    if (!hasFilledInitialPhoneNumber && phoneNumberInput.length === 0) {
      // Filling in the initial phone number
      const parsedCountryCode =
        parsePhoneNumber(initialPhoneNumber)?.countryCallingCode ?? "1"
      setCountryCode(`+${parsedCountryCode}`)
      onChangePhoneNumber(initialPhoneNumber)
      setHasFilledInitialPhoneNumber(true)
    } else {
      // if already filled, then make sure the country code is updated when user changes it
      onChangePhoneNumber(`${countryCode}${phoneNumberInput}`)
    }
  }, [initialPhoneNumber, countryCode]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TextInput
      name="Phone Number"
      value={phoneNumberInput}
      onChange={(e) => onChangePhoneNumber(e.target.value)}
      maxLength={16}
      placeholder="123-456-7890"
      fullWidth
      Icon={() => (
        <CountryCode
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
      )}
      style={{
        overflow: "visible",
        height: SPACING.space14,
        background: JoonUIColor.background.xlightGray,
        paddingLeft: SPACING.space5,
        ...style,
      }}
    />
  )
}
