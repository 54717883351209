import {
  ArrowRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ChatBubble from "./planComponents/ChatBubble"
import PlanInfoCard from "./planComponents/PlanInfoCard"
import PlanSecondaryOfferingModal, {
  usePlanSecondaryOfferingModalStore,
} from "./planComponents/PlanSecondaryOfferingModal"
import TimerBanner from "./planComponents/TimerBanner"
import { useRecommendedCoachModalStore } from "./recommendedCoaches/useRecommendedCoachModalStore"
import PageLoader from "../../../components/loading/PageLoader"
import { CoachingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import SmallCoachCard from "../SmallCoachCard"
import { useCoachingStore } from "../useCoachingStore"

const PlanSelect = () => {
  const { selectedCoach, setTimeLeft, setStep, setPlayVideo } =
    useCoachingStore()
  const { onClose } = useRecommendedCoachModalStore()
  const { onOpen: onOpenSecondaryOfferingModal } =
    usePlanSecondaryOfferingModalStore()
  const isMobileMediaQuery = useMobile()
  const router = useRouter()
  const coachName = selectedCoach?.user.name

  const onBackClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_CHOOSE_ANOTHER_COACH, {
      is_tof: router.query.from !== "preview",
    })
    onClose()
    setPlayVideo(false)
    setStep(CoachingStep.RECOMMENDED_COACHES)
  }

  const onNoThanksClick = () => {
    trackAnalyticEvent(
      ANALYTIC_EVENTS.MARKETING_SECONDARY_OFFERING_MODAL_OPEN,
      {
        is_tof: router.query.from !== "preview",
      }
    )
    onOpenSecondaryOfferingModal()
  }

  useEffect(() => {
    setTimeLeft(10 * 60)
  }, [setTimeLeft])

  if (!selectedCoach || !coachName) {
    return <PageLoader />
  }

  return (
    <FlexBox direction="column" align="center" gap={SPACING.space4}>
      <TimerBanner coachName={coachName} />

      <button
        onClick={onBackClick}
        style={{
          width: "100%",
          padding: "0",
          marginRight: "auto",
          marginTop: isMobileMediaQuery ? SPACING.space3 : SPACING.space6,
        }}
      >
        <FlexBox
          direction="row"
          justify="flex-start"
          align="center"
          wrap={false}
        >
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            wrap={false}
            style={{
              marginRight: SPACING.space2,
              transform: "rotate(180deg)",
              width: "fit-content",
            }}
          >
            <ArrowRightIcon size={20} color={JoonUIColor.text.secondary} />
          </FlexBox>
          <Typography
            variant="caption"
            color={JoonUIColor.text.secondary}
            style={{ whiteSpace: "nowrap" }}
          >
            Return to all coaches
          </Typography>
        </FlexBox>
      </button>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space4}
        style={{
          position: "relative",
          marginTop: "52px",
          marginBottom: "0px",
        }}
      >
        <ChatBubble
          text={`Hi! I'm ${coachName} and I can't wait to get started working with you!`}
        />
        <SmallCoachCard
          coachCardContent={selectedCoach}
          backgroundColor={JoonUIColor.background.primaryNeutral}
        />
      </FlexBox>

      <Typography variant="h3" color={JoonUIColor.text.primary}>
        Coaching plan details:
      </Typography>

      <PlanInfoCard />

      <TextButton onClick={onNoThanksClick}>
        <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
          No thanks, I don't want a coach after all
        </Typography>
      </TextButton>
      <PlanSecondaryOfferingModal />
    </FlexBox>
  )
}

export default PlanSelect
