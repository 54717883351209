import {
  ImageAttachIcon,
  JoonUIColor,
  PhotoIcon,
  SPACING,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useQuestPhotoUploadModalStore } from "./QuestPhotoUploadModal"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

const QuestPhotoUploadButton = () => {
  const { photoFile, setPhotoFile, photoUrl, setPhotoUrl } =
    useAddEditQuestStore()
  const {
    isOpen: isOpenQuestPhotoUploadModal,
    onOpen: onOpenPhotoUploadModal,
    onClose: onCloseQuestPhotoUploadModal,
  } = useQuestPhotoUploadModalStore()

  const onPhotoUploadClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (isOpenQuestPhotoUploadModal) {
      onCloseQuestPhotoUploadModal()
      return
    } else if (hasPhoto) {
      onOpenPhotoUploadModal()
      e.preventDefault()
      e.stopPropagation()
      return
    }
  }

  useEffect(() => {
    if (photoFile) {
      setPhotoUrl(URL.createObjectURL(photoFile))
    }
  }, [photoFile, setPhotoUrl])

  const hasPhoto = !!photoFile || !!photoUrl

  return (
    <>
      <input
        onClick={onPhotoUploadClick}
        onChange={(e) => {
          setPhotoFile(e.target.files?.[0] || photoFile)
        }}
        type="file"
        accept="image/*"
        id="file-upload"
        style={{ display: "none" }}
      />
      <label
        htmlFor="file-upload"
        className="icon-only-upload"
        aria-label="Upload Image"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          padding: hasPhoto ? "4px 8px" : "6px 4px 4px 8px",
          cursor: "pointer",
          backgroundColor: hasPhoto
            ? JoonUIColor.background.lightAccent
            : JoonUIColor.background.primaryNeutral,
          border: `1px solid ${hasPhoto ? JoonUIColor.icon.accent : JoonUIColor.icon.light}`,
          borderRadius: SPACING.space2,
          width: "40px",
          height: "32px",
        }}
      >
        {!hasPhoto ? (
          <ImageAttachIcon />
        ) : (
          <PhotoIcon color={JoonUIColor.icon.accent} />
        )}
      </label>
    </>
  )
}

export default QuestPhotoUploadButton
