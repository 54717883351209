import {
  Button,
  DropdownInput,
  FlexBox,
  JoonUIColor,
  QuestRoutine,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import { CloseButton } from "../../../../components/closeButton/CloseButton"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

interface SetTimeModalStoreProps {
  questRoutine: QuestRoutine | null
  isOpen: boolean
  onOpen: (questRoutine: QuestRoutine) => void
  onClose: () => void
}

export const useSetTimeModalStore = create<SetTimeModalStoreProps>()((set) => ({
  questRoutine: null,
  isOpen: false,
  onOpen: (questRoutine: QuestRoutine) => set({ questRoutine, isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

const hourOptions = Array.from({ length: 12 }, (_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
}))

const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
  value: `${i}`.padStart(2, "0"),
  label: `${i}`.padStart(2, "0"),
}))

export default function SetTimeModal() {
  const { reminderTimeMap, setReminderTimeMap, setRoutine } =
    useAddEditQuestStore()
  const { isOpen, onClose, questRoutine } = useSetTimeModalStore()

  const initialHour =
    questRoutine && reminderTimeMap[questRoutine]
      ? reminderTimeMap[questRoutine]?.split(":")[0]
      : "9"
  const initialMinute =
    questRoutine && reminderTimeMap[questRoutine]
      ? reminderTimeMap[questRoutine]?.split(":")[1]?.split(" ")[0]
      : "00"

  const [hour, setHour] = useState<any>({
    value: initialHour,
    label: initialHour,
  })
  const [minute, setMinute] = useState<any>({
    value: initialMinute,
    label: initialMinute,
  })
  const [amPm, setAmPm] = useState<any>(
    Number(initialHour) < 12
      ? { value: "AM", label: "AM" }
      : { value: "PM", label: "PM" }
  )

  const onDone = () => {
    let timeString = `${hour.value}:${minute.value} ${amPm.value}`
    if (questRoutine) {
      setReminderTimeMap({
        [questRoutine]: timeString,
      })
      setRoutine(questRoutine)
    }
    onClose()
  }

  const onModalClose = () => {
    setHour({ value: initialHour, label: initialHour })
    setMinute({ value: initialMinute, label: initialMinute })
    setAmPm({ value: amPm.value, label: amPm.value })
    onClose()
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onModalClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox
        direction="column"
        justify="space-between"
        align="center"
        wrap={false}
        fullWidth
        gap={SPACING.space4}
        style={{
          position: "relative",
          background: JoonUIColor.background.xlightGray,
          paddingTop: SPACING.space4,
          paddingBottom: SPACING.space3,
          padding: SPACING.space4,
        }}
      >
        <FlexBox direction="column" gap={SPACING.space4}>
          <CloseButton onClick={onModalClose} />
          <Typography variant="bodyBold" textAlign="left">
            Set due time
          </Typography>
          <FlexBox
            direction="row"
            justify="center"
            align="center"
            gap={SPACING.space2}
            wrap={false}
            fullWidth
          >
            <DropdownInput
              name="set-time-hour"
              options={hourOptions}
              label={hour?.label}
              onSelect={setHour}
              placeholder="Hour"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              fullWidth
            />
            <DropdownInput
              name="set-time-minute"
              options={minuteOptions}
              label={minute?.label}
              onSelect={setMinute}
              placeholder="Minute"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              fullWidth
            />
            <DropdownInput
              name="set-time-am-pm"
              options={["AM", "PM"].map((option) => ({
                value: option,
                label: option,
              }))}
              label={amPm?.label}
              onSelect={setAmPm}
              placeholder="AM/PM"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              fullWidth
            />
          </FlexBox>
        </FlexBox>

        <Button text="Done" onClick={onDone} fullWidth />
      </FlexBox>
    </SlideUpModal>
  )
}
