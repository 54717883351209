import {
  Button,
  FlexBox,
  JoonUIColor,
  Modal,
  QuestInstance,
  QuestStatus,
  QuestType,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useViewQuestModalStore } from "./useViewQuestModalStore"
import { QUERY_KEYS } from "../../../constants"
import { useSubscription } from "../../../hooks/useSubscription"
import { useFamilyQuery } from "../../../networking/queries"
import {
  completeQuests,
  skipQuests,
  undoVerifyQuest,
} from "../../../networking/quests"
import { FamilyQuerySelect } from "../../../types"
import { useQBDatePickerStore } from "../QuestBoardDatePicker"
import { useQuestGroupInstances } from "../useQuestGroups"

interface QuestActionsModalStoreProps {
  questInstance: QuestInstance | null
  isOpen: boolean
  onOpen: (questInstance: QuestInstance) => void
  onClose: () => void
}

export const useQuestActionsModalStore = create<QuestActionsModalStoreProps>()(
  (set) => ({
    questInstance: null,
    isOpen: false,
    onOpen: (questInstance: QuestInstance) =>
      set({ questInstance, isOpen: true }),
    onClose: () => set({ questInstance: null, isOpen: false }),
  })
)

export const QuestActionsModal = () => {
  const [isSkipLoading, setIsSkipLoading] = useState(false)
  const [isCompleteLoading, setIsCompleteLoading] = useState(false)
  const [isUndoLoading, setIsUndoLoading] = useState(false)
  const { isOpen, onClose, questInstance } = useQuestActionsModalStore()
  const { onClose: onCloseViewQuestModal } = useViewQuestModalStore()
  const { questGroupInstances } = useQuestGroupInstances(questInstance)
  const { checkRemainingFreeQuests } = useSubscription()
  const { questBoardDate } = useQBDatePickerStore()
  const queryClient = useQueryClient()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const questIsRepeating = questInstance?.series.type === QuestType.REPEATING
  const questIsOpen =
    questInstance?.status === QuestStatus.OPEN ||
    questInstance?.status === QuestStatus.RETRY

  const onClickSkipQuest = async () => {
    if (!questInstance) return

    try {
      setIsSkipLoading(true)
      await skipQuests({ questInstanceIds: [questInstance.id] })
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_LOG])
    } catch (error) {
      toast.error("Error skipping Quest")
    } finally {
      setIsSkipLoading(false)
      toast.success("Quest skipped")
      onClose()
      if (questGroupInstances.length === 1) onCloseViewQuestModal()
    }
  }

  const onClickUndoQuest = async () => {
    if (!questInstance) return

    if (questInstance.status === QuestStatus.REDEEMED) {
      return toast.error(
        "You cannot undo this because your child has already redeemed the rewards."
      )
    }

    try {
      setIsUndoLoading(true)
      await undoVerifyQuest(questInstance.id)
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_LOG])
      if (questInstance.status === QuestStatus.VERIFIED) {
        toast.success("Quest verification undone")
      } else if (questInstance.status === QuestStatus.REJECTED) {
        toast.success("Quest rejection undone")
      } else if (questInstance.status === QuestStatus.SKIPPED) {
        toast.success("Quest skip undone")
      } else {
        toast.success("Quest action undone")
      }
    } catch (error) {
      toast.error("Error undoing Quest action")
    } finally {
      setIsUndoLoading(false)
      onClose()
      if (questGroupInstances.length === 1) onCloseViewQuestModal()
    }
  }

  const onClickCompleteQuest = async () => {
    if (!questInstance) return
    const questIsInFuture =
      questBoardDate.format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD")
    if (questIsInFuture)
      throw toast.error("You cannot complete a Quest in the future.")

    checkRemainingFreeQuests()

    try {
      setIsCompleteLoading(true)
      await completeQuests({ questInstanceIds: [questInstance.id] })
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_LOG])
    } catch (error) {
      toast.error("Error completing Quest")
    } finally {
      setIsCompleteLoading(false)
      toast.success("Quest completed")
      onClose()
      if (questGroupInstances.length === 1) onCloseViewQuestModal()
    }
  }

  // Only display undo button if Quest is verified
  const validUndoStatuses = [
    QuestStatus.SKIPPED,
    QuestStatus.REJECTED,
    QuestStatus.VERIFIED,
    QuestStatus.REJECTED_VIEWED,
    QuestStatus.REDEEMED, // Show button but surface message for redeemed Quests
  ]
  const showUndoButton =
    questInstance && validUndoStatuses.includes(questInstance.status)
  const showCompleteButton = questIsOpen && !showUndoButton
  const showSkipButton = questIsOpen && !showUndoButton && questIsRepeating
  const isLoading = isSkipLoading || isCompleteLoading || isUndoLoading

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon
      animate
      style={{ zIndex: 1000, width: "min(80%, 300px)" }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space1}
        style={{ padding: SPACING.space4 }}
        fullWidth
      >
        <Typography
          variant="bodyBold"
          color={JoonUIColor.text.primary}
          style={{ marginTop: SPACING.space4, marginBottom: SPACING.space2 }}
        >
          Modify{" "}
          {
            children?.find((child) => child.user.id === questInstance?.user_id)
              ?.user.name
          }
          's quest status
        </Typography>
        {showUndoButton && (
          <Button
            text="Undo"
            buttonType="primary"
            onClick={onClickUndoQuest}
            fullWidth
            style={{ borderRadius: SPACING.space2 }}
            isLoading={isUndoLoading}
            disabled={isLoading && !isUndoLoading}
          />
        )}
        {showCompleteButton && (
          <Button
            text="Complete"
            buttonType="primary"
            onClick={onClickCompleteQuest}
            fullWidth
            style={{ borderRadius: SPACING.space2 }}
            isLoading={isCompleteLoading}
            disabled={isLoading && !isCompleteLoading}
          />
        )}
        {showSkipButton && (
          <Button
            text="Skip"
            buttonType="secondary"
            onClick={onClickSkipQuest}
            fullWidth
            style={{ borderRadius: SPACING.space2 }}
            isLoading={isSkipLoading}
            disabled={isLoading && !isSkipLoading}
          />
        )}
        <Button
          text="Cancel"
          buttonType="secondary"
          onClick={onClose}
          fullWidth
          style={{
            borderRadius: SPACING.space2,
            marginTop: SPACING.space1,
          }}
          disabled={isLoading}
        />
      </FlexBox>
    </Modal>
  )
}
