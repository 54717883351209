import {
  ArrowRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Loader,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import RewardsChildToggleHeader, {
  useRewardsChildToggleHeaderStore,
} from "./redeem/RewardsChildToggleHeader"
import RewardTransactionCard from "./RewardTransactionCard"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { GRADIENTS } from "../../constants"
import useMobile from "../../hooks/useMobile"
import { useCustomRewardLogsQuery } from "../../networking/rewards/queries"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"

const AllRewardTransactions = () => {
  const { selectedChildId } = useRewardsChildToggleHeaderStore()
  const { data: rewardLogs, isLoading } = useCustomRewardLogsQuery({
    childId: selectedChildId || undefined,
  })
  const router = useRouter()
  const isMobile = useMobile()

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <FlexBox
        direction="row"
        justify="center"
        align="center"
        wrap={false}
        style={{
          width: "min(600px, 100%)",
          position: "relative",
          padding: isMobile
            ? `${SPACING.space4} ${SPACING.space4} 0`
            : `${SPACING.space4} ${SPACING.space4} ${SPACING.space4} 0`,
        }}
      >
        <TextButton
          onClick={() => router.push("/rewards")}
          style={{
            position: "absolute",
            top: SPACING.space2,
            left: isMobile ? SPACING.space3 : SPACING.space0,
          }}
        >
          <div style={{ transform: "rotate(180deg)" }}>
            <ArrowRightIcon
              color={JoonUIColor.icon.neutral}
              size={isMobile ? 16 : 20}
            />
          </div>
        </TextButton>
        <Typography
          variant="h3"
          textAlign="center"
          color={JoonColorExpanded.indigo700}
        >
          All reward transactions
        </Typography>
      </FlexBox>
      <RewardsChildToggleHeader />
      <PageContentWrapper mobileGutter>
        <FlexBox
          direction="column"
          gap={SPACING.space2}
          wrap={false}
          style={isMobile ? {} : { marginTop: SPACING.space6 }}
        >
          {isLoading ? (
            <FlexBox
              justify="center"
              align="center"
              fullWidth
              height={SPACING.space10}
            >
              <Loader
                size={24}
                thickness={2}
                color={JoonUIColor.text.secondary}
              />
            </FlexBox>
          ) : rewardLogs && rewardLogs.length > 0 ? (
            rewardLogs.map((rewardLog) => (
              <RewardTransactionCard
                currentRewardLog={rewardLog}
                allRewardLogs={rewardLogs}
              />
            ))
          ) : (
            <FlexBox justify="center" align="center" fullWidth>
              <Typography
                variant="bodySmall"
                color={JoonUIColor.text.secondary}
                textAlign="left"
              >
                No rewards found.
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
}

const AllRewardTransactionsWithAuth = requireAuth(AllRewardTransactions)
export default AllRewardTransactionsWithAuth
