import { CheckIcon, FlexBox, JoonUIColor, SPACING } from "@joonapp/web-shared"

export const BooleanCheckbox = ({
  isChecked,
  type = "checkbox",
}: {
  isChecked: boolean
  type?: "checkbox" | "radio"
}) => {
  return (
    <FlexBox
      align="center"
      justify="center"
      style={{
        minHeight: "20px",
        minWidth: "20px",
        maxHeight: "20px",
        maxWidth: "20px",
        borderRadius: type === "radio" ? "50%" : SPACING.space2,
        border: isChecked
          ? `2px solid ${JoonUIColor.border.accent}`
          : `2px solid ${JoonUIColor.border.default}`,
        background: isChecked
          ? JoonUIColor.background.accent
          : JoonUIColor.background.primaryNeutral,
      }}
    >
      {type === "checkbox" ? (
        <CheckIcon size={16} color={JoonUIColor.background.primaryNeutral} />
      ) : (
        <div
          style={{
            height: "8px",
            width: "8px",
            borderRadius: "50%",
            background: JoonUIColor.background.primaryNeutral,
          }}
        />
      )}
    </FlexBox>
  )
}
