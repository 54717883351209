import { create } from "zustand"

interface TOSSigningModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void

  agreementAccepted: boolean
  setAgreementAccepted: (value: boolean) => void

  firstName: string
  setFirstName: (value: string) => void
  lastName: string
  setLastName: (value: string) => void
  month: string
  setMonth: (value: string) => void
  day: string
  setDay: (value: string) => void
  year: string
  setYear: (value: string) => void
  streetAddress: string
  setStreetAddress: (value: string) => void
  addressLine2: string
  setAddressLine2: (value: string) => void
  city: string
  setCity: (value: string) => void
  stateProvinceRegion: string
  setStateProvinceRegion: (value: string) => void
  zipPostalCode: string
  setZipPostalCode: (value: string) => void
  country: string
  setCountry: (value: string) => void
  emergencyContactFirstName: string
  setEmergencyContactFirstName: (value: string) => void
  emergencyContactLastName: string
  setEmergencyContactLastName: (value: string) => void
  emergencyContactPhoneNumber: string
  setEmergencyContactPhoneNumber: (value: string) => void

  showFormContent: boolean
  setShowFormContent: (value: boolean) => void
}

export const useTOSSigningModalStore = create<TOSSigningModalStore>()(
  (set) => ({
    isOpen: false,
    onClose: () => set({ isOpen: false }),
    onOpen: () => set({ isOpen: true }),

    agreementAccepted: false,
    setAgreementAccepted: (value: boolean) => set({ agreementAccepted: value }),

    firstName: "",
    setFirstName: (value: string) => set({ firstName: value }),
    lastName: "",
    setLastName: (value: string) => set({ lastName: value }),
    month: "",
    setMonth: (value: string) => set({ month: value }),
    day: "",
    setDay: (value: string) => set({ day: value }),
    year: "",
    setYear: (value: string) => set({ year: value }),
    streetAddress: "",
    setStreetAddress: (value: string) => set({ streetAddress: value }),
    addressLine2: "",
    setAddressLine2: (value: string) => set({ addressLine2: value }),
    city: "",
    setCity: (value: string) => set({ city: value }),
    stateProvinceRegion: "",
    setStateProvinceRegion: (value: string) =>
      set({ stateProvinceRegion: value }),
    zipPostalCode: "",
    setZipPostalCode: (value: string) => set({ zipPostalCode: value }),
    country: "",
    setCountry: (value: string) => set({ country: value }),
    emergencyContactFirstName: "",
    setEmergencyContactFirstName: (value: string) =>
      set({ emergencyContactFirstName: value }),
    emergencyContactLastName: "",
    setEmergencyContactLastName: (value: string) =>
      set({ emergencyContactLastName: value }),
    emergencyContactPhoneNumber: "",
    setEmergencyContactPhoneNumber: (value: string) =>
      set({ emergencyContactPhoneNumber: value }),

    showFormContent: false,
    setShowFormContent: (value: boolean) => set({ showFormContent: value }),
  })
)
