import { useShallow } from "zustand/react/shallow"

import AddPhoneNumberModal from "./addPhoneNumberModal/AddPhoneNumberModal"
import AddToHomescreenModal from "./AddToHomescreenModal"
import FreeTrialFirstVerifyModal from "./FreeTrialFirstVerifyModal"
import FreeTrialLimitModal from "./FreeTrialLimitModal"
import { ImageModal } from "./ImageModal"
import IOSModal, { useIOSModal } from "./IOSModal"
import SubscriptionModal from "./SubscriptionModal"
import DataTaskCompletionModal from "../../pages/academy/dataTasks/DataTaskCompletionModal"
import DataTasksModal from "../../pages/academy/dataTasks/DataTasksModal"
import TrainingModal from "../../pages/academy/training/TrainingModal"
import CoachUpsellModal from "../../pages/care/CoachUpsellModal"
import CoinTransactionsModal from "../../pages/family/coinTransactions/CoinTransactionsModal"
import EditFamilyModal from "../../pages/family/EditFamilyModal"
import GetChildStarted from "../../pages/family/GetChildStartedModal"
import ManageGameplayModal from "../../pages/family/manageGameplay/ManageGameplayModal"
import { EnterTherapistCodeModal } from "../../pages/family/therapists/EnterTherapistCodeModal"
import { InviteTherapistViaEmailModal } from "../../pages/family/therapists/InviteTherapistViaEmailModal"
import {
  ManageTherapistModal,
  useManageTherapistModal,
} from "../../pages/family/therapists/ManageTherapistModal"
import CatchUp from "../../pages/me/CatchUp"
import IncidentReport from "../../pages/me/incidents/IncidentReport"
import ParentTasksModal from "../../pages/me/parentTasks/ParentTasksModal"
import TaskReflectionModal from "../../pages/me/parentTasks/TaskReflectionModal"
import AddEditQuestModal from "../../pages/questBoard/addEditQuestModal/AddEditQuestModal"
import { useAddEditQuestStore } from "../../pages/questBoard/addEditQuestModal/useAddEditQuestStore"
import AddQuestTemplatesModal from "../../pages/questBoard/addQuestTemplates/AddQuestTemplatesModal"
import { useAddQuestTemplatesModalStore } from "../../pages/questBoard/addQuestTemplates/useAddQuestTemplatesModalStore"
import MoreOptionsModal from "../../pages/questBoard/moreOptions/MoreOptionsModal"
import ReorderQuestsModal from "../../pages/questBoard/moreOptions/reorderQuests/ReorderQuestsModal"
import QuestReviewModal from "../../pages/questBoard/questReview/QuestReviewModal"
import RejectQuestModal from "../../pages/questBoard/RejectQuestModal"
import ReviewQuestModal from "../../pages/questBoard/ReviewQuestModal"
import QuestModal from "../../pages/questBoard/viewQuestModal/ViewQuestModal"
import AddEditRewardModal, {
  useAddEditRewardModal,
} from "../../pages/rewards/AddEditRewardModal"
import BonusRewardModal from "../../pages/rewards/BonusRewardModal"
import DeleteRewardModal from "../../pages/rewards/DeleteRewardModal"
import PurchaseRewardModal from "../../pages/rewards/PurchaseRewardModal"
import FilterRewardLogsModal from "../../pages/rewards/redeem/FilterRewardLogsModal"
import RewardQuantityModal from "../../pages/rewards/redeem/RewardQuantityModal"
import AddParentModal, {
  useAddParentModal,
} from "../../pages/settings/AddParentModal"

const ModalProvider = () => {
  const { isOpen: isOpenAddRewards } = useAddEditRewardModal()
  const { isOpen: isOpenAddQuestTemplates } = useAddQuestTemplatesModalStore()
  const isOpenAddEditQuest = useAddEditQuestStore(
    useShallow((state) => state.isOpen)
  )
  const { isOpen: isOpenAddParent } = useAddParentModal()
  const { isOpen: isOpenManageTherapists } = useManageTherapistModal()
  const { isOpen: isOpenIOSModal } = useIOSModal()

  return (
    <>
      <CatchUp />
      <SubscriptionModal />
      <AddPhoneNumberModal />
      <AddToHomescreenModal />
      <QuestModal />
      <ReviewQuestModal />
      <RejectQuestModal />
      <MoreOptionsModal />
      <ReorderQuestsModal />
      {isOpenAddQuestTemplates && <AddQuestTemplatesModal />}
      <FreeTrialLimitModal />
      <FreeTrialFirstVerifyModal />
      <GetChildStarted />
      {isOpenAddRewards && <AddEditRewardModal />}
      {isOpenAddParent && <AddParentModal />}
      {isOpenManageTherapists && <ManageTherapistModal />}
      {isOpenIOSModal && <IOSModal />}
      <InviteTherapistViaEmailModal />
      <EnterTherapistCodeModal />
      <BonusRewardModal />
      <ManageGameplayModal />
      <CoinTransactionsModal />
      {isOpenAddEditQuest && <AddEditQuestModal />}
      <EditFamilyModal />
      <ImageModal />
      <QuestReviewModal />
      <DeleteRewardModal />
      <PurchaseRewardModal />
      <RewardQuantityModal />
      <FilterRewardLogsModal />
      <IncidentReport />
      <TrainingModal />
      <CoachUpsellModal />
      <ParentTasksModal />
      <DataTasksModal />
      <TaskReflectionModal />
      <DataTaskCompletionModal />
    </>
  )
}

export default ModalProvider
