import { DropdownOption } from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

import { useRepeatWeekModalStore } from "./RepeatWeekModal"
import { REPETITIONS } from "../../../../constants"
import { RecurrenceOption } from "../../../../constants"
import { getRepetitionText } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const useRecurrance = () => {
  const { repetition, repeatsFrequency, setRepetition, setRepeatsFrequency } =
    useAddEditQuestStore(
      useShallow((state) => ({
        repetition: state.repetition,
        repeatsFrequency: state.repeatsFrequency,
        setRepetition: state.setRepetition,
        setRepeatsFrequency: state.setRepeatsFrequency,
      }))
    )
  const { onOpen: openRepeatWeekModal } = useRepeatWeekModalStore()

  const selectedRecurrenceOption = useMemo(() => {
    if (repeatsFrequency && repeatsFrequency > 1) return RecurrenceOption.CUSTOM
    const today = REPETITIONS.noDays.slice()
    today[dayjs().day()] = true
    const repetitionIsToday = repetition?.every(
      (val, index) => val === today[index]
    )
    const repetitionIsWeekdays = repetition?.every(
      (val, index) => val === REPETITIONS.weekdays[index]
    )
    const repetitionIsWeekends = repetition?.every(
      (val, index) => val === REPETITIONS.weekends[index]
    )
    if (repetition?.every((day) => day)) return RecurrenceOption.EVERY_DAY
    if (repetitionIsWeekdays) return RecurrenceOption.WEEKDAYS
    if (repetitionIsWeekends) return RecurrenceOption.WEEKENDS
    if (repetitionIsToday) return RecurrenceOption.WEEKLY_TODAY
    return RecurrenceOption.CUSTOM
  }, [repetition, repeatsFrequency])

  const getLabel = (option: DropdownOption) => {
    if (
      option.label === "Custom..." &&
      selectedRecurrenceOption === RecurrenceOption.CUSTOM
    ) {
      return getRepetitionText(repetition, repeatsFrequency)
    }
    return option.label
  }

  const setSelectedReptition = (value: RecurrenceOption) => {
    switch (value) {
      case RecurrenceOption.EVERY_DAY:
        setRepetition(REPETITIONS.daily)
        setRepeatsFrequency(1)
        break
      case RecurrenceOption.WEEKDAYS:
        setRepetition(REPETITIONS.weekdays)
        setRepeatsFrequency(1)
        break
      case RecurrenceOption.WEEKENDS:
        setRepetition(REPETITIONS.weekends)
        setRepeatsFrequency(1)
        break
      case RecurrenceOption.WEEKLY_TODAY:
        const today = REPETITIONS.noDays.slice()
        today[dayjs().day()] = true
        setRepetition(today)
        setRepeatsFrequency(1)
        break
      case RecurrenceOption.CUSTOM:
        openRepeatWeekModal()
        break
    }
  }

  return {
    selectedRecurrenceOption,
    getLabel,
    setSelectedReptition,
  }
}
