import {
  Button,
  FlexBox,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import usePlanUpdate from "./usePlanUpdate"
import usePlanUpdateModalStore from "./usePlanUpdateModalStore"
import usePlanUpdateMutation from "./usePlanUpdateMutation"

const PlanUpdateConfirmationModal = () => {
  const {
    isOpenConfirmationModal,
    onCloseConfirmationModal,
    setHasChangedPlan,
  } = usePlanUpdateModalStore()
  const { selectedPackage, planFinePrint } = usePlanUpdate()
  const upgradeSubscriptionMutation = usePlanUpdateMutation()

  const handleSelectPlan = async () => {
    if (!selectedPackage) return
    await upgradeSubscriptionMutation.mutateAsync(selectedPackage?.lookup_key)
    setHasChangedPlan(true)
    onCloseConfirmationModal()
  }

  return (
    <Modal
      isOpen={isOpenConfirmationModal}
      onClose={onCloseConfirmationModal}
      style={{ width: "min(95vw, 420px)" }}
    >
      <ModalHeader
        title={`Continue with ${selectedPackage?.num_sessions}/month plan?`}
        onClose={onCloseConfirmationModal}
        showBorderBottom
      />
      <FlexBox
        direction="column"
        gap={SPACING.space6}
        style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
      >
        <Typography variant="body">{planFinePrint}</Typography>

        <Button
          onClick={handleSelectPlan}
          text="Confirm"
          fullWidth
          isLoading={upgradeSubscriptionMutation.isLoading}
          style={{ borderRadius: SPACING.space2 }}
        />
      </FlexBox>
    </Modal>
  )
}

export default PlanUpdateConfirmationModal
