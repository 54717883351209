import dayjs from "dayjs"
import { useEffect } from "react"

import PaywallBundling from "./PaywallBundling"
import PaywallNoBundling from "./PaywallNoBundling"
import PaywallReferralModal from "./PaywallReferralModal"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import {
  useCoachIntakeFormQuestionnaireQuery,
  useCoachPostSelectionQuestionnaireQuery,
  useUserQuery,
} from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { requireAuth } from "../../util/auth"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../util/experiments"
import { localStorageItems } from "../../util/storage"

const Paywall = () => {
  useDocumentTitle("Joon - Start Trial")
  const { user } = useUserQuery()
  const variant = useGetExperimentCohort(experimentNames.coachBundlingPaywall)

  // This is called here to preload/cache questionnaires for survey step
  useCoachIntakeFormQuestionnaireQuery()
  useCoachPostSelectionQuestionnaireQuery()

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_PAYWALL)
    localStorage.setItem(
      localStorageItems.lastSeenPaywall,
      dayjs().format("YYYY-MM-DD")
    )
  }, [])

  if (!user) return <></>

  return (
    <>
      {variant === null ? (
        <></>
      ) : variant === cohorts.variantA ? (
        <PaywallBundling />
      ) : (
        <PaywallNoBundling />
      )}
      <PaywallReferralModal />
    </>
  )
}

const PaywallWithAuth = requireAuth(Paywall)
export default PaywallWithAuth
