import { FlexBox, JoonColorExpanded, JoonUIColor } from "@joonapp/web-shared"
import { useEffect, useRef } from "react"

import CoachingFlow from "./coachingFlow/CoachingFlow"
import { useCoachingStore } from "./useCoachingStore"
import { CoachingStep } from "../../constants"
import { requireAuth } from "../../util/auth"

const CoachingPage = () => {
  const { step, questionnaireStep } = useCoachingStore()
  const pageContainerRef = useRef<HTMLDivElement>(null)

  const shouldUseWhiteBackground =
    step === CoachingStep.LANDING ||
    step === CoachingStep.INTAKE_FORM ||
    step === CoachingStep.MATCHING ||
    step === CoachingStep.POST_SELECTION ||
    step === CoachingStep.SCHEDULED

  useEffect(() => {
    pageContainerRef.current?.scrollTo(0, 0)
  }, [step, questionnaireStep])

  return (
    <FlexBox
      ref={pageContainerRef}
      direction="row"
      justify="center"
      wrap={false}
      style={{
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "auto",
        minHeight: "100dvh",
        maxHeight: "100dvh",
        textAlign: "center",
        backgroundColor: shouldUseWhiteBackground
          ? JoonUIColor.background.primaryNeutral
          : step === CoachingStep.RECOMMENDED_COACHES
            ? JoonColorExpanded.indigo100
            : JoonColorExpanded.yellow100,
      }}
    >
      <CoachingFlow />
    </FlexBox>
  )
}

const CoachingPageWithAuth = requireAuth(CoachingPage)
export default CoachingPageWithAuth
