import { useMutation, useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useEffect } from "react"
import { RRule } from "rrule"
import { useShallow } from "zustand/react/shallow"

import { useAddEditQuestStore } from "./useAddEditQuestStore"
import { QUERY_KEYS } from "../../../constants"
import { addQuest, editQuest } from "../../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import {
  convertToByWeekday,
  getRepetitionArrayFromRrule,
  getRepetitionIntervalFromRrule,
} from "../../../util/util"
import useQuestBoardStore from "../useQuestBoardStore"
import { useQuestGroupInstances } from "../useQuestGroups"

export const useAddEditQuestModal = () => {
  const queryClient = useQueryClient()

  const { questBoardRef } = useQuestBoardStore()

  const {
    questName,
    description,
    photoFile,
    coinRewardsMap,
    category,
    routine,
    repetition,
    repeatsFrequency,
    isOngoing,
    startDate,
    reminderTimeMap,
    timerLength,
    assignedChildren,
    mandatory,
    reqReview,
    reqNotes,
    reqPhoto,
    saveTemplate,
    templateId,
    customTemplateId,
    isGrouped,

    setQuestName,
    setDescription,
    setPhotoUrl,
    setQuestDifficulty,
    setCoinRewardsMap,
    setCategory,
    setRoutine,
    setRepetition,
    setRepeatsFrequency,
    setIsOngoing,
    setStartDate,
    setReminderTimeMap,
    setTimerLength,
    setMandatory,
    setReqReview,
    setSaveTemplate,
    setReqNotes,
    setReqPhoto,
    resetQuestDetails,

    onClose,
    setErrors,

    selectedQuest,
  } = useAddEditQuestStore(
    useShallow((state) => ({
      questName: state.questName,
      description: state.description,
      photoFile: state.photoFile,
      coinRewardsMap: state.coinRewardsMap,
      category: state.category,
      routine: state.routine,
      repetition: state.repetition,
      repeatsFrequency: state.repeatsFrequency,
      isOngoing: state.isOngoing,
      startDate: state.startDate,
      reminderTimeMap: state.reminderTimeMap,
      timerLength: state.timerLength,
      assignedChildren: state.assignedChildren,
      mandatory: state.mandatory,
      reqReview: state.reqReview,
      reqNotes: state.reqNotes,
      reqPhoto: state.reqPhoto,
      saveTemplate: state.saveTemplate,
      templateId: state.templateId,
      customTemplateId: state.customTemplateId,
      isGrouped: state.isGrouped,
      setQuestName: state.setQuestName,
      setDescription: state.setDescription,
      setPhotoUrl: state.setPhotoUrl,
      setQuestDifficulty: state.setQuestDifficulty,
      setCoinRewardsMap: state.setCoinRewardsMap,
      setCategory: state.setCategory,
      setRoutine: state.setRoutine,
      setRepetition: state.setRepetition,
      setRepeatsFrequency: state.setRepeatsFrequency,
      setStartDate: state.setStartDate,
      setIsOngoing: state.setIsOngoing,
      setReminderTimeMap: state.setReminderTimeMap,
      setTimerLength: state.setTimerLength,
      setMandatory: state.setMandatory,
      setReqReview: state.setReqReview,
      setSaveTemplate: state.setSaveTemplate,
      setReqNotes: state.setReqNotes,
      setReqPhoto: state.setReqPhoto,
      resetQuestDetails: state.resetQuestDetails,
      onClose: state.onClose,
      setErrors: state.setErrors,
      selectedQuest: state.selectedQuest,
    }))
  )
  const { questGroupInstances } = useQuestGroupInstances(selectedQuest)

  // On initial load, make sure the quest details are set
  // Maybe a better way to do this?
  useEffect(() => {
    if (!selectedQuest) return
    const questData = selectedQuest.series
    const repeatFreq = getRepetitionIntervalFromRrule(questData.recurrence)
    const repetitionArray = getRepetitionArrayFromRrule(questData.recurrence)

    setQuestName(questData.title)
    questData.description && setDescription(questData.description)
    questData.photo_url && setPhotoUrl(questData.photo_url)
    setQuestDifficulty(questData.difficulty)
    setCoinRewardsMap(questData.user_id, questData.redeemable_reward)
    questData.skill && setCategory(questData.skill)
    setRoutine(questData.routine)
    setRepetition(repetitionArray)
    setRepeatsFrequency(repeatFreq)
    if (!repetitionArray.some((day) => day) && questData.end_date === null)
      setIsOngoing(true)
    else setIsOngoing(false)
    questData.start_date && setStartDate(questData.start_date)
    questData.reminder_time &&
      setReminderTimeMap({ [questData.routine]: questData.reminder_time })
    questData.timer_length && setTimerLength(questData.timer_length)
    setMandatory(!!questData.mandatory)
    setReqReview(!!questData.requires_review)
    setReqNotes(!!questData.requires_completion_notes)
    setReqPhoto(!!questData.requires_completion_photo)
    setSaveTemplate(!!questData.template_id)
    //eslint-disable-next-line
  }, [selectedQuest])

  // Ensure that when the quest repeat selection is non repeating,
  // the repetition and repeats frequency are set to null

  const checkForErrors = () => {
    const tempErrors = {} as any
    if (questName.length === 0) {
      tempErrors.questName = "Please enter a Quest Title"
    }
    if (assignedChildren.length === 0) {
      tempErrors.assignedChildren = "Please assign to at least one child"
    }
    setErrors(tempErrors)
    if (Object.keys(tempErrors)?.length > 0)
      throw new Error("Invalid quest details")
  }

  const addQuestAction = () => {
    checkForErrors()

    const isRepeatingQuest = !!repetition.some((day) => day)

    const recurrence = new RRule({
      freq: isRepeatingQuest ? RRule.WEEKLY : RRule.DAILY,
      byweekday: isRepeatingQuest ? convertToByWeekday(repetition) : undefined,
      interval: isRepeatingQuest ? repeatsFrequency ?? 1 : undefined,
    })

    const addQuestPromises = [] as Promise<any>[]

    const sharedData = {
      title: questName,
      requires_review: reqReview,
      requires_completion_notes: reqNotes,
      requires_completion_photo: reqPhoto,
      routine: routine,
      mandatory: mandatory,
      description,
      skill: category,
      timer_length: timerLength,
      reminder_time: reminderTimeMap[routine] || null,
      occurrence_limit: isRepeatingQuest ? null : 1,
      template_id: templateId,
      custom_template_id: customTemplateId,
      recurrence: recurrence.toString(),
      start_date: isOngoing
        ? dayjs().format("YYYY-MM-DD")
        : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
      end_date:
        isOngoing || isRepeatingQuest
          ? null
          : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
    }

    // if grouped quest, only hit endpoint once. Do not send user_id or redeemable_reward
    if (isGrouped && assignedChildren.length > 1) {
      addQuestPromises.push(
        addQuest(
          undefined,
          {
            ...sharedData,
            quest_group_user_rewards: assignedChildren.map((childId) => ({
              user_id: childId,
              redeemable_reward: coinRewardsMap[childId],
            })),
            save_as_template: saveTemplate,
          },
          photoFile ?? undefined
        )
      )
    } else {
      // For each child, create a quest promise
      assignedChildren.forEach((childId, index) => {
        addQuestPromises.push(
          addQuest(
            childId,
            {
              ...sharedData,
              user_id: childId,
              redeemable_reward: coinRewardsMap[childId],
              ...(index === 0 ? { save_as_template: saveTemplate } : {}),
            },
            photoFile ?? undefined
          )
        )
      })
    }

    // Wait for all the API calls to complete before continuing
    return Promise.all(addQuestPromises)
  }

  const addQuestMutation = useMutation({
    mutationFn: addQuestAction,
    onError: (err: any) => {
      const error = err.response.data
      if (error.title) alert("Please enter a Quest Title")
      else
        alert(Object.entries(error).map(([key, value]) => `${key}: ${value}`))
    },
    onSuccess: async () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.ADD_QUEST)
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      if (questBoardRef?.current) {
        questBoardRef.current.scrollTo({ top: 0, behavior: "smooth" })
      }
      onClose()
      resetQuestDetails()
    },
  })

  useEffect(() => {
    if (!reqReview) {
      reqNotes && setReqNotes(false)
      reqPhoto && setReqPhoto(false)
    }
    // eslint-disable-next-line
  }, [reqReview])

  const editQuestAction = () => {
    if (!selectedQuest || questGroupInstances.length < 1)
      throw new Error("No quest selected")
    checkForErrors()

    const isRepeatingQuest = !!repetition?.some((day) => day)

    const recurrence = new RRule({
      freq: isRepeatingQuest ? RRule.WEEKLY : RRule.DAILY,
      byweekday: isRepeatingQuest ? convertToByWeekday(repetition) : undefined,
      interval: isRepeatingQuest ? repeatsFrequency ?? 1 : undefined,
    })

    const didNotUploadPhoto = photoFile === null

    const editQuestPromises = [] as Promise<any>[]

    // always iterate through all children
    questGroupInstances.forEach((instance, index) => {
      editQuestPromises.push(
        editQuest(
          instance.series.id,
          assignedChildren[index],
          {
            title: questName,
            ...(didNotUploadPhoto ? { photo_url: null } : {}),
            requires_review: reqReview,
            requires_completion_notes: reqNotes,
            requires_completion_photo: reqPhoto,
            routine: routine,
            save_as_template: saveTemplate,
            mandatory: mandatory,
            redeemable_reward: coinRewardsMap[assignedChildren[index]],
            description,
            occurrence_limit: isRepeatingQuest ? null : 1,
            skill: category,
            timer_length: timerLength,
            reminder_time: reminderTimeMap[routine] || null,
            recurrence: recurrence.toString(),
            start_date: isOngoing
              ? dayjs().format("YYYY-MM-DD")
              : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
            end_date:
              isOngoing || isRepeatingQuest
                ? null
                : dayjs(startDate || new Date()).format("YYYY-MM-DD"),
            quest_group_id:
              isGrouped && assignedChildren.length > 1
                ? instance.series.quest_group_id
                : null,
          },
          photoFile ?? undefined
        )
      )
    })

    return Promise.all(editQuestPromises)
  }

  const editQuestMutation = useMutation({
    mutationFn: editQuestAction,
    onError: (err: any) => {
      const error = err.response.data
      if (error.title) alert("Please enter a Quest Name")
      else
        alert(Object.entries(error).map(([key, value]) => `${key}: ${value}`))
    },
    onSuccess: async () => {
      trackAnalyticEvent(ANALYTIC_EVENTS.EDIT_QUEST)
      await queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      onClose()
      resetQuestDetails()
    },
  })

  return { addQuestMutation, editQuestMutation }
}
