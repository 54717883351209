import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useLayoutEffect } from "react"

import CareMarketingCard from "./CareMarketingCard"
import CareTeamCard from "./CareTeamCard"
import useCareTeam from "./useCareTeam"
import Card from "../../components/card/Card"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"
import { useInviteTherapistViaEmailModal } from "../family/therapists/InviteTherapistViaEmailModal"

const CarePage = () => {
  useDocumentTitle("Joon - Care")
  const { onOpen: openInviteModal } = useInviteTherapistViaEmailModal()
  const { careTeam, isLoading, hasCoach, hasTherapist, firstCoach } =
    useCareTeam()
  const router = useRouter()

  useLayoutEffect(() => {
    if (careTeam?.length === 0) {
      router.push("/coach")
      return
    }
    if (!hasTherapist && hasCoach) router.push(`/coach/${firstCoach?.user.id}`)
  }, [careTeam, hasTherapist, hasCoach, firstCoach, router])

  if (isLoading) return <></>

  return (
    <PageWrapper
      style={{
        background: JoonColorExpanded.yellow100,
        paddingBottom: "0px",
      }}
    >
      <PageContentWrapper mobileGutter>
        <FlexBox
          direction="column"
          align="center"
          wrap={false}
          style={{
            position: "relative",
            padding: `${SPACING.space9} ${SPACING.space2}`,
            width: "min(600px, 100%)",
          }}
          gap={SPACING.space9}
        >
          <Typography variant="h1" textAlign="left">
            Your care team
          </Typography>
          <FlexBox
            direction="column"
            align="flex-start"
            justify="flex-start"
            gap={SPACING.space2}
            fullWidth
          >
            {careTeam?.map((profile) => (
              <CareTeamCard key={profile.id} profile={profile} />
            ))}

            <Card
              onClick={openInviteModal}
              title={
                <FlexBox
                  direction="row"
                  align="center"
                  justify="flex-start"
                  gap={SPACING.space2}
                  wrap={false}
                >
                  <div
                    style={{
                      minWidth: "32px",
                      minHeight: "32px",
                      borderRadius: "50%",
                      background: JoonUIColor.background.lightGray,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h2" color={JoonUIColor.icon.neutral}>
                      +
                    </Typography>
                  </div>
                  <Typography
                    variant="caption"
                    color={JoonUIColor.text.primary}
                  >
                    Invite another therapist
                  </Typography>
                </FlexBox>
              }
              style={{
                padding: SPACING.space3,
                paddingRight: "0px",
                width: "99%",
                position: "relative",
                border: "none",
              }}
            />
            {!hasCoach && <CareMarketingCard />}
          </FlexBox>
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
}

const CarePageWithAuth = requireAuth(CarePage)
export default CarePageWithAuth
