import { useState } from "react"
import toast from "react-hot-toast"

import { CoachingLookupKey } from "../../../../constants"
import { getCoachingStripeCheckoutUrl } from "../../../../networking/subscriptions"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useRouter } from "../../../../util/router"
import { localStorageItems } from "../../../../util/storage"
import { useCoachingStore } from "../../useCoachingStore"

export const usePlanCard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedCoach, isBundleJoonity } = useCoachingStore()
  const router = useRouter()

  const redirectToCoachCheckout = async (coachingPlan: CoachingLookupKey) => {
    if (!selectedCoach) return
    setIsLoading(true)
    const bundleJoonity =
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
      "appOnly"
        ? false
        : isBundleJoonity
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_INITIATE_CHECKOUT, {
      is_tof: router.query.from !== "preview",
      bundle_joonity: bundleJoonity,
    })
    try {
      const checkoutUrl = await getCoachingStripeCheckoutUrl(
        selectedCoach.id, // must be profile id
        coachingPlan,
        bundleJoonity
      )
      if (!checkoutUrl) {
        toast.error("Subscription already exists")
      } else {
        window.location.assign(checkoutUrl)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { redirectToCoachCheckout, isLoading }
}
