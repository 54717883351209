import {
  FlexBox,
  JoonUIColor,
  Loader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ChildAssignDifficultyCard from "./ChildAssignDifficultyCard"
import { useReassignQuestModalStore } from "./ReassignQuest"
import { useFamilyQuery } from "../../../../networking/queries"
import { FamilyQuerySelect } from "../../../../types"
import { useQuestGroupInstances } from "../../useQuestGroups"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

const ChildAssignDifficultySection = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const {
    isGrouped,
    setIsGrouped,
    selectedQuest,
    assignedChildren,
    toggleChild,
  } = useAddEditQuestStore()
  const { onOpen: onOpenReassignModal } = useReassignQuestModalStore()
  const { questGroupInstances } = useQuestGroupInstances(selectedQuest)

  const isEditing = !!selectedQuest

  // Enable isGrouped if multiple children are assigned; only triggers once during edit
  useEffect(() => {
    if (isEditing) {
      if (!isGrouped && assignedChildren.length > 1) setIsGrouped(true)
      if (
        questGroupInstances &&
        questGroupInstances.length !== assignedChildren.length
      ) {
        // Create toggle queue to ensure assignedChildren is in the right order
        const toggleQueue: number[] = []
        // Toggle on children in questGroupInstances not already assigned
        questGroupInstances.forEach((instance) => {
          if (!assignedChildren.includes(instance.user_id)) {
            toggleQueue.push(instance.user_id)
          }
        })
        // Toggle off children not in questGroupInstances
        assignedChildren.forEach((childId) => {
          if (
            !questGroupInstances.some(
              (instance) => instance.user_id === childId
            )
          ) {
            toggleQueue.push(childId)
          }
        })
        // Toggle first child twice to make sure assignedChildren is in the right order
        toggleQueue.push(assignedChildren[0])
        toggleQueue.push(assignedChildren[0])
        // Execute the toggle actions in ascending id order
        toggleQueue
          .sort((a, b) => a - b)
          .forEach((childId) => toggleChild(childId))
      }
    } else {
      if (assignedChildren.length === 0) {
        toggleChild(children?.[0]?.user.id || 0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedChildren, questGroupInstances, toggleChild, isEditing])

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{
        padding: SPACING.space4,
        borderBottom: `1px solid ${JoonUIColor.border.default}`,
      }}
      fullWidth
    >
      <FlexBox
        direction="row"
        align="center"
        justify="flex-start"
        fullWidth
        wrap={false}
      >
        <Typography variant="bodySmall" style={{ width: "32%" }}>
          {isEditing ? "Assigned to:" : "Assign to:"}
        </Typography>
        <FlexBox
          direction="row"
          align="center"
          justify="space-between"
          style={{ width: "68%" }}
          wrap={false}
        >
          <Typography variant="bodySmall" style={{ whiteSpace: "nowrap" }}>
            Difficulty level
          </Typography>
          <Typography variant="bodySmall" style={{ whiteSpace: "nowrap" }}>
            Reward
          </Typography>
        </FlexBox>
      </FlexBox>

      {isEditing &&
        assignedChildren.map((childId) => (
          <ChildAssignDifficultyCard
            childId={childId}
            childName={
              children?.find((child) => child.user.id === childId)?.user.name ||
              ""
            }
            key={childId}
            onClick={() => onOpenReassignModal(childId)}
            isChecked={assignedChildren.includes(childId)}
          />
        ))}

      {!isEditing && children
        ? children.map((child) => (
            <ChildAssignDifficultyCard
              childId={child.user.id}
              childName={child.user.name}
              key={child.user.id}
              onClick={() => toggleChild(child.user.id)}
              isChecked={assignedChildren.includes(child.user.id)}
            />
          ))
        : !isEditing && (
            <FlexBox
              align="center"
              justify="center"
              style={{ width: "100%", height: "80px" }}
            >
              <Loader color={JoonUIColor.text.primaryAccent} />
            </FlexBox>
          )}
    </FlexBox>
  )
}

export default ChildAssignDifficultySection
