import { FlexBox, JoonUIColor, SPACING, Typography } from "@joonapp/web-shared"
import { create } from "zustand"

import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"

interface MandatoryQuestInfoModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useMandatoryQuestInfoModalStore =
  create<MandatoryQuestInfoModalStoreProps>()((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const MandatoryQuestInfoModal = () => {
  const { isOpen, onClose } = useMandatoryQuestInfoModalStore()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: `${SPACING.space8} ${SPACING.space4} ${SPACING.space14} ${SPACING.space4}`,
        }}
        fullWidth
      >
        <Typography variant="bodyBold" color={JoonUIColor.text.primary}>
          What's a mandatory quest?
        </Typography>
        <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
          Mandatory quests will be included in quest completion stat
          calculations.
        </Typography>
        <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
          If you have the 'Limit by Routine Time' setting turned on, they will
          also require your child to complete this quest before they are able to
          access the game part of Joon.
        </Typography>
        <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
          You can turn on 'Limit by Routine Time' by going to the family tab,
          clicking Manage Game on a child and then clicking Manage Gameplay.
        </Typography>
      </FlexBox>
    </SlideUpModal>
  )
}
