import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons"
import {
  DropdownInput,
  FlexBox,
  JoonUIColor,
  QuestRoutine,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect } from "react"

import { useRecurrenceModalStore } from "./RecurrenceModal"
import { useRecurrance } from "./useRecurrance"
import { ExpandCard } from "../../../../components/card/ExpandCard"
import {
  RECURRENCE_OPTIONS,
  RecurrenceOption,
  REPETITIONS,
} from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { getRepetitionText } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const RecurrenceSection = () => {
  const {
    routine,
    startDate,
    repetition,
    repeatsFrequency,
    setRepetition,
    setRepeatsFrequency,
    setIsOngoing,
    selectedQuest,
  } = useAddEditQuestStore()
  const { onOpen: openRecurrenceModal } = useRecurrenceModalStore()
  const { selectedRecurrenceOption, getLabel, setSelectedReptition } =
    useRecurrance()

  const isMobile = useMobile()

  const isEditing = !!selectedQuest

  const recurrenceOptions = RECURRENCE_OPTIONS.map((option) => ({
    label: getLabel(option),
    value: option.value,
  }))

  const hasRepetition = repetition.some((day) => day)

  useEffect(() => {
    if (isEditing && !hasRepetition) {
      setRepeatsFrequency(null)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isEditing && !hasRepetition) return null

  return (
    <ExpandCard
      icon={faArrowRotateLeft}
      text={
        <Typography
          variant="caption"
          textAlign="left"
          color={
            hasRepetition
              ? JoonUIColor.text.primary
              : JoonUIColor.text.secondary
          }
        >
          {hasRepetition
            ? getRepetitionText(repetition, repeatsFrequency)
            : "Make recurring"}
        </Typography>
      }
      additionalContent={
        <FlexBox direction="column" gap={SPACING.space2}>
          <button
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            style={{ width: "100%" }}
          >
            <DropdownInput
              options={recurrenceOptions}
              label={
                recurrenceOptions.find(
                  (option) => option.value === selectedRecurrenceOption
                )?.label
              }
              onSelect={(option) =>
                setSelectedReptition(option?.value as RecurrenceOption)
              }
              style={{ background: JoonUIColor.background.primaryNeutral }}
              menuStyle={{ minWidth: "300px", minHeight: "226px" }}
              fullWidth
            />
          </button>
        </FlexBox>
      }
      reset={!isEditing && hasRepetition}
      onReset={() => {
        setRepetition(REPETITIONS.noDays)
        setRepeatsFrequency(null)
        if (
          dayjs(startDate).isSame(dayjs(), "day") &&
          routine === QuestRoutine.ANYTIME
        ) {
          setIsOngoing(true)
        }
      }}
      onClick={() => {
        if (isMobile) return openRecurrenceModal()
        if (!hasRepetition) {
          setRepetition(REPETITIONS.daily)
          setRepeatsFrequency(1)
          setIsOngoing(false)
        }
      }}
    />
  )
}
