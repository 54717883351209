import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons"
import {
  DropdownInput,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import { useTimerModalStore } from "./TimerModal"
import { ExpandCard } from "../../../../components/card/ExpandCard"
import { getDoubleDigitNum } from "../../../../components/timerInput/TimerInput"
import { timerMinuteOptions, timerSecondOptions } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const TimerSection = () => {
  const {
    timerLength,
    minutes,
    seconds,
    setMinutes,
    setSeconds,
    setTimerLength,
    selectedQuest,
  } = useAddEditQuestStore()
  const { onOpen: onOpenTimerModal, setShowTimer } = useTimerModalStore()

  const isMobile = useMobile()

  useEffect(() => {
    if (seconds?.value === "0") {
      setSeconds(null)
    }
    if (minutes?.value === "0") {
      setMinutes(null)
    }
    if (minutes?.value > 0 || seconds?.value > 0) {
      let timeString = `00:${getDoubleDigitNum(minutes?.value)}:${getDoubleDigitNum(
        seconds?.value
      )}`
      setTimerLength(timeString)
    }
  }, [minutes, seconds, setTimerLength, setMinutes, setSeconds])

  useEffect(() => {
    // for editing quest
    if (!!selectedQuest?.series.timer_length) {
      setTimerLength(selectedQuest.series.timer_length)
      const minuteValue = parseInt(
        selectedQuest.series.timer_length.split(":")[1],
        10
      )
      const secondValue = parseInt(
        selectedQuest.series.timer_length.split(":")[2],
        10
      )
      setMinutes(
        minuteValue === 0
          ? null
          : {
              value: minuteValue.toString(),
              label: `${minuteValue} min`,
            }
      )
      setSeconds(
        secondValue === 0
          ? null
          : {
              value: secondValue.toString(),
              label: `${secondValue} sec`,
            }
      )
    }
    // for quest templates
    if (!timerLength) return
    const minutes = parseInt(timerLength.split(":")[1], 10)
    const seconds = parseInt(timerLength.split(":")[2], 10)
    setMinutes(
      minutes === 0
        ? null
        : { value: minutes.toString(), label: `${minutes} min` }
    )
    setSeconds(
      seconds === 0
        ? null
        : { value: seconds.toString(), label: `${seconds} sec` }
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExpandCard
      icon={faClockRotateLeft}
      text={
        <Typography
          variant="caption"
          color={
            minutes?.value > 0 || seconds?.value > 0
              ? JoonUIColor.text.primary
              : JoonUIColor.text.secondary
          }
        >
          {minutes?.value > 0 || seconds?.value > 0
            ? `Timer for ${minutes?.value ?? "0"} minute${
                minutes?.value !== "1" ? "s" : ""
              } and ${seconds?.value ?? "0"} second${seconds?.value !== "1" ? "s" : ""}`
            : "Add timer"}
        </Typography>
      }
      additionalContent={
        <FlexBox
          direction="row"
          justify="center"
          align="center"
          gap={SPACING.space2}
          wrap={false}
        >
          <button
            onClick={(e) => e.stopPropagation()}
            style={{ width: "100%" }}
          >
            <DropdownInput
              name="minute"
              options={timerMinuteOptions}
              label={minutes?.label}
              onSelect={setMinutes}
              placeholder="Minutes"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              menuStyle={{ minWidth: "200px" }}
              fullWidth
            />
          </button>

          <button
            onClick={(e) => e.stopPropagation()}
            style={{ width: "100%" }}
          >
            <DropdownInput
              name="second"
              options={timerSecondOptions}
              label={seconds?.label}
              onSelect={setSeconds}
              placeholder="Seconds"
              style={{ background: JoonUIColor.background.primaryNeutral }}
              menuStyle={{ minWidth: "200px" }}
              fullWidth
            />
          </button>
        </FlexBox>
      }
      reset={minutes !== null || seconds !== null}
      onReset={() => {
        setMinutes(null)
        setSeconds(null)
        setShowTimer(false)
      }}
      onClick={() => {
        if (isMobile) return onOpenTimerModal()
      }}
    />
  )
}
