import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  capitalizeFirstLetter,
  DropdownInput,
  FlexBox,
  InfoSolidIcon,
  JoonUIColor,
  SPACING,
  Switch,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import { useAddSkillModalStore } from "./AddSkillModal"
import { useMandatoryQuestInfoModalStore } from "./MandatoryQuestInfoModal"
import QuestPhotoUploadButton from "./QuestPhotoUploadButton"
import { useSkills } from "./useSkills"
import useMobile from "../../../../hooks/useMobile"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const HeaderBottomButtons = () => {
  const { category, mandatory, selectedQuest, setCategory, setMandatory } =
    useAddEditQuestStore()
  const { onOpen: onOpenAddSkillModal } = useAddSkillModalStore()
  const { onOpen: onOpenMandatoryQuestInfo } = useMandatoryQuestInfoModalStore()
  const { skillOptions, selectedSkill } = useSkills()
  const isMobile = useMobile()

  const isEditing = !!selectedQuest
  const hasFrequencyTarget = !!selectedQuest?.series.current_frequency_target

  return (
    <FlexBox
      direction="row"
      align="center"
      justify="flex-start"
      gap={SPACING.space2}
    >
      <QuestPhotoUploadButton />

      {isMobile ? (
        <button
          onClick={onOpenAddSkillModal}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: SPACING.space1,
            backgroundColor: category
              ? JoonUIColor.background.lightAccent
              : JoonUIColor.background.primaryNeutral,
            outline: `1px solid ${
              category ? JoonUIColor.icon.accent : JoonUIColor.icon.light
            }`,
            borderWidth: "0px",
            paddingLeft: SPACING.space2,
            paddingRight: SPACING.space2,
            width: "fit-content",
            borderRadius: SPACING.space2,
            height: "32px",
          }}
        >
          <Typography variant="bodySmall">
            {category ? capitalizeFirstLetter(category) : "Add skill "}
          </Typography>
          {!category && (
            <FontAwesomeIcon
              icon={faChevronDown}
              color={JoonUIColor.icon.neutral}
              style={{
                fontSize: "0.875rem",
                fontWeight: 500,
              }}
            />
          )}
        </button>
      ) : (
        <DropdownInput
          size="small"
          options={skillOptions}
          label={selectedSkill?.label}
          onSelect={(skill) => setCategory(skill.value as any)}
          style={{
            backgroundColor: category
              ? JoonUIColor.background.lightAccent
              : JoonUIColor.background.primaryNeutral,
            outline: `1px solid ${
              category ? JoonUIColor.icon.accent : JoonUIColor.icon.light
            }`,
            borderWidth: "0px",
            paddingLeft: SPACING.space2,
            paddingRight: SPACING.space05,
            width: "110px",
            borderRadius: SPACING.space2,
            height: "32px",
          }}
        />
      )}

      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space2}
        style={{
          width: "fit-content",
          ...(isEditing && hasFrequencyTarget
            ? {
                opacity: 0.6,
                pointerEvents: "none",
                fontStyle: "italic",
              }
            : { opacity: 1, pointerEvents: "auto" }),
        }}
        wrap={false}
      >
        <Switch
          checked={mandatory}
          name="requiresPhoto"
          onChange={() => setMandatory(!mandatory)}
          style={{
            minWidth: "51px",
            maxWidth: "51px",
            transform: "scale(0.9)",
          }}
        />
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space05}
          wrap={false}
          style={{ width: "fit-content" }}
        >
          <Typography variant="bodySmall" textAlign="center">
            {mandatory ? "Mandatory quest" : "Not mandatory"}
          </Typography>
          {isEditing && hasFrequencyTarget ? (
            <Typography variant="bodySmall">*</Typography>
          ) : (
            <TextButton
              onClick={onOpenMandatoryQuestInfo}
              style={{
                padding: SPACING.space05,
                borderRadius: "50%",
              }}
            >
              <InfoSolidIcon size={12} color={JoonUIColor.icon.neutral} />
            </TextButton>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
