import { FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { create } from "zustand"

import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"

interface QuestGroupInfoModalStoreProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useQuestGroupInfoModalStore =
  create<QuestGroupInfoModalStoreProps>()((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

export const QuestGroupInfoModal = () => {
  const { isOpen, onClose } = useQuestGroupInfoModalStore()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
      zIndex={1000}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: `${SPACING.space8} ${SPACING.space4} ${SPACING.space14} ${SPACING.space4}`,
        }}
        fullWidth
      >
        <Typography variant="bodyBold">What is a Quest Group?</Typography>
        <Typography variant="bodySmall">
          <Typography variant="caption" style={{ lineHeight: "1.25rem" }}>
            Quest groups
          </Typography>{" "}
          let you create identical quests to assign to multiple children at
          once, ideal when expectations are the same for all. For example, daily
          or same-day tasks work well as grouped quests.
        </Typography>
        <FlexBox direction="column">
          <Typography
            variant="bodySmall"
            style={{ textDecoration: "underline", whiteSpace: "pre-line" }}
          >
            {`Sample grouped quests include:\n`}
          </Typography>
          <ul style={{ listStyleType: "disc", marginTop: 0 }}>
            <li>
              <Typography variant="bodySmall">
                Make bed every day for Amy and Jo
              </Typography>
            </li>
            <li>
              <Typography variant="bodySmall">
                Brush teeth every morning for Bob and Michael
              </Typography>
            </li>
          </ul>
        </FlexBox>
        <Typography variant="bodySmall">
          <Typography variant="caption" style={{ lineHeight: "1.25rem" }}>
            When quests aren't grouped,{" "}
          </Typography>
          you can customize details like due dates for each child, which is
          useful for tasks completed on different days or at different times.
        </Typography>
        <FlexBox direction="column">
          <Typography
            variant="bodySmall"
            style={{ textDecoration: "underline" }}
          >
            Sample ungrouped quests include:
          </Typography>
          <ul style={{ listStyleType: "disc", marginTop: 0 }}>
            <li>
              <FlexBox direction="column">
                <Typography variant="caption" style={{ lineHeight: "1.25rem" }}>
                  Practice music 3x a week for Amy and Jo
                </Typography>
                <Typography variant="bodySmall">
                  When left{" "}
                  <Typography variant="caption">ungrouped,</Typography> you can
                  then personalize these quests further without losing your
                  initial setup:
                </Typography>
                <ul style={{ listStyleType: "disc", marginTop: 0 }}>
                  <li>
                    <Typography variant="bodySmall">
                      Update Amy's to: Practice Piano at 4pm
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="bodySmall">
                      Update Jo's to: Practice Flute at 8pm
                    </Typography>
                  </li>
                </ul>
              </FlexBox>
            </li>
          </ul>
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}
