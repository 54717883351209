import { useEffect } from "react"

import {
  useFamilyQuery,
  useReorderQuestsQuery,
} from "../../../../networking/queries"
import { FamilyQuerySelect } from "../../../../types"
import useQuestBoardStore from "../../useQuestBoardStore"

const useQuestBoardDndFilters = () => {
  const { data, isLoading } = useReorderQuestsQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { selectedChildId, setSelectedChildId, selectedRoutine } =
    useQuestBoardStore()

  const filteredQuests =
    data?.filter((quest) => {
      // Remove self-initiated quests
      if (quest.assigner_profile === null) {
        return false
      }
      // Filter quests by child
      if (selectedChildId && quest.user_id !== selectedChildId) {
        return false
      }
      // Filter quests by routine
      if (selectedRoutine && quest.routine !== selectedRoutine) {
        return false
      }
      return true
    }) || []

  useEffect(() => {
    if (children?.length === 1 && !selectedChildId) {
      setSelectedChildId(children[0].user.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  return { filteredQuests, isLoading }
}

export default useQuestBoardDndFilters
