import {
  faReceipt,
  faCreditCard,
  faDragon,
  faMessage,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  CloseCircleIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { isChrome, isMobile, isAndroid } from "react-device-detect"
import { create } from "zustand"

import ChatBubbleDownRight from "./ChatBubbleDownRight"
import { PlanTitle } from "./PlanTitle"
import { usePlanCard } from "./usePlanCard"
import { useAddToHomescreenModal } from "../../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../../components/modals/IOSModal"
import SlideUpModal from "../../../../components/slideUpModal/SlideUpModal"
import {
  CoachingStep,
  CoachingLookupKey,
  COACH_PLANS,
} from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../../networking/queries"
import { SubscriptionPackage } from "../../../../types/user"
import { ANALYTIC_EVENTS } from "../../../../util/analytics"
import { trackAnalyticEvent } from "../../../../util/analytics"
import { useRouter } from "../../../../util/router"
import { localStorageItems } from "../../../../util/storage"
import { formattedCentsToDollars } from "../../../../util/util"
import { useCoachingStore } from "../../useCoachingStore"

interface PlanSecondaryOfferingModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const usePlanSecondaryOfferingModalStore =
  create<PlanSecondaryOfferingModalStore>((set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  }))

const PlanSecondaryOfferingModal = () => {
  const { selectedCoach } = useCoachingStore()
  const { isOpen, onClose } = usePlanSecondaryOfferingModalStore()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const isOnMobileChrome = isChrome && isMobile && isAndroid
  const isMobileMediaQuery = useMobile()
  const router = useRouter()

  const onNoThankYouClick = () => {
    onClose()
    if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
      "appOnly"
    ) {
      localStorage.removeItem(localStorageItems.planChoiceFromPaywall)
      router.push("/me")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.PLAN_SELECT,
        is_tof: router.query.from !== "preview",
      })
      if (router.query.from === "preview") return
      if (isOnMobileChrome) openAddToHomescreenModal()
      else openIOSModal()
    } else {
      router.push("/paywall/trial")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.PLAN_SELECT,
        is_tof: router.query.from !== "preview",
      })
    }
  }

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="fit-content"
      backgroundType="overlay"
    >
      <TextButton
        onClick={onClose}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: SPACING.space2,
          borderRadius: "50%",
        }}
      >
        <CloseCircleIcon size={16} color={JoonUIColor.icon.neutral} />
      </TextButton>

      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space6,
          paddingTop: isMobileMediaQuery ? "120px" : "140px",
        }}
      >
        <ChatBubbleDownRight
          text="Not ready for a larger commitment?"
          boldText=" Let's start with our Starter package instead!"
          imageUrl={selectedCoach?.profile_image_url || ""}
        />
        <PlanSecondaryOfferingModalCard />
        <TextButton onClick={onNoThankYouClick}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            No, thank you
          </Typography>
        </TextButton>
      </FlexBox>
    </SlideUpModal>
  )
}

const PlanSecondaryOfferingModalCard = () => {
  const { selectedCoach, isBundleJoonity } = useCoachingStore()
  const { onClose } = usePlanSecondaryOfferingModalStore()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const { redirectToCoachCheckout, isLoading } = usePlanCard()
  const starterPlan: SubscriptionPackage | undefined = Object.values(
    subscriptionPackages || {}
  ).find((plan) => plan.starter_plan)
  const starterLookupKey =
    starterPlan?.lookup_key || CoachingLookupKey.TEXT_ONLY
  const { plan } = COACH_PLANS[starterLookupKey]
  const isMobileMediaQuery = useMobile()
  const firstMonthPriceInCents = starterPlan?.first_month_unit_amount
  const priceInCents = starterPlan?.unit_amount ?? 0
  const numSessions = starterPlan?.num_sessions ?? 0

  const priceForToday = formattedCentsToDollars(firstMonthPriceInCents!)
  const fullPrice = formattedCentsToDollars(priceInCents)

  const bundleJoonity =
    localStorage.getItem(localStorageItems.planChoiceFromPaywall) === "appOnly"
      ? false
      : isBundleJoonity

  const infoCards = [
    ...(bundleJoonity
      ? [
          {
            icon: faDragon,
            text: "Full access to Joon Apps included!",
          },
        ]
      : []),
    {
      icon: faMessage,
      text: "Anytime messages with your coach",
    },
    ...(numSessions > 0
      ? [
          {
            icon: faVideo,
            text: `${numSessions} x 45 minute sessions with your coach/month`,
          },
        ]
      : []),
    {
      icon: faCreditCard,
      text: "HSA/FSA eligible",
    },
    {
      icon: faReceipt,
      text: "Try it Risk-Free!\nLove it or get a full refund within 30 days",
    },
  ]

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space0}
      style={{
        width: "min(100%, 450px)",
        paddingBottom: SPACING.space2,
        opacity: subscriptionPackages ? 1 : 0,
        background: "#F9FAFC",
        border: `2px solid ${JoonUIColor.text.primaryAccent}`,
        borderRadius: "16px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
        transition: "opacity .3s ease-in-out",
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        wrap={false}
        style={{ padding: SPACING.space4 }}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          gap={SPACING.space1}
          wrap={false}
          fullWidth
        >
          <PlanTitle plan={plan} coachingPlan={starterLookupKey} />
          {firstMonthPriceInCents ? (
            <FlexBox
              direction="row"
              justify="flex-end"
              align="center"
              gap={SPACING.space1}
              wrap={false}
            >
              <Typography
                variant={isMobileMediaQuery ? "h3" : "h2"}
                color={JoonUIColor.background.disabled}
                style={{ textDecoration: "line-through" }}
              >
                ${fullPrice}
              </Typography>
              <Typography
                variant={isMobileMediaQuery ? "h3" : "h2"}
                color={JoonUIColor.text.primary}
              >
                ${priceForToday}
              </Typography>
            </FlexBox>
          ) : (
            <FlexBox
              direction="row"
              justify="flex-end"
              align="center"
              gap={SPACING.space1}
              wrap={false}
            >
              <Typography
                variant={isMobileMediaQuery ? "h3" : "h2"}
                color={JoonUIColor.text.primary}
              >
                ${fullPrice}
                <Typography variant="body" color={JoonUIColor.text.primary}>
                  /month
                </Typography>
              </Typography>
            </FlexBox>
          )}
        </FlexBox>

        {firstMonthPriceInCents && (
          <>
            <FlexBox
              style={{
                padding: `${SPACING.space1} ${SPACING.space2}`,
                background: JoonColorExpanded.yellow300,
                borderRadius: SPACING.space1,
              }}
              fullWidth
            >
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.primary}
                textAlign="center"
                style={{
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                First month: 60% off Early Bird Special!
              </Typography>
            </FlexBox>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.secondary}
              style={{ fontWeight: 600 }}
            >
              {`afterwards $${fullPrice}/month`}
            </Typography>
          </>
        )}
      </FlexBox>

      <FlexBox
        direction="column"
        align="flex-start"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space2}`,
          background: JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.icon.light}`,
          borderBottom: `1px solid ${JoonUIColor.icon.light}`,
        }}
      >
        {infoCards.map(({ icon, text }, index) => (
          <FlexBox
            key={index}
            direction="row"
            justify="center"
            align="center"
            gap={SPACING.space2}
            wrap={false}
            style={{ width: "fit-content" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "16px",
                maxWidth: "16px",
                minHeight: "16px",
                maxHeight: "16px",
              }}
            >
              <FontAwesomeIcon
                icon={icon}
                color={
                  index < (bundleJoonity ? 2 : 1)
                    ? JoonUIColor.icon.accent
                    : JoonUIColor.icon.neutral
                }
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.primary}
              style={{
                fontWeight: 600,
                textAlign: "left",
                lineHeight: "1.3",
                whiteSpace: "pre-line",
              }}
            >
              {text}
            </Typography>
          </FlexBox>
        ))}
      </FlexBox>

      <div
        style={{
          width: "100%",
          padding: SPACING.space2,
          paddingTop: SPACING.space4,
        }}
      >
        <Button
          fullWidth
          isLoading={isLoading}
          onClick={() =>
            redirectToCoachCheckout(starterLookupKey).then(onClose)
          }
          style={{
            position: "relative",
            cursor: "pointer",
          }}
          text={
            <Typography
              variant={isMobileMediaQuery ? "caption" : "bodyBold"}
              color={JoonUIColor.text.inverted}
            >
              {`Get started with ${selectedCoach?.user.name || "your coach"}`}
            </Typography>
          }
        />
      </div>
    </FlexBox>
  )
}

export default PlanSecondaryOfferingModal
