import {
  faHandHoldingHeart,
  faMessage,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  FlexBox,
  InfoIcon,
  JoonColorExpanded,
  JoonUIColor,
  Shadow,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"

import usePlanUpdate from "./usePlanUpdate"
import usePlanUpdateModalStore from "./usePlanUpdateModalStore"
import { useRouter } from "../../../util/router"
import useCareTeam from "../../care/useCareTeam"
import { CoachTab } from "../CoachTabNav"

const PlanUpdateSuccess = () => {
  const { numSessions, setHasChangedPlan, previousPackage } =
    usePlanUpdateModalStore()
  const { onClose } = usePlanUpdateModalStore()
  const { currentBillingCycleEndDate } = usePlanUpdate()
  const { firstCoach } = useCareTeam()
  const router = useRouter()
  const infoCards = [
    {
      icon: faHandHoldingHeart,
      text: "A personally matched Coach to help you set up your Joon app properly for your family",
    },
    {
      icon: faMessage,
      text: "Anytime direct messaging with your coach",
    },
    ...(numSessions > 0
      ? [
          {
            icon: faVideo,
            text: `${numSessions}x 45-minute video calls each month`,
          },
        ]
      : []),
  ]

  const hasUpgraded = (previousPackage?.num_sessions || Infinity) < numSessions

  const handleComplete = () => {
    if (!firstCoach) return
    if (numSessions === 0) {
      router.push(`/coach/${firstCoach?.user.id}/${CoachTab.MESSAGES}`)
    } else {
      router.push(`/coach/${firstCoach?.user.id}/${CoachTab.SESSIONS}`)
    }
    onClose()
    setHasChangedPlan(false)
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      wrap={false}
      gap={SPACING.space8}
      style={{ minHeight: "300px", padding: SPACING.space6 }}
    >
      <img src={"/images/logos/joon-health-logo.svg"} alt="joonhealth" />
      <FlexBox
        align="center"
        justify="center"
        style={{
          width: "fit-content",
          position: "relative",
          margin: `${SPACING.space4} 0`,
          height: SPACING.space10,
        }}
      >
        <FontAwesomeIcon
          icon={faHandHoldingHeart}
          color={JoonUIColor.semantic.success}
          fontSize={40}
          style={{ zIndex: 2 }}
        />
        <div
          style={{
            width: "63px",
            height: "63px",
            background: JoonColorExpanded.viridian100,
            borderRadius: SPACING.space4,
            position: "absolute",
            transform: "rotate(15deg)",
          }}
        ></div>
      </FlexBox>
      <Typography variant="h2" style={{ fontWeight: 600 }} textAlign="center">
        Coaching membership updated!
      </Typography>
      <Typography variant="bodySmall" style={{ fontWeight: 600 }}>
        Membership summary:
      </Typography>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{
          padding: SPACING.space4,
          border: `2px solid ${JoonUIColor.border.accent}`,
          borderRadius: SPACING.space2,
          boxShadow: Shadow.high,
        }}
      >
        <FlexBox direction="row" gap={SPACING.space1} align="center">
          <Typography variant="h3">Joon</Typography>
          <Typography
            variant="h2"
            style={{ fontFamily: "Caveat" }}
            color={JoonUIColor.text.primaryAccent}
          >
            with
          </Typography>
          <Typography variant="h3">Coach</Typography>
        </FlexBox>
        <FlexBox direction="column">
          {infoCards.map(({ icon, text }) => (
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              align="center"
              wrap={false}
              style={{ padding: `${SPACING.space2} 0` }}
            >
              <FontAwesomeIcon
                icon={icon}
                color={JoonUIColor.icon.accent}
                fontSize={20}
              />
              <Typography
                variant="bodySmall"
                style={{ fontWeight: 600, lineHeight: 1.2 }}
              >
                {text}
              </Typography>
            </FlexBox>
          ))}
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: SPACING.space3,
          borderRadius: SPACING.space2,
          background: JoonUIColor.background.lightAccent,
        }}
      >
        <InfoIcon color={JoonUIColor.icon.accent} size={16} />
        <Typography variant="bodySmall" style={{ fontWeight: 600 }}>
          {hasUpgraded
            ? `You’re all set - let's book your session!`
            : `You’re all set - your new ${numSessions === 0 ? "plan" : "pricing"} will take effect on ${currentBillingCycleEndDate}`}
        </Typography>
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space3}
        align="center"
        style={{ padding: `0 ${SPACING.space6}` }}
      >
        <Button
          buttonType="primary"
          text={numSessions === 0 ? "Message coach" : "Book session"}
          fullWidth
          onClick={handleComplete}
          style={{ borderRadius: SPACING.space2 }}
        />
        <TextButton onClick={onClose}>
          <Typography variant="caption" color={JoonUIColor.text.primaryAccent}>
            Return home
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default PlanUpdateSuccess
