import {
  FlexBox,
  HeartIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid, isChrome, isMobile } from "react-device-detect"
import Xarrow, { Xwrapper } from "react-xarrows"

import ListCard from "../../../components/coaching/ListCard"
import TextArrowButton from "../../../components/coaching/TextArrowButton"
import PageLoader from "../../../components/loading/PageLoader"
import { useAddToHomescreenModal } from "../../../components/modals/AddToHomescreenModal"
import { useIOSModal } from "../../../components/modals/IOSModal"
import { CoachingStep } from "../../../constants"
import { useCoachIntakeFormQuestionnaireQuery } from "../../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"
import { localStorageItems } from "../../../util/storage"
import { getTopValueProps } from "../getTopValueProps"
import { useCoachingStore } from "../useCoachingStore"

const CoachLandingPage = () => {
  const { setStep, setQuestionnaireStep } = useCoachingStore()
  const { data: coachQuestionnaire, isLoading } =
    useCoachIntakeFormQuestionnaireQuery()
  const router = useRouter()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  const parentQuestionnaireAnswers = JSON.parse(
    localStorage.getItem(localStorageItems.parentQuestionnaireAnswers) || "{}"
  )

  const valueProps = getTopValueProps(parentQuestionnaireAnswers)

  const onContinueClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_CONTINUE_CLICK, {
      step: CoachingStep.LANDING,
      is_tof: router.query.from !== "preview",
    })
    if (coachQuestionnaire?.questions?.length) setStep(CoachingStep.INTAKE_FORM)
    else setStep(CoachingStep.RECOMMENDED_COACHES)
  }

  const onSkipClick = () => {
    if (
      localStorage.getItem(localStorageItems.planChoiceFromPaywall) ===
      "appOnly"
    ) {
      localStorage.removeItem(localStorageItems.planChoiceFromPaywall)
      router.push("/me")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.LANDING,
        is_tof: router.query.from !== "preview",
      })
      if (router.query.from === "preview") return
      if (isOnMobileChrome) openAddToHomescreenModal()
      else openIOSModal()
    } else {
      router.push("/paywall/trial")
      trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_SKIP_CLICK, {
        step: CoachingStep.LANDING,
        is_tof: router.query.from !== "preview",
      })
    }
  }

  useEffect(() => {
    setQuestionnaireStep(0)
  }, [setQuestionnaireStep])

  if (isLoading) return <PageLoader />

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      fullWidth
      gap={SPACING.space6}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space2}
        fullWidth
      >
        <Typography variant="h2" color={JoonUIColor.text.primary}>
          Joon is better with a Coach!
        </Typography>
        <img
          src="/images/guidePhotos/coach_amy_help.svg"
          alt="Helpful message from Coach Amy"
          style={{ width: "min(80vw, 300px)" }}
        />
      </FlexBox>
      <Typography variant="caption" color={JoonUIColor.text.primary}>
        Coaches can help turn your parenting challenges into daily wins!
      </Typography>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        fullWidth
        gap={SPACING.space2}
      >
        <FlexBox direction="column" style={{ maxWidth: "300px" }}>
          <Xwrapper>
            <FlexBox direction="row" justify="flex-start">
              <ListCard
                id={"elem1"}
                text={`😢 ${valueProps[0].problem}`}
                backgroundColor={JoonColorExpanded.red100}
                style={{
                  borderRadius: "100px",
                  width: "fit-content",
                  padding: SPACING.space2,
                  marginLeft: valueProps[0].problem.length > 30 ? "1%" : "5%",
                }}
              />
            </FlexBox>
            <FlexBox direction="row" justify="flex-end" wrap={false}>
              <ListCard
                id={"elem2"}
                icon={HeartIcon}
                iconColor={JoonColorExpanded.yellow400}
                text={valueProps[0].valueProp}
                backgroundColor={JoonColorExpanded.yellow100}
                gap={SPACING.space2}
                style={{
                  borderRadius: "100px",
                  width: "fit-content",
                  padding: SPACING.space2,
                  marginRight:
                    valueProps[0].valueProp.length > 30 ? "1%" : "5%",
                }}
              />
            </FlexBox>
            <Xarrow
              start="elem1"
              end="elem2"
              startAnchor="right"
              endAnchor="right"
              color={JoonColorExpanded.purple600}
              strokeWidth={2}
              headSize={-4}
              curveness={-0.5}
              _cpx1Offset={
                valueProps[0].valueProp.length - valueProps[0].problem.length >
                10
                  ? 50
                  : 100
              }
              _cpx2Offset={16}
              _cpy1Offset={-3}
              _cpy2Offset={-20}
            />
          </Xwrapper>
          <Xwrapper>
            <FlexBox direction="row" justify="flex-start">
              <ListCard
                id={"elem3"}
                text={`😢 ${valueProps[1].problem}`}
                backgroundColor={JoonColorExpanded.red100}
                style={{
                  borderRadius: "100px",
                  width: "fit-content",
                  padding: SPACING.space2,
                }}
              />
            </FlexBox>
            <FlexBox direction="row" justify="flex-end">
              <ListCard
                id={"elem4"}
                icon={HeartIcon}
                iconColor={JoonColorExpanded.yellow400}
                text={valueProps[1].valueProp}
                backgroundColor={JoonColorExpanded.yellow100}
                gap={SPACING.space2}
                style={{
                  borderRadius: "100px",
                  width: "fit-content",
                  padding: SPACING.space2,
                }}
              />
            </FlexBox>
            <Xarrow
              start="elem3"
              end="elem4"
              startAnchor="left"
              endAnchor="left"
              color={JoonColorExpanded.purple600}
              strokeWidth={2}
              headSize={4}
              curveness={0.8}
              _cpx1Offset={
                valueProps[1].valueProp.length - valueProps[1].problem.length >
                10
                  ? -50
                  : -100
              }
              _cpx2Offset={-20}
              _cpy1Offset={10}
              _cpy2Offset={0}
            />
          </Xwrapper>
          <FlexBox direction="row" justify="flex-start">
            <ListCard
              id={"elem5"}
              text={`😢 ${valueProps[2].problem}`}
              backgroundColor={JoonColorExpanded.red100}
              style={{
                borderRadius: "100px",
                width: "fit-content",
                padding: SPACING.space2,
                marginLeft: valueProps[2].problem.length > 30 ? "1%" : "5%",
              }}
            />
          </FlexBox>
          <Xwrapper>
            <FlexBox direction="row" justify="flex-end">
              <ListCard
                id={"elem6"}
                icon={HeartIcon}
                iconColor={JoonColorExpanded.yellow400}
                text={valueProps[2].valueProp}
                backgroundColor={JoonColorExpanded.yellow100}
                gap={SPACING.space2}
                style={{
                  borderRadius: "100px",
                  width: "fit-content",
                  padding: SPACING.space2,
                  marginRight:
                    valueProps[2].valueProp.length > 30 ? "1%" : "5%",
                }}
              />
            </FlexBox>
            <Xarrow
              start="elem5"
              end="elem6"
              startAnchor="right"
              endAnchor="right"
              color={JoonColorExpanded.purple600}
              strokeWidth={2}
              headSize={-4}
              curveness={-0.5}
              _cpx1Offset={
                valueProps[2].valueProp.length - valueProps[2].problem.length >
                10
                  ? 50
                  : 100
              }
              _cpx2Offset={20}
              _cpy1Offset={-3}
              _cpy2Offset={-20}
            />
          </Xwrapper>
        </FlexBox>

        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.primary}
          style={{ marginTop: SPACING.space2, marginBottom: SPACING.space2 }}
        >
          Get virtual 1-1 coaching, direct messaging, and in-app support with a
          Joon Coach. <br />
          Complete a 2 min survey and see your recommended coaches!
        </Typography>
        <div
          style={{ position: "sticky", width: "100%", bottom: SPACING.space4 }}
        >
          <TextArrowButton
            text="Let's go"
            onClick={onContinueClick}
            style={{
              position: "relative",
              width: "100%",
            }}
          />
        </div>
        <TextButton onClick={onSkipClick}>
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            No Thanks, I don't want a coach
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

export default CoachLandingPage
