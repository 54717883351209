import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  DropdownOption,
  JoonUIColor,
  SkillKey,
  SPACING,
} from "@joonapp/web-shared"
import { FlexBox, Typography } from "@joonapp/web-shared"
import { useShallow } from "zustand/react/shallow"

import { capitalizeFirstLetter } from "../../../../util/util"
import { useAddEditQuestStore } from "../useAddEditQuestStore"

export const useSkills = () => {
  const { category } = useAddEditQuestStore(
    useShallow((state) => ({
      category: state.category,
    }))
  )

  let skillOptions: DropdownOption[] = Object.values(SkillKey)?.map(
    (skill) => ({
      label: (
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space2}
          wrap={false}
        >
          <img
            src={`/images/icons/${skill}-icon.png`}
            alt={skill}
            style={{ width: "1.25rem", height: "1.25rem" }}
          />
          <Typography variant="bodySmall">
            {capitalizeFirstLetter(skill)}
          </Typography>
        </FlexBox>
      ),
      value: skill,
    })
  )
  if (category) {
    skillOptions.push({
      label: (
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space2}
          wrap={false}
        >
          <div
            style={{
              paddingLeft: SPACING.space1,
              paddingRight: SPACING.space1,
            }}
          >
            <FontAwesomeIcon icon={faXmark} color={JoonUIColor.icon.accent} />
          </div>
          <Typography variant="bodySmall">No skill</Typography>
        </FlexBox>
      ),
      value: null,
    })
  }

  const selectedSkill = skillOptions.find(
    (skill) => skill.value === category
  ) ?? {
    label: "Add skill",
    value: null,
  }

  return { skillOptions, selectedSkill }
}
