import { useQuery } from "@tanstack/react-query"

import { getAgreementLinks } from "./api"
import { QUERY_KEYS } from "../../constants"

export const useAgreementLinksQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.AGREEMENT_LINKS],
    queryFn: getAgreementLinks,
  })
}
