import {
  Checkbox,
  FlexBox,
  JoonUIColor,
  QuestInstance,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { QuestSectionType } from "./QuestCard"
import useQuestBoardMultiSelectStore from "./useQuestBoardMultiSelectStore"
import useQuestBoardStore from "./useQuestBoardStore"
import { useViewQuestModalStore } from "./viewQuestModal/useViewQuestModalStore"
import Card from "../../components/card/Card"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { getQuestCardBorderColor, getQuestCardTabName } from "../../util/util"
import { QuestCardStatusOverlay } from "../me/CatchUpCompleteContent"

type Props = {
  questGroup: QuestInstance[]
  type: QuestSectionType
  index: number
}

export const QuestGroupCard = ({ questGroup, type, index }: Props) => {
  const { onOpen: openViewQuestModal } = useViewQuestModalStore()
  const selectedChildId = useQuestBoardStore((state) => state.selectedChildId)

  const { selectedQuestIds, toggleSelectedQuestId } =
    useQuestBoardMultiSelectStore()

  const [isHovered, setIsHovered] = useState(false)
  const [hoveredCheckboxInstanceId, setHoveredCheckboxInstanceId] = useState<
    null | number
  >(null)

  const isMandatory = questGroup[0]?.series?.mandatory

  const tabName = getQuestCardTabName(type, index)
  const borderColor = getQuestCardBorderColor(type, isMandatory)

  const minCoinRange = questGroup.reduce((acc, questInstance) => {
    return Math.min(acc, questInstance.series.redeemable_reward)
  }, Infinity)
  const maxCoinRange = questGroup.reduce((acc, questInstance) => {
    return Math.max(acc, questInstance.series.redeemable_reward)
  }, 0)

  const onClickCard = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_QUEST_DETAILS)
    openViewQuestModal(questGroup[0])
  }

  return (
    <FlexBox
      direction="column"
      fullWidth
      wrap={false}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transform: isHovered ? "scale(1.015)" : "scale(1)",
        transition: "transform 0.2s",
      }}
    >
      {tabName && (
        <div
          style={{
            padding: `${SPACING.space0} ${SPACING.space2}`,
            background: borderColor,
            borderRadius: `${SPACING.space2} ${SPACING.space2} 0 0`,
            marginLeft: SPACING.space3,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            height: SPACING.space5,
          }}
        >
          <Typography
            variant="bodyXSmall"
            color={JoonUIColor.text.inverted}
            style={{ lineHeight: SPACING.space5 }}
          >
            {tabName}
          </Typography>
        </div>
      )}
      <button
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          border: `1px solid ${borderColor}`,
          borderRadius: SPACING.space2,
          overflow: "hidden",
        }}
        onClick={() => onClickCard()}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          fullWidth
          wrap={false}
        >
          <FlexBox
            direction="column"
            gap={SPACING.space2}
            style={{
              padding: `${SPACING.space3} ${SPACING.space4}`,
              background: JoonUIColor.background.xlightGray,
            }}
          >
            {questGroup[0].series.reminder_time && (
              <Typography variant="bodyXSmall">
                {questGroup[0].series.reminder_time}
              </Typography>
            )}
            <FlexBox
              direction="row"
              align="center"
              justify="space-between"
              wrap={false}
            >
              <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>
                {questGroup[0].series.title}
              </Typography>
              <FlexBox
                direction="row"
                align="center"
                justify="flex-end"
                gap={SPACING.space1}
                wrap={false}
              >
                <img
                  style={{ height: SPACING.space3 }}
                  src="/images/icons/coin-icon.png"
                  alt="coin"
                />
                <Typography variant="bodySmall">
                  {minCoinRange === maxCoinRange
                    ? minCoinRange
                    : `${minCoinRange}-${maxCoinRange}`}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        {questGroup.map((questInstance) => (
          <FlexBox
            key={questInstance.id}
            justify="unset"
            align="center"
            fullWidth
            wrap={false}
            style={{
              position: "relative",
              background: "rgba(255, 255, 255, 0.6)",
              height: "fit-content",
              borderTop: `1px solid ${JoonUIColor.border.default}`,
            }}
          >
            {/* @ts-ignore */}
            {questInstance.temp_status && (
              <QuestCardStatusOverlay
                // @ts-ignore
                questStatus={questInstance.temp_status}
              />
            )}
            <Card
              title={""}
              subtitle={questInstance?.series?.reminder_time || ""}
              childIds={!selectedChildId ? [questInstance.user_id] : undefined}
              onClick={() => {}}
              style={{
                border: "none",
                background: JoonUIColor.background.primaryNeutral,
                transform: "none",
                borderRadius: 0,
                maxWidth: `calc(100% - ${type === QuestSectionType.ROUTINE ? "54px" : "0px"})`,
              }}
            />
            {type === QuestSectionType.ROUTINE && (
              <div
                onMouseEnter={() =>
                  setHoveredCheckboxInstanceId(questInstance.id)
                }
                onMouseLeave={() => setHoveredCheckboxInstanceId(null)}
                style={{
                  transform:
                    hoveredCheckboxInstanceId === questInstance.id
                      ? "scale(1.1)"
                      : "scale(1)",
                  transition: "transform 0.2s",
                }}
              >
                <Checkbox
                  name={questInstance.id.toString()}
                  selected={selectedQuestIds.includes(questInstance.id)}
                  onChange={(e) => {
                    e.stopPropagation()
                    toggleSelectedQuestId(questInstance.id)
                  }}
                  hideBorder
                  style={{
                    padding: `${SPACING.space3} ${SPACING.space4}`,
                    background: "transparent",
                  }}
                />
              </div>
            )}
          </FlexBox>
        ))}
      </button>
    </FlexBox>
  )
}
