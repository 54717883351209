import {
  faCreditCard,
  faDragon,
  faMessage,
  faReceipt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"

import { PlanTitle } from "./PlanTitle"
import { usePlanCard } from "./usePlanCard"
import TextArrowButton from "../../../../components/coaching/TextArrowButton"
import { ACTIVE_COACHING_LOOKUP_KEY, COACH_PLANS } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../../networking/queries"
import { localStorageItems } from "../../../../util/storage"
import { formattedCentsToDollars } from "../../../../util/util"
import { useCoachingStore } from "../../useCoachingStore"

const PlanInfoCard = () => {
  const { selectedCoach, isBundleJoonity } = useCoachingStore()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const { redirectToCoachCheckout, isLoading } = usePlanCard()
  const coachingDefaultPlan = subscriptionPackages?.[ACTIVE_COACHING_LOOKUP_KEY]
  const isMobile = useMobile()
  const { plan, frequency } = COACH_PLANS[ACTIVE_COACHING_LOOKUP_KEY]
  const firstMonthPriceInCents = coachingDefaultPlan?.first_month_unit_amount
  const priceInCents = coachingDefaultPlan?.unit_amount ?? 0

  const priceForToday = formattedCentsToDollars(
    firstMonthPriceInCents ?? priceInCents
  )
  const fullPrice = formattedCentsToDollars(
    firstMonthPriceInCents ? priceInCents : priceInCents * 2
  )

  const priceLabel =
    firstMonthPriceInCents !== null
      ? `afterwards $${fullPrice}/month`
      : "Early bird special!"

  const bundleJoonity =
    localStorage.getItem(localStorageItems.planChoiceFromPaywall) === "appOnly"
      ? false
      : isBundleJoonity

  const infoCards = [
    ...(bundleJoonity
      ? [
          {
            icon: faDragon,
            text: "Full access to Joon Apps included!",
          },
        ]
      : []),
    {
      icon: faMessage,
      text: "Anytime direct messaging with your coach",
    },
    {
      icon: faVideo,
      text: frequency,
    },
    {
      icon: faCreditCard,
      text: "HSA/FSA eligible",
    },
    {
      icon: faReceipt,
      text: "Try it Risk Free!\nLove it or get a full refund within 30 days",
    },
  ]

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space0}
      style={{
        width: "min(100%, 400px)",
        opacity: subscriptionPackages ? 1 : 0,
        background: "#F9FAFC",
        border: `2px solid ${JoonUIColor.text.primaryAccent}`,
        borderRadius: "16px",
        marginBottom: SPACING.space4,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
        transition: "opacity .3s ease-in-out",
      }}
    >
      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space1}
        style={{
          padding: SPACING.space4,
        }}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          fullWidth
          wrap={false}
        >
          <FlexBox
            justify="flex-start"
            align="center"
            gap={SPACING.space1}
            wrap={false}
          >
            <PlanTitle plan={plan} coachingPlan={ACTIVE_COACHING_LOOKUP_KEY} />
          </FlexBox>

          {firstMonthPriceInCents ? (
            <FlexBox justify="flex-end" align="center" gap={SPACING.space2}>
              <Typography
                variant="h2"
                color={JoonUIColor.background.disabled}
                style={{ textDecoration: "line-through" }}
              >
                ${fullPrice}
              </Typography>
              <Typography variant="h2" color={JoonUIColor.text.primary}>
                ${priceForToday}
              </Typography>
            </FlexBox>
          ) : (
            <FlexBox justify="flex-end" align="center" gap={SPACING.space2}>
              <Typography variant="h2" color={JoonUIColor.text.primary}>
                ${priceForToday}
                <Typography variant="body" color={JoonUIColor.text.primary}>
                  /month
                </Typography>
              </Typography>
            </FlexBox>
          )}
        </FlexBox>

        {firstMonthPriceInCents && (
          <>
            <FlexBox
              style={{
                padding: `${SPACING.space1} ${SPACING.space2}`,
                background: JoonColorExpanded.yellow300,
                borderRadius: SPACING.space1,
              }}
              fullWidth
            >
              <Typography
                variant="bodyXSmall"
                color={JoonUIColor.text.primary}
                textAlign="center"
                style={{
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                First month: 60% off Early Bird Special!
              </Typography>
            </FlexBox>

            <Typography
              variant="bodyXSmall"
              color={JoonUIColor.text.secondary}
              style={{ fontWeight: 600 }}
            >
              {priceLabel}
            </Typography>
          </>
        )}
      </FlexBox>

      <FlexBox
        direction="column"
        justify="space-between"
        align="flex-start"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space2}`,
          background: JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.icon.light}`,
          borderBottom: `1px solid ${JoonUIColor.icon.light}`,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        }}
      >
        <FlexBox direction="column" align="flex-start" gap={SPACING.space0}>
          {infoCards.map(({ icon, text }, index) => (
            <FlexBox
              direction="row"
              justify="flex-start"
              align="center"
              gap={SPACING.space2}
              wrap={false}
              style={{ padding: SPACING.space2 }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "20px",
                  maxWidth: "20px",
                }}
              >
                <FontAwesomeIcon
                  icon={icon}
                  color={
                    index < (bundleJoonity ? 3 : 2)
                      ? JoonUIColor.icon.accent
                      : JoonUIColor.icon.neutral
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <Typography
                variant={isMobile ? "bodyXSmall" : "bodySmall"}
                color={JoonUIColor.text.primary}
                style={{
                  fontWeight: "700",
                  textAlign: "left",
                  whiteSpace: "pre-line",
                }}
              >
                {text}
              </Typography>
            </FlexBox>
          ))}
        </FlexBox>

        <div
          style={{ width: "100%" }}
          onPointerDown={(e) => {
            e.currentTarget.dataset.pressed = "true"
            e.currentTarget.dataset.pressX = e.clientX.toString()
            e.currentTarget.dataset.pressY = e.clientY.toString()
          }}
          onPointerUp={async (e) => {
            if (e.currentTarget.dataset.pressed === "true") {
              const startX = Number(e.currentTarget.dataset.pressX || 0)
              const startY = Number(e.currentTarget.dataset.pressY || 0)
              const deltaX = Math.abs(e.clientX - startX)
              const deltaY = Math.abs(e.clientY - startY)
              if (deltaX < 10 && deltaY < 10)
                redirectToCoachCheckout(ACTIVE_COACHING_LOOKUP_KEY)
            }
            e.currentTarget.dataset.pressed = "false"
          }}
          onPointerLeave={(e) => {
            e.currentTarget.dataset.pressed = "false"
          }}
        >
          <TextArrowButton
            text={`Get started with ${selectedCoach?.user.name || "your coach"}`}
            isLoading={isLoading}
            onClick={() => {}}
            style={{
              position: "relative",
              width: "100%",
              cursor: "pointer",
            }}
          />
        </div>
      </FlexBox>
    </FlexBox>
  )
}

export default PlanInfoCard
