import {
  CalendarCheckIcon,
  CheckIcon,
  FlexBox,
  HandshakeAngleIcon,
  JoonColorExpanded,
  JoonUIColor,
  LocationArrowIcon,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"

import ReadyButton from "./ReadyButton"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import { CoachingStep } from "../../../constants"
import useDocumentTitle from "../../../hooks/useDocumentTitle"
import useMobile from "../../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { requireAuth } from "../../../util/auth"
import { useRouter } from "../../../util/router"
import useCareTeam from "../../care/useCareTeam"
import { useCoachingStore } from "../useCoachingStore"

const CareMarketingPage = () => {
  useDocumentTitle("Joon - Care")
  const { setStep } = useCoachingStore()
  const { careTeam, isLoading, hasCoach, firstCoach } = useCareTeam()
  const isMobile = useMobile()
  const router = useRouter()

  const onReadyButtonClick = () => {
    setStep(CoachingStep.LANDING)
    router.push("/coaching?from=preview")
  }

  useEffect(() => {
    if (hasCoach) router.push(`/coach/${firstCoach?.user.id}`)
  }, [careTeam, hasCoach, firstCoach, router])

  useEffect(() => {
    if (isLoading || hasCoach) return
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_TAB_VIEWED)
  }, [isLoading, hasCoach])

  if (isLoading || hasCoach) return <></>

  return (
    <PageWrapper
      style={{
        background: JoonColorExpanded.yellow100,
        paddingBottom: "0px",
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        style={{
          position: "relative",
          padding: SPACING.space0,
          paddingTop: SPACING.space9,
          width: "min(600px, 100%)",
        }}
        gap={SPACING.space9}
      >
        <FlexBox direction="column" align="center" gap={SPACING.space4}>
          <Typography variant="bodySmall" textAlign="center">
            Finally...
          </Typography>
          <Typography variant="h1" textAlign="center" style={{ width: "75%" }}>
            Get a coach who gets you
          </Typography>
          <Typography
            variant="body"
            textAlign="center"
            style={{ width: "min(400px, 85%)" }}
          >
            Connect 1:1 with a coach, right here in the app and{" "}
            <Typography variant="bodyBold">
              get support like never before
            </Typography>
          </Typography>
          <img
            src="/images/guidePhotos/coach_main_headline.svg"
            style={{ width: "min(380px, 80%)" }}
            alt="<3"
          />
        </FlexBox>

        <img src="/images/squiggle.svg" style={{ width: "70%" }} alt="~~~" />

        <Typography variant="h2" textAlign="center" style={{ width: "75%" }}>
          Why you should consider a coach
        </Typography>
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space12}
          style={{ marginBottom: SPACING.space4 }}
        >
          <FlexBox direction="column" align="center">
            <Typography
              variant="bodyBold"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              Get practical strategies for managing challenging behaviors
            </Typography>
            <img
              src="/images/guidePhotos/coach_practical_strategy.svg"
              style={{
                width: "min(400px, 80%)",
                paddingBottom: SPACING.space3,
              }}
              alt="Practical strategies"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              Message your coach anytime and retain continuity between daily
              challenges and active sessions.
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center">
            <Typography variant="bodyBold" textAlign="center">
              Improve your relationship with your child
            </Typography>
            <img
              src="/images/guidePhotos/parent_task.svg"
              style={{ width: "min(400px, 80%)", paddingTop: SPACING.space3 }}
              alt="Practical strategies"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              Receive guided practice tasks to continue improving your
              relationship with your child
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center">
            <Typography
              variant="bodyBold"
              textAlign="center"
              style={{ width: "min(400px, 85%)" }}
            >
              Virtually meet your coach, anytime, anywhere
            </Typography>
            <img
              src="/images/guidePhotos/zoom.svg"
              style={{
                width: "min(400px, 80%)",
                paddingTop: SPACING.space3,
                paddingBottom: SPACING.space2,
              }}
              alt="Meet Your Coach"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 70%)" }}
            >
              All you need is a little space and an open mind to get started
            </Typography>
          </FlexBox>
          <FlexBox direction="column" align="center">
            <Typography variant="bodyBold" textAlign="center">
              On demand curated learning
            </Typography>
            <img
              src="/images/guidePhotos/learning_modules.svg"
              style={{
                width: "min(400px, 80%)",
              }}
              alt="Learning Modules"
            />
            <Typography
              variant="bodySmall"
              textAlign="center"
              style={{ width: "min(400px, 70%)" }}
            >
              Continue to learn while on the go with bite-sized modules
              specifically curated by your coach
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox
          direction="column"
          align="center"
          wrap={false}
          style={{ width: "min(500px, 100%)" }}
        >
          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space10}
            style={{
              background: JoonColorExpanded.purple600,
              padding: SPACING.space4,
              paddingBottom: SPACING.space12,
              borderTopLeftRadius: SPACING.space4,
              borderTopRightRadius: SPACING.space4,
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              color={JoonUIColor.text.inverted}
              style={{ marginTop: SPACING.space4 }}
            >
              How this works
            </Typography>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <HandshakeAngleIcon
                size={48}
                color={JoonColorExpanded.yellow400}
              />
              <Typography
                variant="bodyBold"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                Get matched with the best coach for your family
              </Typography>
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                Our Care Navigator will provide you with a curated list of best
                coaches that matches your family's needs
              </Typography>
            </FlexBox>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <CalendarCheckIcon
                size={48}
                color={JoonColorExpanded.yellow400}
              />
              <Typography
                variant="bodyBold"
                textAlign="center"
                color={JoonUIColor.text.inverted}
              >
                Always on your own time
              </Typography>
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.inverted}
              >
                Schedule times that work for you
              </Typography>
            </FlexBox>
            <FlexBox direction="column" align="center" gap={SPACING.space2}>
              <LocationArrowIcon
                size={48}
                color={JoonColorExpanded.yellow400}
              />
              <Typography
                variant="bodyBold"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                Let's set your goals
              </Typography>
              <Typography
                variant="bodySmall"
                textAlign="center"
                color={JoonUIColor.text.inverted}
                style={{ width: "min(400px, 70%)" }}
              >
                Establish parenting goals, set attainable targets, and make
                meaningful progress towards your parenting goals
              </Typography>
            </FlexBox>
          </FlexBox>

          <FlexBox
            direction="column"
            align="center"
            gap={SPACING.space6}
            wrap={false}
            style={{
              height: isMobile ? "590px" : "480px",
              background: JoonColorExpanded.yellow400,
              padding: `${SPACING.space12} ${SPACING.space6}`,
            }}
          >
            <Typography
              variant="h2"
              textAlign="center"
              color={JoonUIColor.text.primary}
              style={{ width: "min(350px, 90%)" }}
            >
              What you'll get with your coaching plan
            </Typography>

            <FlexBox
              direction="column"
              justify="left"
              gap={SPACING.space4}
              wrap={false}
              style={{
                width: "auto",
                borderRadius: SPACING.space4,
                padding: `${SPACING.space6} ${SPACING.space4}`,
                background: JoonColorExpanded.yellow100,
              }}
            >
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                wrap={false}
              >
                <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
                <Typography
                  variant="bodySmall"
                  textAlign="left"
                  style={{ width: "80%" }}
                >
                  Bi-weekly 45 min 1-on-1 sessions with your coach
                </Typography>
              </FlexBox>
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                wrap={false}
              >
                <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
                <Typography variant="bodySmall" textAlign="left">
                  Access to personalized resources and strategies for your
                  family
                </Typography>
              </FlexBox>
              <FlexBox
                direction="row"
                gap={SPACING.space2}
                align="center"
                wrap={false}
              >
                <CheckIcon size={28} color={JoonColorExpanded.viridian400} />
                <Typography
                  variant="bodySmall"
                  textAlign="left"
                  style={{ width: "80%" }}
                >
                  Unlimited messaging
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <ReadyButton
          onClick={onReadyButtonClick}
          style={{
            bottom: isMobile ? "76px" : "20px",
          }}
        />
      </FlexBox>
    </PageWrapper>
  )
}

const CareMarketingPageWithAuth = requireAuth(CareMarketingPage)
export default CareMarketingPageWithAuth
