import { useMutation } from "@tanstack/react-query"

import { QUERY_KEYS } from "../../../constants"
import { updateCoachSubscription } from "../../../networking/subscriptions"
import { queryClient } from "../../../util/queryClient"

const usePlanUpdateMutation = () => {
  return useMutation({
    mutationFn: updateCoachSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.SUBSCRIPTION_PACKAGES])
      queryClient.invalidateQueries([QUERY_KEYS.SESSION_BILLING_PERIODS])
      queryClient.invalidateQueries([QUERY_KEYS.COACH_SUBSCRIPTION])
    },
  })
}

export default usePlanUpdateMutation
