import { createJoonAPIClient } from "../../util/joon-api"

interface AgreementLink {
  id: string
  link: string
}

export const signAgreement = async (agreement_id: string) => {
  const API = createJoonAPIClient()
  const { data } = await API.post(`api/agreement-links/${agreement_id}/sign/`)
  return data
}

export const getAgreementLinks = async () => {
  const API = createJoonAPIClient()
  const response = await API.get<{ results: AgreementLink[] }>(
    "api/agreement-links/",
    {
      params: {
        unsigned: true,
        agreement_type: "coaching",
      },
    }
  )
  return response.data.results
}
